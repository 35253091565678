import React from 'react'
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { MdCancel } from "react-icons/md";
import AddButton from '../../Button/AddButton';
import TextInput from '../../InputFiled/TextInput';
import { otpRes, otpTodo } from '../../../redux/slices/Login/changePassword';
import { otpSchema } from './otpYup';

export default function OTPResetForm(props) {

    const { OTP, setOTP, adminpsw, setAdminpsw } = props;
    const dispatch = useDispatch()
    const initialValues = {
        otp: ''
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: otpSchema,
        onSubmit: (values, action) => {
            dispatch(otpTodo(values)).then(() => otpverify())
            action.resetForm()

        },
    })

    const otpverify = () => {
        if (otpRes && otpRes.success) {
            setOTP(false)
            toast.success(otpRes && otpRes.message, { position: 'bottom-right' })
            setTimeout(() => {
                setAdminpsw(true)
            }, 1000);
        } else {
            toast.error(otpRes && otpRes.message, { position: 'bottom-right' })
        }
    }

    return (
        <>
            <div className={OTP ? 'modal fade show d-block' : 'modal fade show d-none'} style={{ background: 'rgba(0,0,0,0.8)' }}>
                <div className="modal-dialog modal-dialog-centered modal-md  ">
                    <div className="modal-content pb-5" >
                        <div className="modal-header  "  >
                            <h5 className='text-center ' > Forgot Password</h5>
                            <span onClick={() => { setOTP(false); }}><MdCancel style={{ color: 'red' }} size={25} className="mb-2" /></span>
                        </div>
                        <div className='modal-body  p-4 '>
                            <form onSubmit={handleSubmit}>
                                <TextInput
                                    name='otp'
                                    label={'Enter OTP'}
                                    value={values.otp}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                            {errors.otp && touched.otp ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.otp}</span>) : null}

                                <div className='mt-3 d-flex justify-content-end'>
                                    <AddButton label={'Save'} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
