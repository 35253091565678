import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var bookingCancleRes;
export const  bookingCancleTodo = createAsyncThunk(' bookingCancle', async (data) => {
    console.log("bookingData", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'booking_cancel',
            data: {
                
                    "booking_id":data && data.bookingID,
                    "reason_id":data && data.values && data.values.reason_id
                
            },
            headers: { "Content-Type": "application/json"  }
        })
        return bookingCancleRes = res.data

    } catch (error) {
        return bookingCancleRes = error.response.data
    }
})