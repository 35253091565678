import React, { useState } from 'react';
import TextInput from '../../../InputFiled/TextInput';
import FileInput from '../../../InputFiled/FileInput';
import StatusSelect from '../../../InputFiled/StatusSelect';
import { useDispatch, useSelector } from 'react-redux';
import AddButton from '../../../Button/AddButton';
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import { addCategoryRes, addCategoryTodo, updateCategoryTodo, updateCategoryRes } from '../../../../redux/slices/Master/Categorgy/addCategory';
import { useLocation, useParams } from 'react-router-dom';
import { AddCategorySchema, UpdateCategorySchema } from './validation';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../Loader/Loader';

const CategoryForm = (props) => {
    const { bool, setBool } = props;
    const dispatch = useDispatch()
    const { id } = useParams();
    const navigate = useNavigate()
    const { state } = useLocation();
    const [loading, setLoading] = useState(false)
    var categoryData = state;

    const initialValues = {
        category: id ? categoryData && categoryData.name : '',
        image: id ? categoryData && categoryData.categoryImage : '',
        status: id ? categoryData && categoryData.status : ''
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: id ? UpdateCategorySchema : AddCategorySchema,

        onSubmit: (values, action) => {
            {
                id ? dispatch(updateCategoryTodo({ bodyFormData, id })).then(() => updateCategoryFun())
                    : dispatch(addCategoryTodo(bodyFormData)).then(() => addCategoryFun())
            }
            setLoading(true)
            id ? <></> : action.resetForm()
        },
    })

    const addCategoryFun = () => {
        if (addCategoryRes && addCategoryRes.success) {
            toast.success(addCategoryRes && addCategoryRes.message, { position: "bottom-right" })
            setLoading(false)
            setBool(!bool)

        } else {
            toast.error(addCategoryRes && addCategoryRes.message, { position: "bottom-right" })
            setLoading(false)
        }
    }

    const updateCategoryFun = () => {
        if (updateCategoryRes && updateCategoryRes.success) {
            setLoading(false)
            toast.success(updateCategoryRes && updateCategoryRes.message, { position: "bottom-right" })
            setTimeout(() => {
                navigate('../category')
            }, 1500);
        } else {
            setLoading(false)
            toast.error(updateCategoryRes && updateCategoryRes.message, { position: "bottom-right" })

        }
    }

    let bodyFormData = new FormData();

    bodyFormData.append("name", values.category)
    // bodyFormData.append("order", '1')
    bodyFormData.append("status", values.status)
    bodyFormData.append("path", "category")
    bodyFormData.append("categoryImage", values.image)
    bodyFormData.append("user_id", localStorage.getItem('userId'))

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='master_form_section'>
                            {loading ?
                                <div className='d-flex justify-content-center mt-5'>
                                    <Loader />
                                </div> :
                                <form onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className='col-md-4 mb-3'>
                                            <TextInput
                                                name='category'
                                                label={'Category Name'}
                                                value={values.category}
                                                onChange={handleChange}
                                                onBlur={handleBlur} />
                                            {errors.category && touched.category ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.category}</span>) : null}

                                        </div>
                                        <div className='col-md-4 mb-3'>
                                            <FileInput
                                                name='image'
                                                label={'Category Image'}
                                                onChange={(e) => setFieldValue("image", e.currentTarget.files[0])}
                                                onBlur={handleBlur}
                                            />
                                            {errors.image && touched.image ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.image}</span>) : null}


                                        </div>
                                        <div className='col-md-4 mb-3'>
                                            <StatusSelect
                                                name='status'
                                                label={'Status'}
                                                value={values.status}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.status && touched.status ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.status}</span>) : null}

                                        </div>
                                        <div className='col-md-12 '>
                                            <AddButton
                                                label={id ? 'Update' : 'Save'} />
                                        </div>
                                    </div>
                                </form>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CategoryForm;
