import React, { useEffect, useState } from 'react';
import { FaRegBell, FaUserCircle } from "react-icons/fa";
import { MdOutlineEmail, MdLogout } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import { IoReorderThree } from "react-icons/io5";
import Sidebar from '../Navbar&Sidebar/Sidebar';
import { RiLockPasswordLine } from "react-icons/ri";
import ChangePassword from '../AdminLogin/ForgotPswPopup/ChangePassword';
import { ToastContainer } from 'react-toastify';
import ForgotPassword from '../AdminLogin/ForgotPswPopup/ForgotPassword';
import OTPResetForm from '../AdminLogin/ForgotPswPopup/OTPResetForm';


const Navbar = () => {
    const navigate = useNavigate();
    const logout = () => {
        localStorage.clear();
        navigate('/login');
    }


    const [sidebar, setSidebar] = useState(true);
    const [data, setData] = useState([])
    const [loading, setLoading] = useState();
    const [adminpsw, setAdminpsw] = useState(false)
    const [forgotPsw, setForgotPsw] = useState(false)
    const [OTP, setOTP] = useState(false)


    const handlePswUpdate = () => {
        setAdminpsw(true);
    }
    const handleForgotPsw = () => {
        setForgotPsw(true);
    }
    const handleOTP = () => {
        setOTP(true);
    }

    useEffect(() => {
        if (sidebar) {
            var marginAll = document.getElementById("content");
            marginAll.classList.add("margin-all-page")
        } else {
            var marginAll = document.getElementById("content");
            marginAll.classList.remove("margin-all-page")
        }
        setLoading(true)

    }, [sidebar])
    // console.log(data.length)
    return (
        <>
            <nav style={{ position: 'relative' }}>
                <div className='container-fluid' style={{ position: 'fixed', zIndex: "999", top: '0', backgroundColor: "white" }}>
                    <header className=' ' id='property-header'>
                        <div className="row  ">
                            <div className="col-md-12 mb-2">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className=" d-flex align-items-center">
                                        <div className='header-logo-section ' id='header-dekstop-logo'>
                                            <img src="../logo2.png" className='img-fluid header-logo ' alt="property-logo" />
                                        </div>
                                        {/* <div className='header-logo-section  d-block d-lg-none ' >
                                            <Link to='/'><img src="../Images/Property-Logo.png" className='img-fluid  header-logo ' alt="property-logo" /></Link>
                                        </div> */}
                                        {/* <div className='headerLogo'>
                                            <p>Doctor <span>On Discount</span></p>
                                        </div> */}

                                        <div>
                                            <a type='button' className='sidebar-btn d-none d-lg-block ' style={{ marginTop: "1px" }} onClick={() => setSidebar(!sidebar)} > <IoReorderThree style={{ color: "#ff0002" }} size={35} /></a>
                                            {/* <a type='button' className=' sidebar-btn d-block d-lg-none' data-bs-toggle="offcanvas" data-bs-target="#sideBar" aria-controls="offcanvasWithBothOptions" > <IoReorderThree style={{ color: "#0f5684" }} size={35} /></a> */}
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        {/* <div className='mt-1'>
                                            <a style={{ textDecoration: "none", color: "#0f5684" }} className=" position-relative">
                                                <MdOutlineEmail size={25} style={{ color: "#404FE0" }} />
                                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-circle bg-danger">
                                                    2
                                                </span>
                                            </a>
                                        </div> */}
                                        <div className='ms-4'>
                                            <div className="dropdown">
                                                <p className=' profile_drop_toggal dropdown-toggle mb-0' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span style={{ fontSize: "18px", fontWeight: '500' }}>Welcome ! {localStorage.getItem('userName')}  <FaUserCircle size={25} style={{ color: "#ff0002" }} /> </span>
                                                </p>
                                                <ul className="dropdown-menu profile_drop">

                                                    <li><a className="dropdown-item" type='button' onClick={handleForgotPsw}><RiLockPasswordLine className=' me-1 ' size={25} style={{ color: '#ff0002' }} /> Change Password</a></li>
                                                    <li><a className="dropdown-item" type='button' onClick={logout}><MdLogout className=' me-1 ' size={25} style={{ color: '#ff0002' }} /> Logout</a></li>
                                                </ul>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
            </nav>
            {sidebar && (<Sidebar />)}
           
            <ForgotPassword forgotPsw={forgotPsw} setForgotPsw={setForgotPsw} OTP={OTP} setOTP={setOTP}/>
            <OTPResetForm OTP={OTP} setOTP={setOTP} adminpsw={adminpsw} setAdminpsw={setAdminpsw} />
            <ChangePassword adminpsw={adminpsw} setAdminpsw={setAdminpsw} />
            <ToastContainer />
        </>
    );
}

export default Navbar;
