import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var diseaseListRes;
export const diseaseListTodo = createAsyncThunk('diseaseList', async (id) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'disease_list',
            data: {
                "status": "all"

            },
            headers: { "Content-Type": "application/json" }
        })
        return diseaseListRes = res.data

    } catch (error) {
        return diseaseListRes = error.response.data
    }
})

const diseaseListSlice = createSlice({
    name: 'diseaseList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(diseaseListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('equipmentList', state.data.response)
        });
        builder.addCase(diseaseListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default diseaseListSlice.reducer; 