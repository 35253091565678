import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var docterListRes;
export const docterListTodo = createAsyncThunk('docterList', async (id) => {
    // console.log("ExerciseId", id)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'admin_doctor_list',
            data: {
                "doctorid": id ? id : ""
            },
            headers: { "Content-Type": "application/json" }
        })
        return docterListRes = res.data

    } catch (error) {
        return docterListRes = error.response.data
    }
})

const docterListSlice = createSlice({
    name: 'docterList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(docterListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('equipmentList', state.data.response)
        });
        builder.addCase(docterListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default docterListSlice.reducer; 