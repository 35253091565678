import React from 'react'
import SubscriptionForm from '../../../component/Form/MasterForm/SubscriptionForm/SubscriptionForm'

export default function UpdateSubscription() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form_head">
                            <h4>Update Plan</h4>
                        </div>
                        <SubscriptionForm/>
                    </div>
                </div>
            </div>
        </>
    )
}
