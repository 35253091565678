import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var deleteDiseaseRes;
export const  deleteDiseaseTodo = createAsyncThunk(' deleteDisease', async (id) => {
    try {
        const res = await axios({
            method: "delete",
            url: process.env.REACT_APP_API_URL + `disease_delete/${id}`,
            headers: { "Content-Type": "multipart/form-data" }
        })
        return deleteDiseaseRes = res.data

    } catch (error) {
        return deleteDiseaseRes = error.response.data
    }
})