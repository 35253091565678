import React, { useState, useEffect, useRef, useCallback } from 'react';
import TextInput from '../../InputFiled/TextInput';
import NumberInput from '../../InputFiled/NumberInput';
import TextAreaInput from '../../InputFiled/TextAreaInput';
import AddButton from '../../Button/AddButton';
import ReactSelectInput from '../../InputFiled/ReactSelectInput';
import RadioInput from '../../InputFiled/RadioInput';
import FileInput from '../../InputFiled/FileInput';
import MultiSelectInput from '../../InputFiled/MultiSelectInput';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { categoryListRes, categoryListTodo } from '../../../redux/slices/Master/Categorgy/categorylist';
import { specialityListRes, specialityListTodo } from '../../../redux/slices/Master/Speciality/specialitylist';
import { courseListRes, courseListTodo } from '../../../redux/slices/Master/Course/courselist';
import { stateListRes, stateListTodo } from '../../../redux/slices/StateCity/statelist';
import { cityListRes, cityListTodo } from '../../../redux/slices/StateCity/citylist';
import { addDoctorRes, addDoctorTodo, updateDoctorRes, updateDoctorTodo, updateProfileRes, updateProfileTodo } from '../../../redux/slices/DoctorSlices/adddoctor';
import { ToastContainer, toast } from 'react-toastify';
import { AddDoctorSchema, UpdateDoctorSchema } from './validation';
import { useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import { docterListRes, docterListTodo } from '../../../redux/slices/DoctorSlices/docterList';
import Loader from '../../Loader/Loader';
import TimeInput from '../../InputFiled/TimeInput';
import { GoogleMap, useJsApiLoader, StandaloneSearchBox, Marker } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '400px'
};

// const center = {
//     lat: 22.7534141,
//     lng: 75.8972057
// };

const AddUpdateDoctor = () => {


    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
        libraries: ['places']
    })


    const [map, setMap] = useState(null)

    const onLoad = (map) => {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        // const bounds = new window.google.maps.LatLngBounds(center);
        // map.fitBounds(bounds);

        map.setCenter(markerPosition);
        map.setZoom(14);
        setMap(map)
    }

    const onUnmount = (map) => {
        setMap(null)
    }

    // const onUnmount = (map) => {
    //     setMap(null)
    // }


    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const { id } = useParams();
    const { state } = useLocation();
    const doctorId = localStorage.getItem('doctorID');
    var doctorData = state;
    const categoryList = useSelector(state => state && state.categoryList && state.categoryList.data && state.categoryList.data.data.filter(status => status.status !== "Inactive"))
    const specialityList = useSelector(state => state && state.specialityList && state.specialityList.data && state.specialityList.data.data.filter(status => status.status !== "Inactive"))
    const courseList = useSelector(state => state && state.courseList && state.courseList.data && state.courseList.data.data.filter(status => status.status !== "Inactive"))
    const stateList = useSelector(state => state && state.stateList && state.stateList.data && state.stateList.data.data.filter(status => status.status !== "Inactive"))
    const cityList = useSelector(state => state && state.cityList && state.cityList.data && state.cityList.data.data.filter(status => status.status !== "Inactive"))
    const drProfile = useSelector(state => state && state.doctorList && state.doctorList.data && state.doctorList.data.data && state.doctorList.data.data[0])


    const docterName =doctorData?.name?.split("Dr ")
    console.log(docterName)

    const docterName2 = drProfile?.name?.split("Dr ")


    var startTime = doctorData && doctorData.ClinicMaster.open_time1;
    var endTime = doctorData && doctorData.ClinicMaster.close_time1;
    var startTime2 = doctorData && doctorData.ClinicMaster.open_time2 === '00:00:00' ? null : doctorData && doctorData.ClinicMaster.open_time2;
    var endTime2 = doctorData && doctorData.ClinicMaster.close_time2 === '00:00:00' ? null : doctorData && doctorData.ClinicMaster.close_time2;

    var drstartTime = drProfile && drProfile.ClinicMaster.open_time1;
    var drendTime = drProfile && drProfile.ClinicMaster.close_time1;
    var drstartTime2 = drProfile && drProfile.ClinicMaster.open_time2 === '00:00:00' ? '' : drProfile && drProfile.ClinicMaster.open_time2;
    var drendTime2 = drProfile && drProfile.ClinicMaster.close_time2 === '00:00:00' ? '' : drProfile && drProfile.ClinicMaster.close_time2;

    var category = []
    var specializationOption = []
    var course = []
    var stateListData = []
    var city = []
    var updatecategory = []
    var updateDegree = []
    var profilecategory = []
    var profileDegree = []

    const categoryRender = categoryList && categoryList.map((value) => category.push({
        "value": value.id,
        "label": value.name
    }))

    const specializationRender = specialityList && specialityList.map((value) => specializationOption.push({
        "value": value.id,
        "label": value.speciality
    }))

    const courseRender = courseList && courseList.map((value) => course.push({
        "value": value.id,
        "label": value.course
    }))
    const stateRender = stateList && stateList.map((value) => stateListData.push({
        "value": value.id,
        "label": value.state
    }))
    const cityRender = cityList && cityList.map((value) => city.push({
        "value": value.id,
        "label": value.city
    }))

    const updatecategoryRender = doctorData && doctorData.categorylist && doctorData.categorylist.map((value) => updatecategory.push({
        "value": value.id,
        "label": value.name
    }))

    const updateDegreeRender = doctorData && doctorData.degree && doctorData.degree.map((value) => updateDegree.push({
        "value": value.id,
        "label": value.course
    }))

    const profilecategoryRender = drProfile && drProfile.categorylist && drProfile.categorylist.map((value) => profilecategory.push({
        "value": value.id,
        "label": value.name
    }))

    const profileDegreeRender = drProfile && drProfile.degree && drProfile.degree.map((value) => profileDegree.push({
        "value": value.id,
        "label": value.course
    }))


    const stTmSecRewf = useRef()
    const enTmSecRewf = useRef()

    const cityListFun = (state) => {
        dispatch(cityListTodo(state.value)).then(() => cityListFun2())
    }


    const docterListFun = () => {
        if (docterListRes && docterListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }


    const cityListFun2 = () => {
        if (cityListRes && cityListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {

        setLoading(true)
        doctorId ? dispatch(docterListTodo(doctorId)).then(() => docterListFun()) : <></>
        // id ? dispatch(cityListTodo(doctorData && doctorData.ClinicMaster && doctorData.ClinicMaster.state)).then(() => cityListFun2()) : <></>
        dispatch(categoryListTodo()).then((res1) => {
            if (res1?.payload?.success) {
                dispatch(specialityListTodo()).then((res2) => {
                    if (res2?.payload?.success) {
                        dispatch(courseListTodo()).then((res3) => {
                            if (res3?.payload?.success) {
                                dispatch(stateListTodo()).then((res4) => {
                                    if (res4?.payload?.success) {
                                        id ? dispatch(cityListTodo(doctorData && doctorData.ClinicMaster && doctorData.ClinicMaster.state)).then((res5) => {
                                            if (res5?.payload?.success) {
                                                setLoading(false)
                                            } else {
                                                setLoading(false)
                                            }
                                        }) : setLoading(false)
                                    } else {
                                        setLoading(false)
                                    }
                                })
                            } else {
                                setLoading(false)
                            }
                        })
                    } else {
                        setLoading(false)
                    }
                })
            } else {
                setLoading(false)
            }
        })

    }, []);


    const initialValues = {
        drname: id ?  docterName?.[1] : doctorId ? docterName2?.[1] : '',
        mobile_nu: id ? doctorData.mobile : doctorId ? drProfile && drProfile.mobile : '',
        registration_number: id ? doctorData.registration : doctorId ? drProfile && drProfile.registration : '',
        experience: id ? doctorData.experience : doctorId ? drProfile && drProfile.experience : '',
        brief_biopic: id ? doctorData.moredetails : doctorId ? drProfile && drProfile.moredetails : '',
        dr_image: id ? doctorData.doctorImage : doctorId ? drProfile && drProfile.doctorImage : '',
        gender: id ? doctorData.gender : doctorId ? drProfile && drProfile.gender : '',
        degree: id ? updateDegree && updateDegree : doctorId ? profileDegree && profileDegree : [],
        specialization: id ? doctorData?.specilization : doctorId ? drProfile && drProfile.specilization : '',
        category: id ? updatecategory && updatecategory : doctorId ? profilecategory && profilecategory : [],
        clinicname: id ? doctorData && doctorData.ClinicMaster && doctorData.ClinicMaster.clinicname : doctorId ? drProfile && drProfile.ClinicMaster && drProfile && drProfile.ClinicMaster.clinicname : '',
        contact_person: id ? doctorData && doctorData.ClinicMaster && doctorData.ClinicMaster.contact_person : doctorId ? drProfile && drProfile.ClinicMaster && drProfile && drProfile.ClinicMaster.contact_person : '',
        appoinment_contact: id ? doctorData && doctorData.ClinicMaster && doctorData.ClinicMaster.phone_no : doctorId ? drProfile && drProfile.ClinicMaster && drProfile && drProfile.ClinicMaster.phone_no : '',
        consultation_fees: id ? doctorData.price : doctorId ? drProfile && drProfile.price : '',
        startTimeFirst: id ? doctorData && doctorData.ClinicMaster && doctorData.ClinicMaster.open_time1 : doctorId ? drProfile && drProfile.ClinicMaster && drProfile.ClinicMaster.open_time1 : '',
        EndTimeFirst: id ? doctorData && doctorData.ClinicMaster && doctorData.ClinicMaster.close_time1 : doctorId ? drProfile && drProfile.ClinicMaster && drProfile.ClinicMaster.close_time1 : '',
        startTimesecond: id ? doctorData && doctorData.ClinicMaster && doctorData.ClinicMaster.open_time2 : doctorId ? drProfile && drProfile.ClinicMaster && drProfile.ClinicMaster.open_time2 : '',
        EndTimesecond: id ? doctorData && doctorData.ClinicMaster && doctorData.ClinicMaster.close_time2 : doctorId ? drProfile && drProfile.ClinicMaster && drProfile.ClinicMaster.close_time2 : '',
        state: id ? doctorData && doctorData.ClinicMaster && doctorData.ClinicMaster.state : doctorId ? drProfile && drProfile.ClinicMaster && drProfile && drProfile.ClinicMaster.state : '',
        city: id ? doctorData && doctorData.ClinicMaster && doctorData.ClinicMaster.city : doctorId ? drProfile && drProfile.ClinicMaster && drProfile && drProfile.ClinicMaster.city : '',
        address: id ? doctorData && doctorData.ClinicMaster && doctorData.ClinicMaster.address : doctorId ? drProfile && drProfile.ClinicMaster && drProfile && drProfile.ClinicMaster.address : '',
        clinic_image: id ? doctorData && doctorData.ClinicMaster && doctorData.ClinicMaster.clinicImage : doctorId ? drProfile && drProfile.ClinicMaster && drProfile && drProfile.ClinicMaster.clinicImage : '',
        otherinformation: id ? doctorData && doctorData.ClinicMaster && doctorData.ClinicMaster.clinic_detail : doctorId ? drProfile && drProfile.ClinicMaster && drProfile && drProfile.ClinicMaster.clinic_detail : '',
        latitude: id ? doctorData && doctorData.ClinicMaster && doctorData.ClinicMaster.latitude : doctorId ? drProfile && drProfile.ClinicMaster && drProfile && drProfile.ClinicMaster.latitude : '',
        longitude: id ? doctorData && doctorData.ClinicMaster && doctorData.ClinicMaster.longitude : doctorId ? drProfile && drProfile.ClinicMaster && drProfile && drProfile.ClinicMaster.longitude : '',
        remove_slot: ''
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: id ? UpdateDoctorSchema : AddDoctorSchema,
        enableReinitialize: true,

        onSubmit: (values, action) => {
            {
                id ? dispatch(updateDoctorTodo({ bodyFormData, id })).then(() => updateDoctorFun()) :
                    doctorId ? dispatch(updateProfileTodo({ bodyFormData, doctorId })).then(() => profileUpdate()) :

                        dispatch(addDoctorTodo(bodyFormData)).then(() => doctorAdd(action))
            }
            setLoading(true)

        },
    })

    const doctorAdd = (action) => {
        if (addDoctorRes && addDoctorRes.success) {
            setLoading(false)
            toast.success(addDoctorRes && addDoctorRes.message, { position: "bottom-right" })
            setTimeout(() => {
                navigate('../doctorList')
            }, 1500);
            action.resetForm()
        } else {
            setLoading(false)
            toast.error(addDoctorRes && addDoctorRes.message, { position: "bottom-right" })
        }
    }

    const updateDoctorFun = () => {
        if (updateDoctorRes && updateDoctorRes.success) {
            setLoading(false)
            toast.success(updateDoctorRes && updateDoctorRes.message, { position: "bottom-right" })
            setTimeout(() => {
                navigate('../doctorList')
            }, 1500);
        } else {
            setLoading(false)
            toast.error(updateDoctorRes && updateDoctorRes.message, { position: "bottom-right" })
        }
    }

    const profileUpdate = () => {
        if (updateProfileRes && updateProfileRes.success) {
            setLoading(false)
            toast.success(updateProfileRes && updateProfileRes.message, { position: "bottom-right" })

        } else {
            setLoading(false)
            toast.error(updateProfileRes && updateProfileRes.message, { position: "bottom-right" })
        }
    }

    let bodyFormData = new FormData();

    bodyFormData.append("name", values.drname)
    bodyFormData.append("mobile", values.mobile_nu)
    bodyFormData.append("registration", values.registration_number)
    bodyFormData.append("experience", values.experience)
    bodyFormData.append("biopic", values.brief_biopic)
    bodyFormData.append("gender", values.gender)
    bodyFormData.append("qualification", JSON.stringify(values.degree))
    bodyFormData.append("specilization", values.specialization)
    bodyFormData.append("category", JSON.stringify(values.category))
    bodyFormData.append("fees", values.consultation_fees)
    bodyFormData.append("clinicname", values.clinicname)
    bodyFormData.append("contact_person", values.contact_person)
    bodyFormData.append("contact_no", values.appoinment_contact)
    bodyFormData.append("open_time1", values.startTimeFirst)
    bodyFormData.append("close_time1", values.EndTimeFirst)
    bodyFormData.append("open_time2", values.startTimesecond)
    bodyFormData.append("close_time2", values.EndTimesecond)
    bodyFormData.append("city", values.city)
    bodyFormData.append("state", values.state)
    bodyFormData.append("address", values.address)
    bodyFormData.append("clinic_detail", values.otherinformation)
    bodyFormData.append("latitude", values.latitude)
    bodyFormData.append("longitude", values.longitude)
    bodyFormData.append("path", "category")
    bodyFormData.append("doctorImage", values.dr_image)
    bodyFormData.append("clinicImage", values.clinic_image)
    bodyFormData.append("user_id", localStorage.getItem('userId'))


    // GOOGLE MAP LOCATION --------------------------

    const inputRef = useRef()
    const [markerPosition, setMarkerPosition] = useState(
        {
            lat: id ? doctorData && doctorData.ClinicMaster && parseFloat(doctorData.ClinicMaster.latitude) : doctorId ? drProfile && drProfile.ClinicMaster && drProfile && parseFloat(drProfile.ClinicMaster.latitude) : 22.720,
            lng: id ? doctorData && doctorData.ClinicMaster && parseFloat(doctorData.ClinicMaster.longitude) : doctorId ? drProfile && drProfile.ClinicMaster && drProfile && parseFloat(drProfile.ClinicMaster.longitude) : 75.859
        });

    const handlePlaceChanged = () => {
        const [place] = inputRef.current.getPlaces();
        setMarkerPosition({ lat: place && place.geometry && place.geometry.location && place.geometry.location.lat(), lng: place && place.geometry && place.geometry.location && place.geometry.location.lng() });
        map.setCenter({ lat: place && place.geometry && place.geometry.location && place.geometry.location.lat(), lng: place && place.geometry && place.geometry.location && place.geometry.location.lng() });
        map.setZoom(14);
        setMap(map)
        setFieldValue('latitude', place && place.geometry && place.geometry.location && place.geometry.location.lat())
        setFieldValue('longitude', place && place.geometry && place.geometry.location && place.geometry.location.lng())
    }

    const handleMarkerDragged = newPosition => {

        setMarkerPosition(({ lat: newPosition.latLng?.lat(), lng: newPosition.latLng?.lng() }));
        setFieldValue('latitude', newPosition.latLng?.lat())
        setFieldValue('longitude', newPosition.latLng?.lng())
    };

    return (
        <>

            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='master_form_section'>
                            {
                                loading ?
                                    <div className='d-flex justify-content-center pb-5 pt-5'>
                                        <Loader />
                                    </div> :
                                    <form onSubmit={handleSubmit}>
                                        <div className='row'>
                                            <h4 className='text-center' style={{ color: 'grey' }}>BASIC DETAILS</h4>
                                            <hr />
                                            <div className='col-md-4 mb-3'>
                                                {/* <TextInput
                                                    name='drname'
                                                    label={'Doctor Name'}
                                                    value={values.drname}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                /> */}
                                                <label className="form-label label_head">Doctor Name</label>
                                                <div class="input-group mb-3">
                                                    {id && doctorId ?<span class="input-group-text input_filed" id="basic-addon1">{docterName?.[1]}</span>: <span class="input-group-text input_filed" id="basic-addon1">Dr</span> }
                                                    <input
                                                        type="text"
                                                        className="form-control input_filed"
                                                        name='drname'
                                                        value={values.drname}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1" />
                                                </div>


                                                {errors.drname && touched.drname ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.drname}</span>) : null}
                                            </div>
                                            <div className='col-md-4 mb-3'>

                                                <TextInput
                                                    name='mobile_nu'
                                                    label={'Mobile No.'}
                                                    value={values.mobile_nu}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} />

                                                {errors.mobile_nu && touched.mobile_nu ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.mobile_nu}</span>) : null}

                                            </div>
                                            <div className='col-md-4 mb-3'>
                                                <NumberInput
                                                    name='registration_number'
                                                    label={'Registration No.'}
                                                    value={values.registration_number}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.registration_number && touched.registration_number ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.registration_number}</span>) : null}

                                            </div>
                                            <div className='col-md-4 mb-3'>

                                                <NumberInput
                                                    name='experience'
                                                    label={'Experience (In Years)'}
                                                    value={values.experience}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.experience && touched.experience ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.experience}</span>) : null}

                                            </div>
                                            <div className='col-md-4 mb-3'>

                                                <TextAreaInput
                                                    name='brief_biopic'
                                                    label={'Brief Biopic '}
                                                    value={values.brief_biopic}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} />
                                                {errors.brief_biopic && touched.brief_biopic ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.brief_biopic}</span>) : null}

                                            </div>
                                            <div className='col-md-4 mb-3'>
                                                <FileInput
                                                    name='dr_image'
                                                    label={'Doctor Image '}
                                                    onBlur={handleBlur}
                                                    onChange={(e) => setFieldValue("dr_image", e.currentTarget.files[0])}
                                                />
                                                {errors.dr_image && touched.dr_image ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.dr_image}</span>) : null}

                                            </div>
                                            <div className='col-md-4'>
                                                <RadioInput
                                                    name="gender"
                                                    label={'Gender'}
                                                    value={values.gender}
                                                    checkedValue={doctorData && doctorData.gender || drProfile && drProfile.gender}
                                                    // defaultCheckedMale={doctorData && doctorData.gender || drProfile && drProfile.gender == 'Female' ? false : true}
                                                    // defaultCheckedFemale={doctorData && doctorData.gender || drProfile && drProfile.gender == 'Male' ? false : true}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.gender && touched.gender ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.gender}</span>) : null}

                                            </div>
                                            <h4 className='text-center' style={{ color: 'grey' }}>EDUCATION DETAILS</h4>
                                            <hr />
                                            <div className='col-md-4 mb-3'>

                                                <MultiSelectInput
                                                    name="degree"
                                                    options={course}
                                                    label={'Degree'}
                                                    value={id ? updateDegree && updateDegree : doctorId ? profileDegree && profileDegree : values.degree}
                                                    onChange={(e) =>
                                                        setFieldValue('degree', e)
                                                    }
                                                    onBlur={handleBlur}
                                                />
                                                {errors.degree && touched.degree ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.degree}</span>) : null}

                                            </div>
                                            <div className='col-md-4 mb-3'>
                                                <ReactSelectInput
                                                    name="specialization"
                                                    options={specializationOption}
                                                    label={'Specialization'}
                                                    value={id ? specializationOption.find((option) => option.value === values.specialization) : doctorId ? drProfile && specializationOption.find((option) => option.value === drProfile.specilization) : values.specialization}
                                                    onChange={(e) =>
                                                        setFieldValue('specialization', e.value)
                                                    }
                                                    onBlur={handleBlur}
                                                />
                                                {errors.specialization && touched.specialization ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.specialization}</span>) : null}

                                            </div>
                                            <div className='col-md-4 mb-3'>
                                                <MultiSelectInput
                                                    name="category"
                                                    options={category}
                                                    label={'Category'}
                                                    value={id ? updatecategory && updatecategory : doctorId ? profilecategory && profilecategory : values.category}
                                                    onChange={(e) =>
                                                        setFieldValue('category', e)
                                                    }
                                                    onBlur={handleBlur}
                                                />
                                                {errors.category && touched.category ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.category}</span>) : null}

                                            </div>
                                            <h4 className='text-center' style={{ color: 'grey' }}>CLINIC DETAILS</h4>
                                            <hr />
                                            <div className='col-md-4 mb-3'>
                                                <TextInput
                                                    name='clinicname'
                                                    label={'Clinic Name'}
                                                    value={values.clinicname}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.clinicname && touched.clinicname ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.clinicname}</span>) : null}

                                            </div>
                                            <div className='col-md-4 mb-3'>


                                                <TextInput
                                                    name='contact_person'
                                                    label={'Contact Person'}
                                                    value={values.contact_person}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.contact_person && touched.contact_person ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.contact_person}</span>) : null}

                                            </div>
                                            <div className='col-md-4 mb-3'>

                                                <TextInput
                                                    name='appoinment_contact'
                                                    label={'Appointment Contact No.'}
                                                    value={values.appoinment_contact}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.appoinment_contact && touched.appoinment_contact ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.appoinment_contact}</span>) : null}

                                            </div>
                                            <div className='col-md-4 mb-3'>

                                                <NumberInput
                                                    name='consultation_fees'
                                                    label={'Consultation  Fees'}
                                                    value={values.consultation_fees}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.consultation_fees && touched.consultation_fees ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.consultation_fees}</span>) : null}
                                            </div>
                                            <div className='col-md-4 mb-3'>
                                                <ReactSelectInput
                                                    name="state"
                                                    options={stateListData}
                                                    onChange={(e) => {
                                                        cityListFun(e)
                                                        setFieldValue('state', e.value)

                                                    }}
                                                    value={id ? doctorData && stateListData.find((option) => option.value === doctorData.ClinicMaster.state) : doctorId ? drProfile && stateListData.find((option) => option.value === drProfile.ClinicMaster.state) : values.state}

                                                    onBlur={handleBlur}
                                                    label={'State'}
                                                />
                                                {errors.state && touched.state ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.state}</span>) : null}

                                            </div>
                                            <div className='col-md-4 mb-3'>
                                                <ReactSelectInput
                                                    name="city"
                                                    options={city}
                                                    label={'City'}
                                                    value={id ? doctorData && city.find((option) => option.value === doctorData.ClinicMaster.city) : doctorId ? drProfile && city.find((option) => option.value === drProfile.ClinicMaster.city) : values.city}
                                                    onChange={(e) => {
                                                        setFieldValue('city', e.value)

                                                    }}
                                                    onBlur={handleBlur}
                                                />
                                                {errors.city && touched.city ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.city}</span>) : null}

                                            </div>
                                            <div className='col-md-4 mb-3'>

                                                <TextAreaInput
                                                    name='address'
                                                    label={'Address '}
                                                    value={values.address}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} />
                                                {errors.address && touched.address ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.address}</span>) : null}

                                            </div>
                                            <div className='col-md-4 mb-3'>
                                                <FileInput
                                                    name='clinic_image'
                                                    label={'Clinic Image '}
                                                    onChange={(e) => setFieldValue("clinic_image", e.currentTarget.files[0])}
                                                    onBlur={handleBlur}
                                                />
                                                {/* {errors.clinic_image && touched.clinic_image ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.clinic_image}</span>) : null} */}

                                            </div>
                                            <div className='col-md-4 mb-3'>

                                                <TextAreaInput
                                                    name='otherinformation'
                                                    label={'Other Information '}
                                                    value={values.otherinformation}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} />
                                                {errors.otherinformation && touched.otherinformation ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.otherinformation}</span>) : null}

                                            </div>
                                            <h4 className='text-center' style={{ color: 'grey' }}>TIME SCHEDULE</h4>
                                            <hr />
                                            <div className='col-md-6'>
                                                <h4 className='text-center'>Slot 1</h4>

                                                <div className='row'>
                                                    <div className='col-md-6 mb-3'>
                                                        <TimeInput
                                                            name='startTimeFirst'
                                                            label={'Start Time '}
                                                            defVal={doctorId ? drstartTime : startTime}
                                                            onChange={(e) => {
                                                                setFieldValue('startTimeFirst', e.value)

                                                            }}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.startTimeFirst && touched.startTimeFirst ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.startTimeFirst}</span>) : null}

                                                    </div>
                                                    <div className='col-md-6 mb-3'>
                                                        <TimeInput
                                                            name='EndTimeFirst'
                                                            label={'End Time '}
                                                            defVal={doctorId ? drendTime : endTime}
                                                            onChange={(e) => {
                                                                setFieldValue('EndTimeFirst', e.value)
                                                            }}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.EndTimeFirst && touched.EndTimeFirst ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.EndTimeFirst}</span>) : null}

                                                    </div>
                                                </div>

                                            </div>
                                            <div className='col-md-6'>
                                                <h4 className='text-center'>Slot 2</h4>

                                                <div className='row'>
                                                    <div className='col-md-6 mb-3'>
                                                        <TimeInput
                                                            name='startTimesecond'
                                                            label={'Start Time '}
                                                            defVal={doctorId ? values.remove_slot === true ? "" : drstartTime2 : startTime2}
                                                            reference={stTmSecRewf}
                                                            onChange={(e) => {
                                                                setFieldValue('startTimesecond', e.value)

                                                            }}
                                                            onBlur={handleBlur}
                                                        />
                                                        {/* {errors.startTimesecond && touched.startTimesecond ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.startTimesecond}</span>) : null} */}

                                                    </div>
                                                    <div className='col-md-6 mb-3'>
                                                        <TimeInput
                                                            name='EndTimesecond'
                                                            label={'End Time '}
                                                            reference={enTmSecRewf}
                                                            defVal={doctorId ? values.remove_slot === true ? "" : drendTime2 : endTime2}
                                                            onChange={(e) => {
                                                                setFieldValue('EndTimesecond', e.value)


                                                            }}
                                                            onBlur={handleBlur}
                                                        />
                                                        {/* {errors.EndTimesecond && touched.EndTimesecond ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.EndTimesecond}</span>) : null} */}

                                                    </div>
                                                    {id ? <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" name="remove_slot" id="flexCheckDefault"
                                                            onChange={(e) => {
                                                                setFieldValue('EndTimesecond', '')
                                                                setFieldValue('startTimesecond', '')
                                                                setFieldValue('remove_slot', 'true')
                                                                stTmSecRewf && stTmSecRewf.current && stTmSecRewf.current.setValue([])
                                                                enTmSecRewf && enTmSecRewf.current && enTmSecRewf.current.setValue([])
                                                            }}
                                                            onBlur={handleBlur}
                                                            value='true'
                                                        />
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            Remove Slot 2
                                                        </label>
                                                    </div> : ''}
                                                </div>
                                            </div>
                                            <h4 className='text-center mt-2' style={{ color: 'grey' }}>ADD YOUR GOOGLE LOCATION </h4>
                                            <hr />
                                            <div className='col-md-4'>
                                                <div className='row'>
                                                    <div className='col-md-12 mb-3'>

                                                        <TextInput
                                                            name='latitude'
                                                            label={'Latitude'}
                                                            readOnly={'readOnly'}
                                                            value={markerPosition.lat}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.latitude && touched.latitude ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.latitude}</span>) : null}

                                                    </div>
                                                    <div className='col-md-12 mb-3'>

                                                        <TextInput
                                                            name=' longitude'
                                                            label={' Longitude'}
                                                            readOnly={'readOnly'}
                                                            value={markerPosition.lng}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.longitude && touched.longitude ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.longitude}</span>) : null}

                                                    </div>
                                                </div>
                                            </div>


                                            <div className='col-md-8'>

                                                {isLoaded && markerPosition ? (
                                                    <GoogleMap
                                                        mapContainerStyle={containerStyle}
                                                        // center={center}
                                                        zoom={3}
                                                        onLoad={onLoad}
                                                        onUnmount={onUnmount}
                                                        onClick={handleMarkerDragged}
                                                        markerPosition={markerPosition}
                                                        onMarkerDragged={handleMarkerDragged}

                                                    >
                                                        <Marker
                                                            position={markerPosition}
                                                            draggable={true}
                                                            onDragEnd={event => {
                                                                const lat = event.latLng.lat();
                                                                const lng = event.latLng.lng();
                                                                handleMarkerDragged(event);
                                                            }}
                                                        />
                                                        <StandaloneSearchBox
                                                            onLoad={ref => (inputRef.current = ref)}
                                                            onPlacesChanged={handlePlaceChanged}
                                                        >
                                                            <input
                                                                type='text'
                                                                className='from-control google_search'
                                                                placeholder='Search Location'
                                                            />
                                                        </StandaloneSearchBox>
                                                        { /* Child components, such as markers, info windows, etc. */}
                                                        <></>
                                                    </GoogleMap>
                                                ) : <></>
                                                }

                                            </div>
                                            <div className='col-md-12 '>
                                                <AddButton
                                                    label={id ? 'Update' : 'Save'} />
                                            </div>
                                        </div>
                                    </form>
                            }

                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default AddUpdateDoctor;
