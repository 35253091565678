import React, { useState, useEffect } from 'react'
import Loader from '../../../component/Loader/Loader'
import DataTables from '../../../component/DataTable/DataTable'
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { diseaseListRes, diseaseListTodo } from '../../../redux/slices/Master/Disease/diseaseList';
import { deleteDiseaseTodo, deleteDiseaseRes } from '../../../redux/slices/Master/Disease/deleteDisease'

export default function DiseaseList(props) {
    const { bool, setBool } = props;
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const diseaseListData = useSelector(state => state && state.diseaseList && state.diseaseList.data && state.diseaseList.data.data)

    const edit_disease = (params) => {
        navigate(`../update_disease/${params.data.id}`, { state: params && params.data })
    }

    const del_disease = (id) => {
        Swal.fire({
            title: "Disease Delete",
            text: "Are You Sure Want To Delete This Disease ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteDiseaseTodo(id)).then(() => deleteDiseaseFun())
            }
        })
    }

    const deleteDiseaseFun = () => {
        if (deleteDiseaseRes && deleteDiseaseRes.success) {
            setBool(!bool)
            Swal.fire(
                "Disease",
                'Disease Deleted Successfully',
                'success'
            )
        } else {
            toast.error(deleteDiseaseRes && deleteDiseaseRes.message, { position: "bottom-right" })
        }
    }

    useEffect(() => {
        setLoading(true)
        dispatch(diseaseListTodo()).then(() => diseaseListFun())
    }, [bool])

    const diseaseListFun = () => {
        if (diseaseListRes && diseaseListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)

        }
    }

    const columnDefs = [
        {
            headerName: 'S.NO.',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 100,
        },
        {
            headerName: 'DISEASE NAME',
            field: 'name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },

        {
            headerName: 'STATUS',
            field: 'status',
            cellRenderer: params => params.value == 'Active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,

        },
        {
            field: 'ACTIONS',
            cellRenderer: params =>
                <div>
                    <FiEdit size={20} className='edit'
                        style={{ color: '#47BB4C', cursor: "pointer" }}
                        onClick={() => edit_disease(params)}

                    />
                    <RiDeleteBin6Line size={20} className='delete ms-3'
                        style={{ color: '#EF3D50', cursor: "pointer" }}
                        onClick={() => del_disease(params && params.data && params.data.id)}

                    />


                </div>,
            wrapText: true,
            autoHeight: true,
        }
    ]
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form_head">
                            <h4> Disease List</h4>
                        </div>
                    </div>
                    {
                        loading ?
                            <div className='d-flex justify-content-center mt-5 pt-5'>
                                <Loader />
                            </div>
                            :
                            diseaseListData && diseaseListData.length ?
                                <div className='mt-3'>
                                    <DataTables
                                        rowData={diseaseListData}
                                        columnDefs={columnDefs} />
                                </div>
                                :
                                <div className='d-flex empty_data_section justify-content-center mt-3'>
                                    <h4>Disease List Not Found</h4>
                                </div>}
                </div>
            </div>
        </>
    )
}
