import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var deleteCategoryRes;
export const deleteCategoryTodo = createAsyncThunk(' deleteCategory', async (id) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'category_delete',
            data: {
                "category_id": id.toString()
            }
        })
        return deleteCategoryRes = res.data

    } catch (error) {
        return deleteCategoryRes = error.response.data
    }
})