import React from 'react';

const EmailInput = (props) => {
    return (
        <>
            <label className="form-label label_head">{props.label}</label>
            <input
                type="email"
                className="form-control input_filed"
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur} />
        </>
    );
}

export default EmailInput;
