
import React, { useEffect, useState } from 'react';
import TextAreaInput from '../../InputFiled/TextAreaInput';
import TextInput from '../../InputFiled/TextInput';
import Loader from '../../Loader/Loader';
import FileInput from '../../InputFiled/FileInput';
import AddButton from '../../Button/AddButton';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import MultiSelectInput from '../../InputFiled/MultiSelectInput';
import { userListRes, userListTodo } from '../../../redux/slices/UserSlices/userList';
import { AddNotificationSchema } from './Validation';
import { addNotificationRes, addNotificationTodo } from '../../../redux/slices/Notification/AddNotification';


export default function AddNotificationForm(props) {

    const { bool, setBool } = props;
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const userData = useSelector(state => state && state.userList && state.userList.data && state.userList.data.data)
    var userOption = []
    const userRender = userData && userData.map((value) => userOption.push({
        "value": value.id,
        "label": value.username + ' (' + value.mobile + ')'
    }))

    const initialValues = {
        type: '',
        user: '',
        title: '',
        description: '',
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: AddNotificationSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(addNotificationTodo(bodyFormData)).then(() => addNotificationFun())
            action.resetForm()
        },
    })

    let bodyFormData = new FormData();

    bodyFormData.append("title", values.title)
    bodyFormData.append("type", values.type)
    bodyFormData.append("description", values.description)
    bodyFormData.append("path", "user")
    bodyFormData.append("notification_image", values.image)
    bodyFormData.append("user_id", values.type === "all" ? JSON.stringify([]) : JSON.stringify(values.user))

    const addNotificationFun = () => {
        if (addNotificationRes && addNotificationRes.success) {
            toast.success(addNotificationRes && addNotificationRes.message, { position: "bottom-right" })
            setBool(!bool)
            setLoading(false)


        } else {
            toast.error(addNotificationRes && addNotificationRes.message, { position: "bottom-right" })
            setLoading(false)

        }
    }

    const userListFun = () => {
        if (userListRes && userListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        setLoading(true)
        dispatch(userListTodo()).then(() => userListFun())
    }, [])

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='master_form_section'>
                            {loading ?
                                <div className='d-flex justify-content-center mt-5'>
                                    <Loader />
                                </div> :
                                <form onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className='col-md-3 mb-3'>
                                            <label className="form-label label_head"> Type</label>
                                            <select className="form-select input_filed"
                                                name='type'
                                                value={values.type}
                                                onChange={handleChange}
                                                onBlur={handleBlur}>
                                                <option value=''>Select</option>
                                                <option value="all">All</option>
                                                <option value="multiple">Multiple</option>
                                            </select>
                                            {errors.type && touched.type ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.type}</span>) : null}

                                        </div>
                                        {values.type == 'multiple' ?
                                            <div className='col-md-3'>
                                                <MultiSelectInput
                                                    name="user"
                                                    options={userOption}
                                                    label={'User'}
                                                    onChange={(e) =>
                                                        setFieldValue('user', e)
                                                    }
                                                    onBlur={handleBlur}
                                                />
                                                {errors.user && touched.user ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.user}</span>) : null}
                                            </div> : ''}
                                        <div className='col-md-3 mb-3'>
                                            <TextInput
                                                name='title'
                                                label={'Title '}
                                                value={values.title}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.title && touched.title ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.title}</span>) : null}

                                        </div>
                                        <div className='col-md-3 mb-3'>
                                            <TextAreaInput
                                                name='description'
                                                label={'Description '}
                                                value={values.description}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.description && touched.description ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.description}</span>) : null}

                                        </div>
                                        <div className='col-md-3 mb-3'>
                                            <FileInput
                                                name='image'
                                                label={'Notification Image'}
                                                onChange={(e) => setFieldValue("image", e.currentTarget.files[0])}
                                                onBlur={handleBlur}
                                            />
                                        </div>
                                        <div className='col-md-12 '>
                                            <AddButton
                                                label={'Save'} />
                                        </div>
                                    </div>
                                </form>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
