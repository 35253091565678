import React from 'react'
import ReasonForm from '../../../component/Form/MasterForm/ReasonForm/ReasonForm'

export default function UpdateReason() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form_head">
                            <h4>Update Reason</h4>
                        </div>
                        <ReasonForm />
                    </div>
                </div>
            </div>
        </>
    )
}
