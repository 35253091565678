import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addPaymentRes;
export const addPaymentTodo = createAsyncThunk(' addPayment', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'payment',
            data: {
                "order_id": data && data.order_id,
                "order_date": data && data.order_date,
                "user_id": data && data.user_id,
                "patient_id": data && data.patient_id,
                "transection_id": data && data.transection_id,
                "payment_mode": data && data.payment_mode,
                "type_for": "desktop"
            },
            headers: { "Content-Type": "application/json" }
        })
        return addPaymentRes = res.data

    } catch (error) {
        return addPaymentRes = error.response.data
    }
})