import React from 'react';

const NumberInput = (props) => {
    return (
        <>
            <label className="form-label label_head">{props.label}</label>
            <input
                type="number"
                className="form-control input_filed"
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
            />
        </>
    );
}

export default NumberInput;
