import React from 'react'
import PublicNavbar from './PublicNavbar'

export default function ShippingDelivery() {
    return (
        <>
            <div>
                
                <PublicNavbar />
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className='shipping_delivery'>
                                <h3>Shipping & Delivery</h3>
                                <h5 className='mt-3'>Shipping is not applicable for business </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
