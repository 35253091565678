
import React, { useState, useEffect } from 'react'
import DataTables from '../../../component/DataTable/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { categoryListRes, categoryListTodo } from '../../../redux/slices/Master/Categorgy/categorylist';
import { deleteCategoryTodo, deleteCategoryRes } from '../../../redux/slices/Master/Categorgy/deleteCategory';
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import Loader from '../../../component/Loader/Loader';
import { MdCancel } from "react-icons/md";



const CategoryList = (props) => {
    const { bool, setBool } = props;
    const [loading, setLoading] = useState(false)
    const [deleteMsg, setDeleteMsg] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const categoryList = useSelector(state => state && state.categoryList && state.categoryList.data && state.categoryList.data.data)


    useEffect(() => {
        setLoading(true)
        dispatch(categoryListTodo()).then(() => categoryListFun())
    }, [bool])

    const categoryListFun = () => {
        if (categoryListRes && categoryListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }


    const del_category = (id) => {

        Swal.fire({
            title: "Category Delete",
            text: "Are You Sure Want To Delete This Category ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteCategoryTodo(id)).then(() => deletecategoryFun())
            }
        })
    }

    const deletecategoryFun = () => {
        if (deleteCategoryRes && deleteCategoryRes.success) {
            setBool(!bool)
            Swal.fire(
                "Category",
                'Category Deleted Successfully',
                'success'
            )
        } else {
            // toast.error(deleteCategoryRes && deleteCategoryRes.message, { position: "bottom-right" })
            Swal.fire({
                title:  (deleteCategoryRes && deleteCategoryRes.data && deleteCategoryRes.data.count) ? deleteCategoryRes.data.count.toString() : "",
                text: (deleteCategoryRes && deleteCategoryRes.message) ? deleteCategoryRes.message.toString() : "",
                icon: 'warning', // This should be one of 'success', 'error', 'warning', 'info', or 'question'
                customClass: {
                  icon: 'warning'
                }
              });
        }
    }

    const edit_category = (params) => {
        navigate(`../update_category/${params.data.id}`, { state: params && params.data })
    }

    const columnDefs = [
        {
            headerName: 'S.NO.',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 100,
        },
        {
            headerName: 'CATEGORY NAME',
            field: 'name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },

        {
            headerName: 'IMAGE',
            field: 'categoryImage',
            cellRenderer: params => <div className='table_img_sec'>
                <img className=" img-fluid table_data_img" src={process.env.REACT_APP_IMAGE_URL + params.data.categoryImage} alt="image" />
            </div>,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },



        {
            headerName: 'STATUS',
            field: 'status',
            cellRenderer: params => params.value == 'Active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true
        },
        {
            field: 'ACTIONS',
            cellRenderer: params =>
                <div>
                    <FiEdit size={20} className='edit'
                        style={{ color: '#47BB4C', cursor: "pointer" }}
                        onClick={() => edit_category(params)}
                    />
                    <RiDeleteBin6Line size={20} className='delete ms-3'
                        style={{ color: '#EF3D50', cursor: "pointer" }}
                        onClick={() => del_category(params && params.data && params.data.id)}
             
                    />
                   
                </div>,
            wrapText: true,
            autoHeight: true,
        }
    ]
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form_head">
                            <h4> Category List</h4>
                        </div>
                    </div>
                    {
                        loading ?
                            <div className='d-flex justify-content-center mt-5 pt-5'>
                                <Loader />
                            </div>
                            :
                            categoryList && categoryList.length ?
                                <div className='mt-3'>
                                    <DataTables
                                        rowData={categoryList}
                                        columnDefs={columnDefs}
                                    />
                                </div> :
                                <div className='d-flex empty_data_section justify-content-center mt-3'>
                                    <h4>Category List Not Found</h4>
                                </div>}
                </div>
            </div>

            <div className={deleteMsg ? 'modal fade show d-block' : 'modal fade show d-none'} style={{ background: 'rgba(0,0,0,0.8)' }}>
                <div className="modal-dialog modal-dialog-centered modal-md  ">
                    <div className="modal-content pb-5" >
                        <div className="modal-header  "  >
                            <h5 className='text-center ' > Category</h5>
                            <span onClick={() => { setDeleteMsg(false); }}><MdCancel style={{ color: 'red' }} size={25} className="mb-2" /></span>
                        </div>
                        <div className='modal-body p-4 '>
                            <div className='d-flex justify-content-center'>
                                <h4 className='text-center delete_msg_category'>{deleteCategoryRes && deleteCategoryRes.data && deleteCategoryRes.data.count}</h4>
                            </div>
                            <h5 className='text-center' style={{ color: "grey" }}> {deleteCategoryRes && deleteCategoryRes.message}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CategoryList;
