import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var planListRes;
export const planListTodo = createAsyncThunk('planList', async (id) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'subscription',
           
            headers: { "Content-Type": "application/json" }
        })
        return planListRes = res.data

    } catch (error) {
        return planListRes = error.response.data
    }
})

const planListSlice = createSlice({
    name: 'planList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(planListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('equipmentList', state.data.response)
        });
        builder.addCase(planListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default planListSlice.reducer; 