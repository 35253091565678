import * as Yup from "yup";

export const AddNotificationSchema = Yup.object({
    type: Yup.string().required(" Select Type") ,
    user: Yup.string().when("type", (val, schema) => {
        if (val == 'multiple') {
            return Yup.array().required('Select User').min(1, " Select at least One Category");
        } else {
            return Yup.string().notRequired();
        }
    }),
    title:Yup.string().required(" Enter Title"),
    description:Yup.string().required(" Enter Description")

})