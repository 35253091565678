import React, { useState, useEffect } from 'react'
import Loader from '../../component/Loader/Loader'
import DataTables from '../../component/DataTable/DataTable'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { patientListTodo, patientListRes } from '../../redux/slices/PatientSlices/PatientList';
import { doctorPatientListRes, doctorPatientListTodo } from '../../redux/slices/PatientSlices/DoctorPatientList';

export default function PatientList() {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const patientListData = useSelector(state => state && state.patientList && state.patientList.data && state.patientList.data.data)
    const drPatientList = useSelector(state => state && state.doctorPatientList && state.doctorPatientList.data && state.doctorPatientList.data.data)
    const doctorID = localStorage.getItem('doctorID');
    const role = localStorage.getItem('userRole');

    useEffect(() => {
        setLoading(true)
        role === 'admin' ? dispatch(patientListTodo()).then(() => patientListFun()) : dispatch(doctorPatientListTodo({ "doctorID": doctorID })).then(() => doctorPatientList())
    }, [])

    const patientListFun = () => {
        if (patientListRes && patientListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const doctorPatientList = () => {
        if (doctorPatientListRes && doctorPatientListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const columnDefs = [
        {
            headerName: 'S.NO.',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 100,
        },
        role == "admin" ? {
            headerName: 'PATIENT NAME',
            field: 'name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        } : {
            headerName: 'PATIENT NAME',
            field: 'patient_detail.name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },
        role == "admin" ? {
            headerName: 'MOBILE NO.',
            field: 'phone',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        } :
            {
                headerName: 'MOBILE NO.',
                field: 'patient_detail.phone',
                filter: 'agTextColumnFilter',
                suppressMenu: true,
                filter: true,
                sortable: true,
                floatingFilter: false,
                wrapText: true,
                autoHeight: true,
            },

        role == "admin" ? {
            headerName: 'AGE',
            field: 'age',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 100,

        } :
            {
                headerName: 'AGE',
                field: 'patient_detail.age',
                filter: 'agTextColumnFilter',
                suppressMenu: true,
                filter: true,
                sortable: true,
                floatingFilter: false,
                wrapText: true,
                autoHeight: true,
            maxWidth: 100,

            }
        ,
        role == "admin" ? {
            headerName: 'USER NAME',
            field: 'User.username',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        } : {
            headerName: 'USER NAME',
            field: 'patient_detail.name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },

        role == "admin" ? {
            headerName: 'REM. BOOKING',
            field: 'booking_count',
            cellRenderer: params => params.data.booking_count === null ? '-' : params.data.booking_count ,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        } :
            {
                headerName: 'REM. BOOKING',
                field: 'patient_detail.booking_count',
                filter: 'agTextColumnFilter',
                suppressMenu: true,
                filter: true,
                sortable: true,
                floatingFilter: false,
                wrapText: true,
                autoHeight: true,
            }
        ,
        role == "admin" ? {
            headerName: 'GENDER',
            field: 'gender',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        } : {
            headerName: 'GENDER',
            field: 'patient_detail.gender',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },
        role == "admin" ? {
            headerName: 'END DATE',
            field: 'User_subscription.enddate',
            cellRenderer: params => params && params.data && params.data.User_subscription === null ? '-' : new Date(params && params.data && params.data.User_subscription && params.data.User_subscription.enddate).toLocaleDateString('en-GB'),
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        } : {
            headerName:'END DATE',
            field: 'user_detail.expired_plan_date',
            cellRenderer: params => params && params.data && params.data.user_detail === null ? '-' : new Date(params && params.data && params.data.user_detail && params.data.user_detail.expired_plan_date).toLocaleDateString('en-GB'),
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },


    ]

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form_head">
                            <h4> Patient List</h4>
                        </div>
                    </div>
                    {
                        loading ?
                            <div className='d-flex justify-content-center mt-5 pt-5'>
                                <Loader />
                            </div>
                            :
                            patientListData && patientListData.length || drPatientList && drPatientList.length ?
                                <div className='mt-3'>
                                    <DataTables
                                        rowData={role === 'admin' ? patientListData : drPatientList}
                                        columnDefs={columnDefs}
                                    />
                                </div> :
                                <div className='d-flex empty_data_section justify-content-center mt-3'>
                                    <h4>Patient List Not Found</h4>
                                </div>}
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
