import * as Yup from "yup";

export const AddBannerSchema = Yup.object({
    bannerDetails: Yup.string().required(" Enter  Banner Details ").matches(/^[a-zA-Z]/, "Empty Space Not Allow"),
    image:  Yup.mixed().required('Image is required'),
    status: Yup.string().required(" Select Status"),

})

export const UpdateBannerSchema = Yup.object({
    bannerDetails: Yup.string().required(" Enter  Banner Details ").matches(/^[a-zA-Z]/, "Empty Space Not Allow"),
    status: Yup.string().required(" Select Status"),

})

