import React, { useState, useEffect } from 'react'
import Loader from '../../../component/Loader/Loader';
import DataTables from '../../../component/DataTable/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import { FiEdit } from "react-icons/fi";
import Swal from 'sweetalert2'
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaLink } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { bannerListRes, bannerListTodo } from '../../../redux/slices/Master/HomeBanner/bannerList';
import { deleteBannerTodo, deleteBannerRes } from '../../../redux/slices/Master/HomeBanner/deleteBanner'
export default function BannerList(props) {

    const { bool, setBool } = props;
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const bannerListData = useSelector(state => state && state.bannerList && state.bannerList.data && state.bannerList.data.data)

    const edit_banner = (params) => {
        navigate(`../update_banner/${params.data.id}`, { state: params && params.data })
    }

    const del_banner = (id) => {
        Swal.fire({
            title: "Banner Delete",
            text: "Are You Sure Want To Delete This Banner ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteBannerTodo(id)).then(() => deleteBannerFun())
            }
        })
    }

    const deleteBannerFun = () => {
        if (deleteBannerRes && deleteBannerRes.success) {
            setBool(!bool)
            Swal.fire(
                "Banner",
                'Banner Deleted Successfully',
                'success'
            )
        } else {
            toast.error(deleteBannerRes && deleteBannerRes.message, { position: "bottom-right" })
        }
    }

    useEffect(() => {
        setLoading(true)
        dispatch(bannerListTodo()).then(() => bannerListFun())
    }, [bool])

    const bannerListFun = () => {
        if (bannerListRes && bannerListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const columnDefs = [
        {
            headerName: 'S.NO.',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 100,
        },
        {
            headerName: 'BANNER DETAILS',
            field: 'detail',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },

        {
            headerName: 'IMAGE',
            field: 'bannerImage',
            cellRenderer: params => <div className='table_img_sec'>
                <img className=" img-fluid table_data_img" src={process.env.REACT_APP_IMAGE_URL + params.data.bannerImage} alt="image" />
            </div>,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 150,
        },
        {
            headerName: "BANNER LINK",
            field: "link",
            cellRenderer: params =>
                <a className='ms-2' href={params.value} target='blank'><FaLink />Link</a>
            ,
            wrapText: true,
            autoHeight: true,
            filter: 'agTextColumnFilter', suppressMenu: true, filter: true, wrapText: true, autoHeight: true,
            maxWidth: 180,

        },
        {
            headerName: 'STATUS',
            field: 'status',
            cellRenderer: params => params.value == 'Active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            maxWidth: 150,

        },

        {
            field: 'ACTIONS',
            cellRenderer: params =>
                <div>
                    <FiEdit size={20} className='edit'
                        style={{ color: '#47BB4C', cursor: "pointer" }}
                        onClick={() => edit_banner(params)}

                    />
                    <RiDeleteBin6Line size={20} className='delete ms-3'
                        style={{ color: '#EF3D50', cursor: "pointer" }}
                        onClick={() => del_banner(params && params.data && params.data.id)}

                    />
                </div>,
            wrapText: true,
            autoHeight: true,
            maxWidth: 180,

        }
    ]

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form_head">
                            <h4> HomeBanner List</h4>
                        </div>
                    </div>
                    {
                        loading ?
                            <div className='d-flex justify-content-center mt-5 pt-5'>
                                <Loader />
                            </div>
                            :
                            bannerListData && bannerListData.length ?
                                <div className='mt-3'>
                                    <DataTables
                                        rowData={bannerListData}
                                        columnDefs={columnDefs}
                                    />
                                </div> :
                                <div className='d-flex empty_data_section justify-content-center mt-3'>
                                    <h4>Home Banner List Not Found</h4>
                                </div>}
                </div>
            </div>
        </>
    )
}
