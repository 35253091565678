import React from 'react';
import AddupdateUser from '../../component/Form/UserForm/addupdateUser';

const AddUser = () => {
    return (
        <>
             <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form_head">
                            <h4>Add User</h4>
                        </div>
                        <AddupdateUser/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddUser;
