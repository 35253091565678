import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Link } from 'react-router-dom'
import PublicNavbar from './PublicNavbar'


export default function AboutsUs() {
    return (
        <>
            <div>
                <PublicNavbar />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className='privacy_term_view'>
                                <PerfectScrollbar>
                                    <div className='ps-3 pe-3'>
                                        <h2>About Us</h2>
                                        <p>"Doctor on Discount" is a privately owned Health Tech company which will aim to uplift the level of Primary Healthcare in our country by integrating various medical streams and specialities onto one common digital platform.
                                            The general public can get the benefit of discounted consultations by empanelled medicos by registering on the mobile application at a normal service charge of rupees 99/-  per year. They can choose the empanelled doctors according to geographical location , speciality, qualification ,work experience and consultation fees.</p>
                                    </div>

                                </PerfectScrollbar>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
