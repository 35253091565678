import React from 'react'
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { MdCancel } from "react-icons/md";
import AddButton from '../../Button/AddButton';
import { forgotPswRes, forgotPswTodo } from '../../../redux/slices/Login/changePassword';

export default function ForgotPassword(props) {
    const { forgotPsw, setForgotPsw, OTP, setOTP } = props;
    const registerdNumber = localStorage.getItem('number');
    const dispatch = useDispatch()

    const initialValues = {
        number: registerdNumber

    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        onSubmit: (values, action) => {
            dispatch(forgotPswTodo(values)).then(() => forgotPswFun())
            action.resetForm()
        },
    })

    const forgotPswFun = () => {
        if (forgotPswRes && forgotPswRes.success) {
            setForgotPsw(false);
            toast.success(forgotPswRes && forgotPswRes.message, { position: 'bottom-right' })
            localStorage.setItem('contactNumber', forgotPswRes.data[0].mobile_number)
            setTimeout(() => {
                setOTP(true)
            }, 1000);
           
        } else {
            toast.error(forgotPswRes && forgotPswRes.message, { position: 'bottom-right' })
        }
    }

    return (
        <>
            <div className={forgotPsw ? 'modal fade show d-block' : 'modal fade show d-none'} style={{ background: 'rgba(0,0,0,0.8)' }}>
                <div className="modal-dialog modal-dialog-centered modal-md  ">
                    <div className="modal-content pb-5" >
                        <div className="modal-header  "  >
                            <h5 className='text-center ' > Forgot Password</h5>
                            <span onClick={() => { setForgotPsw(false); }}><MdCancel style={{ color: 'red' }} size={25} className="mb-2" /></span>
                        </div>
                        <div className='modal-body  p-4 '>
                            <form onSubmit={handleSubmit}>
                                <h5 className='text-center ps-5 pe-5' style={{color:"grey",fontWeight:"600"}}>An OTP will be sent to your Registered Number</h5>
                                <div className='mt-4 d-flex justify-content-center'>
                                    <AddButton label={'Verify and Sent'} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
