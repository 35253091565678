import React from 'react'
import SpecialityForm from '../../../component/Form/MasterForm/SpecialityForm/SpecialityForm'
 

export default function UpdateSpeciality() {
  return (
    <>
        <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form_head">
                            <h4>Update Speciality</h4>
                        </div>
                      <SpecialityForm/>
                    </div>
                </div>
            </div>
    </>
  )
}
