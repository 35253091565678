import React, { useState, useEffect } from 'react'
import Loader from '../../../component/Loader/Loader'
import DataTables from '../../../component/DataTable/DataTable'
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { courseListTodo, courseListRes } from '../../../redux/slices/Master/Course/courselist';
import { deleteCourseTodo, deleteCourseRes } from '../../../redux/slices/Master/Course/deleteCourse';

export default function CourseList(props) {
    const { bool, setBool } = props
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const courseListData = useSelector(state => state && state.courseList.data && state.courseList.data.data)

    const edit_course = (params) => {
        navigate(`../update_qualification/${params.data.id}`, { state: params && params.data })
    }

    const del_course = (id) => {
        Swal.fire({
            title: "Qualification Delete",
            text: "Are You Sure Want To Delete This Qualification ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteCourseTodo(id)).then(() => deleteCourseFun())
            }
        })
    }

    const deleteCourseFun = () => {
        if (deleteCourseRes && deleteCourseRes.success) {
            setBool(!bool)
            Swal.fire(
                "Qualification",
                'Qualification Deleted Successfully',
                'success'
            )
        } else {
            Swal.fire({
                title:  (deleteCourseRes && deleteCourseRes.data && deleteCourseRes.data.count) ? deleteCourseRes.data.count.toString() : "",
                text: (deleteCourseRes && deleteCourseRes.message) ? deleteCourseRes.message.toString() : "",
                icon: 'warning', // This should be one of 'success', 'error', 'warning', 'info', or 'question'
                customClass: {
                  icon: 'warning'
                }
              });
        }
    }

    const courseListFun = () => {
        if (courseListRes && courseListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        setLoading(true)
        dispatch(courseListTodo()).then(() => courseListFun())
    }, [bool])

    const columnDefs = [
        {
            headerName: 'S.NO.',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 100,
        },
        {
            headerName: 'COURSE NAME',
            field: 'course',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: 'Order No.',
            field: 'ordered',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },

        {
            headerName: 'STATUS',
            field: 'status',
            cellRenderer: params => params.value == 'Active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,

        },
        {
            field: 'ACTIONS',
            cellRenderer: params =>
                <div>
                    <FiEdit size={20} className='edit'
                        style={{ color: '#47BB4C', cursor: "pointer" }}
                        onClick={() => edit_course(params)}

                    />
                    <RiDeleteBin6Line size={20} className='delete ms-3'
                        style={{ color: '#EF3D50', cursor: "pointer" }}
                        onClick={() => del_course(params && params.data && params.data.id)}
                    />

                </div>,
            wrapText: true,
            autoHeight: true,
        }
    ]

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form_head">
                            <h4> Qualification List</h4>
                        </div>
                    </div>
                    {
                        loading ?
                            <div className='d-flex justify-content-center mt-5 pt-5'>
                                <Loader />
                            </div>
                            :
                            courseListData && courseListData.length ?
                                <div className='mt-3'>
                                    <DataTables
                                        rowData={courseListData}
                                        columnDefs={columnDefs} />
                                </div>
                                :
                                <div className='d-flex empty_data_section justify-content-center mt-3'>
                                    <h4>Qualification List Not Found</h4>
                                </div>}
                </div>
            </div>
        </>
    )
}
