import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaUserMd, FaUser, FaRupeeSign } from "react-icons/fa"
import { dashboardRes, dashboardTodo } from '../../redux/slices/Dashboard/dashboard';
import { RiHealthBookFill } from "react-icons/ri"
import { doctorDashboardRes, doctorDashboardTodo } from '../../redux/slices/Dashboard/doctorDashboard';
import 'react-perfect-scrollbar/dist/css/styles.css';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../component/Loader/Loader';


const Dashboard = () => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const dashboardData = useSelector(state => state && state.dashboard && state.dashboard.data && state.dashboard.data.data && state.dashboard.data.data[0])
    const doctorDashboard = useSelector(state => state && state.doctordashboard && state.doctordashboard.data && state.doctordashboard.data.data && state.doctordashboard.data.data[0])
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        dispatch(dashboardTodo()).then(() => dashboardFun())
        dispatch(doctorDashboardTodo()).then(() => doctorDashboardFun())
    }, [])

    const role = localStorage.getItem('userRole');

    const dashboardFun = () => {
        if (dashboardRes && dashboardRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }
    const doctorDashboardFun = () => {
        if (doctorDashboardRes && doctorDashboardRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }
    const bookingDetailsFun = (data) => {
        navigate(`../booking_details/${data.id}`, { state: data && data })
    }

    return (
        <>
            {role === 'admin' ?
                <div className="container-fluid">
                    {loading ?
                        <div className='d-flex justify-content-center mt-5'>
                            <Loader />
                        </div> :
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form_head">
                                    <h4>Dashboard</h4>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 col-md-6  mt-3">
                                        <Link to='/doctorList'>  <div className="card dashboard_card text-center  h-100" >
                                            <div className='d-flex justify-content-around align-items-center '>
                                                <div className=''>
                                                    <span className='dashboard_icon'>
                                                        < FaUserMd size={35} style={{ color: "#ff0002" }} />
                                                    </span>
                                                </div>
                                                <div className=''>
                                                    <h4>Total Doctors</h4>
                                                    <h3 className='text-center'>{dashboardData && dashboardData.Doctordata}</h3>
                                                </div>
                                            </div>
                                        </div></Link>
                                    </div>
                                    <div className="col-lg-6 col-md-6  mt-3">
                                        <div className="card dashboard_card text-center  h-100" >
                                            <div className='d-flex justify-content-around align-items-center '>
                                                <div className=''>
                                                    <span className='dashboard_icon'>
                                                        < FaRupeeSign size={35} style={{ color: "#ff0002" }} />
                                                    </span>
                                                </div>
                                                <div className=''>
                                                    <h4>Total Earnings</h4>
                                                    <h3 className='text-center'>
                                                        {dashboardData && dashboardData.totalearning && dashboardData.totalearning.totalAmount}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6  mt-3">
                                        <Link to='/userlist'>    <div className="card dashboard_card text-center  h-100" >
                                            <div className='d-flex justify-content-around align-items-center '>
                                                <div className=''>
                                                    <span className='dashboard_icon'>
                                                        < FaUser size={35} style={{ color: "#ff0002" }} />
                                                    </span>
                                                </div>
                                                <div className=''>
                                                    <h4>Total Users</h4>
                                                    <h3 className='text-center'>{dashboardData && dashboardData.Userdata}</h3>
                                                </div>
                                            </div>
                                        </div></Link>
                                    </div>
                                    <div className="col-lg-6 col-md-6  mt-3">
                                        <Link to='/patientlist'>    <div className="card dashboard_card text-center  h-100" >
                                            <div className='d-flex justify-content-around align-items-center '>
                                                <div className=''>
                                                    <span className='dashboard_icon'>
                                                        < FaUser size={35} style={{ color: "#ff0002" }} />
                                                    </span>
                                                </div>
                                                <div className=''>
                                                    <h4>Total Patients</h4>
                                                    <h3 className='text-center'>{dashboardData && dashboardData.Patientdata}</h3>
                                                </div>
                                            </div>
                                        </div></Link>
                                    </div>
                                    <div className="col-lg-6 col-md-6  mt-3">
                                        <Link to='/bookinglist'>   <div className="card dashboard_card text-center  h-100" >
                                            <div className='d-flex justify-content-around align-items-center '>
                                                <div className=''>
                                                    <span className='dashboard_icon'>
                                                        < RiHealthBookFill size={35} style={{ color: "#ff0002" }} />
                                                    </span>
                                                </div>
                                                <div className=''>
                                                    <h4>Total Bookings</h4>
                                                    <h3 className='text-center'>
                                                        {dashboardData && dashboardData.TotalAllbooking}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div></Link>
                                    </div>
                                    <div className="col-lg-6 col-md-6  mt-3">
                                        <div className="card dashboard_card text-center  h-100" >
                                            <div className='d-flex justify-content-around align-items-center '>
                                                <div className=''>
                                                    <span className='dashboard_icon'>
                                                        < RiHealthBookFill size={35} style={{ color: "#ff0002" }} />
                                                    </span>
                                                </div>
                                                <div className=''>
                                                    <h4>Today's Booking</h4>
                                                    <h3 className='text-center'>
                                                        {dashboardData && dashboardData.TodayBookingCount}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="form_head">
                                    <h4>Today's Bookings</h4>
                                </div>
                                {dashboardData && dashboardData.Todaybooking.length ?
                                    <div className='dashboardBooking'>
                                        <PerfectScrollbar>
                                            <div className='pt-3 ps-3 pe-3'>
                                                <div className="row">
                                                    {dashboardData && dashboardData.Todaybooking.map((value, index) =>
                                                        <div className="col-md-12 mt-2" key={index}>
                                                            <div style={{ cursor: "pointer" }} onClick={() => bookingDetailsFun(value)} className="card today_Booking">
                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <p>{value.doctor_detail && value.doctor_detail.name}</p>
                                                                    <p>{value.booking_id}</p>
                                                                </div>
                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <p>{value && value.patient_detail && value.patient_detail.name}</p>  </div>

                                                                <div className='d-flex justify-content-between  align-items-center'>
                                                                    <p>
                                                                        {value?.booking_stime && (
                                                                            <>
                                                                                {new Date(`1970-01-01T${value.booking_stime}`).toLocaleTimeString([], {
                                                                                    hour: '2-digit',
                                                                                    minute: '2-digit',
                                                                                    hour12: true,
                                                                                })}
                                                                            </>
                                                                        )}
                                                                    </p>
                                                                    <span className={value.booking_status === 'Pending' ? 'badge ms-1 text-bg-warning' : value.booking_status === 'Cancelled' ? 'badge ms-1 text-bg-danger' : value.booking_status === 'Completed' ? 'badge ms-1 text-bg-success' : ''}> {value.booking_status === 'Pending' ? "Booked" : value.booking_status} </span>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                </div>
                                            </div>
                                        </PerfectScrollbar>
                                    </div> :
                                    <div className='d-flex empty_data_section justify-content-center mt-3'>
                                        <h4>NO BOOKING</h4>
                                    </div>
                                }
                            </div>
                        </div>}
                </div >
                :
                <div className='container-fluid'>
                    {loading ?
                        <div className='d-flex justify-content-center mt-5'>
                            <Loader />
                        </div> :
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form_head">
                                    <h4>Dashboard</h4>
                                </div>

                                <div className="row">

                                    <div className="col-lg-6 col-md-6  mt-3">
                                        <Link to='/bookinglist'> <div className="card dashboard_card text-center  h-100" >
                                            <div className='d-flex justify-content-around align-items-center '>
                                                <div className=''>
                                                    <span className='dashboard_icon'>
                                                        < RiHealthBookFill size={35} style={{ color: "#ff0002" }} />
                                                    </span>
                                                </div>
                                                <div className=''>
                                                    <h4>Total Bookings</h4>
                                                    <h3 className='text-center'>
                                                        {doctorDashboard && doctorDashboard.TotalBooking}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div></Link>
                                    </div>
                                    <div className="col-lg-6 col-md-6  mt-3">
                                        <div className="card dashboard_card text-center  h-100" >
                                            <div className='d-flex justify-content-around align-items-center '>
                                                <div className=''>
                                                    <span className='dashboard_icon'>
                                                        < RiHealthBookFill size={35} style={{ color: "#ff0002" }} />
                                                    </span>
                                                </div>
                                                <div className=''>
                                                    <h4>Today's Booking</h4>
                                                    <h3 className='text-center'>
                                                        {doctorDashboard && doctorDashboard.Todaybooking}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6  mt-3">
                                        <Link to='/patientlist'>   <div className="card dashboard_card text-center  h-100" >
                                            <div className='d-flex justify-content-around align-items-center '>
                                                <div className=''>
                                                    <span className='dashboard_icon'>
                                                        < FaUser size={35} style={{ color: "#ff0002" }} />
                                                    </span>
                                                </div>
                                                <div className=''>
                                                    <h4>Total Patients</h4>
                                                    <h3 className='text-center'>{doctorDashboard && doctorDashboard.Toatalpatient}</h3>
                                                </div>
                                            </div>
                                        </div></Link>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form_head">
                                    <h4>Today's Bookings</h4>
                                </div>
                                <div className='dashboardBooking'>
                                    <PerfectScrollbar>
                                        <div className='pt-3 ps-3 pe-3'>
                                            <div className="row">
                                                {doctorDashboard && doctorDashboard.TodayDoctorbooking.length ?
                                                    doctorDashboard && doctorDashboard.TodayDoctorbooking.map((value, index) =>
                                                        <div className="col-md-12 mt-2">
                                                            <div style={{ cursor: "pointer" }} onClick={() => bookingDetailsFun(value)} className="card today_Booking">
                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <p>{value && value.patient_detail && value.patient_detail.name}</p>
                                                                    <p>{value.booking_id}</p>
                                                                </div>
                                                                {/* <div className='d-flex justify-content-between align-items-center'>
                                                            <p>{value.booking_for == 'other' ? value.patient_detail.name : value.user_detail.username}</p>
                                                        </div> */}

                                                                <div className='d-flex justify-content-between  align-items-center'>
                                                                    {/* <p>{time}</p> */}
                                                                    {value?.booking_stime && (
                                                                        <>
                                                                            {new Date(`1970-01-01T${value.booking_stime}`).toLocaleTimeString([], {
                                                                                hour: '2-digit',
                                                                                minute: '2-digit',
                                                                                hour12: true,
                                                                            })}
                                                                        </>
                                                                    )}
                                                                    <span className={value.booking_status === 'Pending' ? 'badge ms-1 text-bg-warning' : value.booking_status === 'Cancelled' ? 'badge ms-1 text-bg-danger' : value.booking_status === 'Completed' ? 'badge ms-1 text-bg-success' : ''}> {value.booking_status === 'Pending' ? "Booked" : value.booking_status} </span>
                                                                </div>
                                                            </div>
                                                        </div>) :
                                                    <div className='d-flex justify-content-center p-5'>
                                                        <h4 style={{ color: 'grey' }}>NO BOOKINGS</h4>
                                                    </div>

                                                }
                                            </div>
                                        </div>
                                    </PerfectScrollbar>

                                </div>
                            </div>
                        </div>}
                </div>
            }
        </>
    );
}

export default Dashboard;
