import React from 'react';
import Select from 'react-select'

const MultiSelectInput = (props) => {
    return (
        <>
            <label className="form-label label_head "> {props.label}</label>
            <Select
               closeMenuOnSelect={false}
                options={props.options}
                defaultValue={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
                isMulti
                className="react_select "
                classNamePrefix="select"
            />
        </>
    );
}

export default MultiSelectInput;
