import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var deleteBannerRes;
export const  deleteBannerTodo = createAsyncThunk(' deleteBanner', async (id) => {
    try {
        const res = await axios({
            method: "delete",
            url: process.env.REACT_APP_API_URL + `homebanner_delete/${id}`,
            headers: { "Content-Type": "multipart/form-data" }
        })
        return deleteBannerRes = res.data

    } catch (error) {
        return deleteBannerRes = error.response.data
    }
})