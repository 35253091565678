import React from 'react'
import { Link } from 'react-router-dom'

export default function PublicNavbar() {
    return (
        <>
            <nav style={{ position: 'relative' }} className=''>
                <div className='container-fluid p-0' style={{ position: 'fixed', zIndex: "999", top: '0', backgroundColor: "white" }}>
                    <header className=' ' id='property-header'>
                        <div className="row  ">
                            <div className="col-md-12 mb-2">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className=" d-flex align-items-center">
                                        <div className='header-logo-section ' id='header-dekstop-logo'>
                                            <img src="../logo2.png" className='img-fluid header-logo ' alt="property-logo" />
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center navbar_option'>
                                        <Link to='../'> <button>Home</button></Link>
                                        <Link to='../shipping-and-delivery'>   <button>Shipping & delivery</button></Link>
                                        <Link to='../refund-and-return'>   <button>Refund & Return</button></Link>
                                        <Link to='../about-us'>  <button>About us</button></Link>
                                        <Link to='../contact-us'> <button>Contact us</button></Link>
                                        <Link to='../privacy-policy'>   <button>Privacy policy</button></Link>
                                        <Link to='../terms-and-conditions'>  <button>Term & condition</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
            </nav>
        </>
    )
}
