import React from 'react';
import AddUpdateDoctor from '../../component/Form/DoctorForm/AddUpdateDoctor';

const AddDoctor = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form_head">
                            <h4>Add Doctor</h4>
                        </div>
                        <AddUpdateDoctor />
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddDoctor;
