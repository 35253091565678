import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addUserRes;
export const  addUserTodo = createAsyncThunk(' addUser', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'User_register_web',
            data: data,
            headers: { "Content-Type": "multipart/form-data" }
        })
        return addUserRes = res.data

    } catch (error) {
        return addUserRes = error.response.data
    }
})

export var updateUserRes;
export const  updateUserTodo = createAsyncThunk(' updateUser', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + "update_user",
            data: data,
            headers: { "Content-Type": "multipart/form-data" }
        })
        return updateUserRes = res.data

    } catch (error) {
        return updateUserRes = error.response.data
    }
})