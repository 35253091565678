import React,{useState} from 'react'
import AddNotificationForm from '../../component/Form/NotificationForm/AddNotificationForm'
import NotificationList from './NotificationList'

export default function AddNotification() {
    const [bool, setBool] = useState(false)

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form_head">
                            <h4>Add Notification</h4>
                        </div>
                        <AddNotificationForm bool={bool} setBool={setBool}/>
                    </div>
                    <div className="col-md-12 mt-3">
                        <NotificationList bool={bool} setBool={setBool}/>
                    </div>
                </div>
            </div>
        </>
    )
}
