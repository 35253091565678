import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var updateVersionRes;
export const updateVersionTodo = createAsyncThunk(' updateVersion', async (data) => {
    try {
        const res = verionsUpdate(data.andriod_version,"android","1")
        const res2 = verionsUpdate(data.ios_version,"ios","2")

        return updateVersionRes = res

    } catch (error) {
        return updateVersionRes = error.response.data
    }
})

const verionsUpdate = async (updateversion,type,id) => {
    return await axios({
        method: "post",
        url: process.env.REACT_APP_API_URL + 'update_version',
        data: {
            "id": id,
            "type":type ,
            "version": updateversion
        },
        headers: { "Content-Type": "application/json" }
    })
}