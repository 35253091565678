import React, { useState } from 'react'
import { AddCourseSchema } from '../CourseForm/validation';
import TextInput from '../../../InputFiled/TextInput'
import StatusSelect from '../../../InputFiled/StatusSelect'
import AddButton from '../../../Button/AddButton'
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import {addDiseaseTodo, addDiseaseRes, updateDiseaseRes, updateDiseaseTodo } from '../../../../redux/slices/Master/Disease/addupdateDisease';
import { AddDiseaseSchema } from './validation';
import Loader from '../../../Loader/Loader';

export default function DiseaseForm(props) {

    const [loading, setLoading] = useState(false)
    const { bool, setBool } = props;
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams();
    const { state } = useLocation();
    var diseaseData = state;

    const initialValues = {
        disease: id ? diseaseData.name : "",
        status: id ? diseaseData.status : ""
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: AddDiseaseSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            {
                id ? dispatch(updateDiseaseTodo({ values, id })).then(() => updateDiseaseFun()) :
                    dispatch(addDiseaseTodo(values)).then(() => addDiseaseFun())
            }
            id ? <></> : action.resetForm()
        },
    })

    const addDiseaseFun = () => {
        if (addDiseaseRes && addDiseaseRes.success) {
          setLoading(false)
            toast.success(addDiseaseRes && addDiseaseRes.message, { position: "bottom-right" })
            setBool(!bool)
        } else {
          setLoading(false)
          toast.error(addDiseaseRes && addDiseaseRes.message, { position: "bottom-right" })
        }
    }

    const updateDiseaseFun = () => {
        if (updateDiseaseRes && updateDiseaseRes.success) {
            setLoading(false)
            toast.success(updateDiseaseRes && updateDiseaseRes.message, { position: "bottom-right" })
            setTimeout(() => {
                navigate('../disease')
            }, 1500);
        } else {
          setLoading(false)
          toast.error(updateDiseaseRes && updateDiseaseRes.message, { position: "bottom-right" })

        }
    }

    return (
        <>
            <>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='master_form_section'>
                            {loading ?
                                <div className='d-flex justify-content-center mt-5'>
                                    <Loader />
                                </div> :
                                <form onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className='col-md-4 mb-3'>
                                            <TextInput
                                                name='disease'
                                                label={'Disease Name'}
                                                value={values.disease}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.disease && touched.disease ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.disease}</span>) : null}

                                        </div>
                                        <div className='col-md-4 mb-3'>
                                            <StatusSelect
                                                name='status'
                                                label={'Status'}
                                                value={values.status}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.status && touched.status ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.status}</span>) : null}
                                        </div>
                                        <div className='col-md-12 '>

                                            <AddButton
                                                label={id ? 'Update':'Save'} />
                                        </div>
                                    </div>
                                </form>}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    )
}
