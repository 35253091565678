import React, { useState, useEffect } from 'react'
import Loader from '../../component/Loader/Loader'
import DataTables from '../../component/DataTable/DataTable'
import { useDispatch, useSelector } from 'react-redux'
import { RiComputerLine } from "react-icons/ri";
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { bookingListRes, bookingListTodo } from '../../redux/slices/BookingSlices/BookingList';
import moment from 'moment';

export default function DoctorBookingList() {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const { id } = useParams();
    const navigate = useNavigate()
    const { state } = useLocation();
    const bookingListData = useSelector(state => state && state.bookingList && state.bookingList.data && state.bookingList.data.data)

    useEffect(() => {
        setLoading(true)
        dispatch(bookingListTodo({ "doctorId": id })).then(() => bookingListFun())
    }, [])

    const bookingListFun = () => {
        if (bookingListRes && bookingListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }
    const booking_details = (params) => {
        navigate(`../booking_details/${params.data.id}`, { state: params && params.data })

    }


    const columnDefs = [
        {
            headerName: 'S.NO.',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 100,
        },
      
        {
            headerName: 'PATIENT',
            field: 'patient_detail.name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },

        {
            headerName: 'ID',
            field: 'booking_id',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: 'DATE',
            field: 'booking_date',
            cellRenderer: params => new Date(params.data.booking_date).toLocaleDateString('en-GB'),
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: 'TIME',
            field: 'booking_stime',
            filter: 'agTextColumnFilter',
            cellRenderer: params => moment(params.data.booking_stime, "HH:mm:ss").format('hh:mm A'),
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },

        {
            headerName: 'STATUS',
            field: 'booking_status',
            cellRenderer: params => params.value == 'Booked' ? <span style={{ color: "white" }} className='badge text-bg-success'>Booked</span> : params.value == 'Pending' ? <span className='badge text-bg-warning'>Pending</span> : <span className='badge text-bg-danger'>Cancle</span>,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
        },
        {
            field: 'ACTIONS',
            cellRenderer: params =>
                <div>
                    <RiComputerLine size={20} className='details ms-3'  onClick={() => booking_details(params)}/>
                </div>,
            wrapText: true,
            autoHeight: true,
        }
    ]

    return (
        <>
            <div className="container-fluid">
                <div className="row">

                    {
                        loading ?
                            <div className='d-flex justify-content-center mt-5 pt-5'>
                                <Loader />
                            </div>
                            :
                            bookingListData && bookingListData.length ?
                                <div className='mt-3'>
                                    <DataTables
                                        rowData={bookingListData}
                                        columnDefs={columnDefs}
                                    />
                                </div> :
                                <div className='d-flex empty_data_section justify-content-center mt-3'>
                                    <h4>Booking List Not Found</h4>
                                </div>}
                </div>
            </div>
        </>
    )
}
