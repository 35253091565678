import React, { useState } from 'react'
import TextInput from '../../../InputFiled/TextInput'
import StatusSelect from '../../../InputFiled/StatusSelect'
import AddButton from '../../../Button/AddButton'
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import { addReasonRes, addReasonTodo, updateReasonRes, updateReasonTodo } from '../../../../redux/slices/Master/Reason/AddUpdateReason';
import { updateDiseaseTodo } from '../../../../redux/slices/Master/Disease/addupdateDisease';
import { AddReasonSchema } from './validation';
import Loader from '../../../Loader/Loader';

export default function ReasonForm(props) {

    const { bool, setBool } = props;
const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams();
    const { state } = useLocation();
    
    const initialValues = {
        reason: id ? state.reason : "",
        userRole: id ? state.userRole : "",
        status: id ? state.status : ""
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: AddReasonSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            {
                id ? dispatch(updateReasonTodo({ values, id })).then(() => updateReasonFun()) :
                    dispatch(addReasonTodo(values)).then(() => addReasonFun())
            }
            id ? <></> : action.resetForm()
        },
    })

    const updateReasonFun = () => {
        setLoading(false)
        if (updateReasonRes && updateReasonRes.success) {
            toast.success(updateReasonRes && updateReasonRes.message, { position: "bottom-right" })
            setTimeout(() => {
                navigate('../reason')
            }, 1500);
        } else {
        setLoading(false)
        toast.error(updateReasonRes && updateReasonRes.message, { position: "bottom-right" })
        }
    }

    const addReasonFun = () => {
        if (addReasonRes && addReasonRes.success) {
        setLoading(false)
        toast.success(addReasonRes && addReasonRes.message, { position: "bottom-right" })
            setBool(!bool)
        } else {
        setLoading(false)
        toast.success(addReasonRes && addReasonRes.message, { position: "bottom-right" })

        }
    }

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='master_form_section'>
                        {loading ?
                                <div className='d-flex justify-content-center mt-5'>
                                    <Loader />
                                </div> :
                            <form onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-md-4 mb-3'>
                                        <TextInput
                                            name='reason'
                                            label={'Reason'}
                                            value={values.reason}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.reason && touched.reason ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.reason}</span>) : null}

                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <label className="form-label label_head"> User Role</label>
                                        <select className="form-select input_filed"
                                            name="userRole"
                                            value={values.userRole}
                                            onChange={handleChange}
                                            onBlur={handleChange}>
                                            <option value=''>Select</option>
                                            <option value="user">User</option>
                                            <option value="doctor">Doctor</option>
                                        </select>
                                        {errors.userRole && touched.userRole ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.userRole}</span>) : null}

                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <StatusSelect
                                            name='status'
                                            label={'Status'}
                                            value={values.status}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.status && touched.status ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.status}</span>) : null}
                                    </div>
                                    <div className='col-md-12 '>

                                        <AddButton
                                            label={id ? "Update":'Save'} />
                                    </div>
                                </div>
                            </form>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
