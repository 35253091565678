import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var bookingDetailsRes;
export const bookingDetailsTodo = createAsyncThunk('bookingDetails', async (id) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + `booking_byid/${id}`,
          
            headers: { "Content-Type": "application/json" }
        })
        return bookingDetailsRes = res.data

    } catch (error) {
        return bookingDetailsRes = error.response.data
    }
})

const bookingDetailsSlice = createSlice({
    name: 'bookingDetails',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(bookingDetailsTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('equipmentList', state.data.response)
        });
        builder.addCase(bookingDetailsTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default bookingDetailsSlice.reducer; 