import './App.css';
import '../src/Styles/login.css'
import '../src/Styles/sidebar.css'
import '../src/Styles/navbar.css'
import '../src/Styles/Form.css'
import "../src/Styles/buttons.css"
import "../src/Styles/DataTable.css"
import '../src/Styles/dashboard.css'
import '../src/Styles/userdetails.css'
import '../src/Styles/privacy_terms.css'
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.css';
import './fonts/tt0246m.ttf'
import './fonts/tt0247m.ttf'
import './fonts/tt0248m.ttf'
import './fonts/tt0249m.ttf'
import { Navigate, Route, Routes } from 'react-router-dom';
import AdminLogin from './component/AdminLogin/AdminLogin';
import ProtectedRoutes from './ProtectedRoute';
import AddDoctor from './Pages/DoctoreModule/AddDoctor';
import DoctorList from './Pages/DoctoreModule/DoctorList';
import AddCategory from './Pages/Masters/Category/AddCategory';
import UpdateCategory from './Pages/Masters/Category/UpdateCategory';
import AddSpeciality from './Pages/Masters/Speciality/AddSpeciality';
import UpdateSpeciality from './Pages/Masters/Speciality/UpdateSpeciality';
import AddCourse from './Pages/Masters/Course/AddCourse';
import UpdateCourse from './Pages/Masters/Course/UpdateCourse';
import AddDisease from './Pages/Masters/Disease/AddDisease';
import UpdateDisease from './Pages/Masters/Disease/UpdateDisease';
import AddBanner from './Pages/Masters/HomeBanner/AddBanner';
import UpdateBanner from './Pages/Masters/HomeBanner/UpdateBanner';
import UpdateDoctor from './Pages/DoctoreModule/UpdateDoctor';
import BookingList from './Pages/BookingModule/BookingList';
import PatientList from './Pages/PatientModule/PatientList';
import AddBooking from './Pages/BookingModule/AddBooking';
import Dashboard from './Pages/Dashboard/Dashboard';
import AddUser from './Pages/UserModule/AddUser';
import UserList from './Pages/UserModule/UserList';
import UpdateUser from './Pages/UserModule/UpdateUser';
import UpdateBooking from './Pages/BookingModule/UpdateBooking';
import UpdateProfile from './Pages/DoctoreModule/UpdateProfile';
// import PaymentList from './Pages/PaymentModule/PaymentList';
import UserDetails from './Pages/UserModule/UserDetails';
import DocterDetails from './Pages/DoctoreModule/DocterDetails';
import AddReason from './Pages/Masters/Reason/AddReason';
import UpdateReason from './Pages/Masters/Reason/UpdateReason';
import BookingDetails from './Pages/BookingModule/BookingDetails';
import UpdateSubscription from './Pages/Masters/Subscription/UpdateSubscription';
import ForgotPsw from './component/AdminLogin/ForgotPswPage/ForgotPsw';
import OTPResetPage from './component/AdminLogin/ForgotPswPage/OTPResetPage';
import ChangePswPage from './component/AdminLogin/ForgotPswPage/ChangePswPage';
import AddNotification from './Pages/Notification/AddNotification';
import NotificationView from './Pages/Notification/NotificationView';
import PrivacyPolicy from './Pages/Policy&Term/PrivacyPolicy';
import TermCondition from './Pages/Policy&Term/TermCondition';
import AboutsUs from './Pages/Policy&Term/AboutsUs';
import ContactUs from './Pages/Policy&Term/ContactUs';
import ShippingDelivery from './Pages/Policy&Term/ShippingDelivery';
import RefunAndReturn from './Pages/Policy&Term/RefunAndReturn';
import UpdateVersion from './Pages/UpdateVersion/UpdateVersion';

import HomePage from './Pages/Home/HomePage';
import TermConditionMobile from './Pages/Policy&Term/TermConditionMobile';




function App() {
  return (
    <div className="App">


      <Routes >
        <Route path='/' element={<HomePage />} />
        <Route path='/login' element={<AdminLogin />} />
        <Route path='/forgot-password' element={<ForgotPsw />} />
        <Route path='/otp' element={<OTPResetPage />} />
        <Route path='/change-psw' element={<ChangePswPage />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-and-conditions' element={<TermCondition />} />
        <Route path='/terms-and-conditions-mobile' element={<TermConditionMobile/>} />
        <Route path='/about-us' element={<AboutsUs />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/shipping-and-delivery' element={<ShippingDelivery />} />
        <Route path='/refund-and-return' element={<RefunAndReturn />} />

        <Route element={<ProtectedRoutes allowedRoles={['doctor', 'admin']} />}  >
          {/* <Route path='/' element={<Navigate replace to='/dashboard' />} /> */}
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/bookinglist' element={<BookingList />} />
          <Route path='/booking_details/:id' element={<BookingDetails />} />
          <Route path='/patientlist' element={<PatientList />} />
          <Route path='/notification' element={<AddNotification />} />
          <Route path='/notification-details/:id' element={<NotificationView />} />
          <Route path='/update-version' element={<UpdateVersion />} />

        </Route>

        <Route element={<ProtectedRoutes allowedRoles={['admin']} />}  >

          <Route path='/category' element={<AddCategory />} />
          <Route path='/update_category/:id' element={<UpdateCategory />} />

          <Route path='/speciality' element={<AddSpeciality />} />
          <Route path='/update_speciality/:id' element={<UpdateSpeciality />} />

          <Route path='/qualification' element={<AddCourse />} />
          <Route path='/update_qualification/:id' element={<UpdateCourse />} />

          <Route path='/disease' element={<AddDisease />} />
          <Route path='/update_disease/:id' element={<UpdateDisease />} />

          <Route path='/reason' element={<AddReason />} />
          <Route path='/update_reason/:id' element={<UpdateReason />} />

          <Route path='/homebanner' element={<AddBanner />} />
          <Route path='/update_banner/:id' element={<UpdateBanner />} />

          {/* <Route path='/subscription' element={<AddSubscription/>} /> */}
          <Route path='/update_subscription' element={<UpdateSubscription />} />

          <Route path='/add_doctor' element={<AddDoctor />} />
          <Route path='/update_doctor/:id' element={<UpdateDoctor />} />
          <Route path='/doctorList' element={<DoctorList />} />
          <Route path='/doctor_details/:id' element={<DocterDetails />} />

          <Route path='/adduser' element={<AddUser />} />
          <Route path='/updateuser/:id' element={<UpdateUser />} />
          <Route path='/userdetails/:id' element={<UserDetails />} />
          <Route path='/userList' element={<UserList />} />

          <Route path='/addbooking' element={<AddBooking />} />
          <Route path='/updatebooking/:id' element={<UpdateBooking />} />

          {/* <Route path='/paymentlist' element={<PaymentList/>} /> */}


        </Route>

        <Route element={<ProtectedRoutes allowedRoles={['doctor']} />}  >
          <Route path='/update_profile' element={<UpdateProfile />} />

        </Route>


      </Routes>

    </div>
  );
}

export default App;



