import React, { useState, useEffect } from 'react';
import DataTables from '../../component/DataTable/DataTable';
import Loader from '../../component/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux'
import { FiEdit } from "react-icons/fi";
import { ToastContainer, toast } from 'react-toastify';
import { RiDeleteBin6Line } from "react-icons/ri";
import { RiComputerLine } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { userListRes, userListTodo } from '../../redux/slices/UserSlices/userList';

const UserList = () => {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const userListData = useSelector(state => state && state.userList && state.userList.data && state.userList.data.data)

    useEffect(() => {
        setLoading(true)
        dispatch(userListTodo()).then(() => userListFun())
    }, [])

    const userListFun = () => {
        if (userListRes && userListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const edit_user = (params) => {
        navigate(`../updateuser/${params.data.id}`, { state: params && params.data })
    }

    const user_details = (params) => {
        navigate(`../userdetails/${params.data.id}`, { state: params && params.data })
    }

    const columnDefs = [
        {
            headerName: 'S.NO.',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 100,
        },
        {
            headerName: ' NAME',
            field: 'username',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: 'MOBILE NO.',
            field: 'mobile',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 160,

        },

        {
            headerName: 'EMAIL',
            field: 'email',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: 'AGE',
            field: 'age',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 90,
        },
        {
            headerName: 'GENDER',
            field: 'gender',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 120,
        },
        {
            headerName: 'STATUS',
            field: 'status',
            cellRenderer: params => params.value == 'active' ? <span className='badge text-bg-success'>{params.value}</span> : params.value == 'inactive' ? <span className='badge text-bg-danger'>{params.value}</span> : <span className='badge text-bg-danger'>{params.value}</span>,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            maxWidth: 120,
            // wrapText: true,
            // autoHeight: true
        },
        {
            field: 'ACTIONS',
            cellRenderer: params =>
                <div>
                    <FiEdit size={20} className='edit' style={{ color: '#47BB4C', cursor: "pointer" }} onClick={() => edit_user(params)} />
                    <RiComputerLine size={20} className='details ms-3' onClick={() => user_details(params)} style={{ color: 'rgb(27, 27, 27)', cursor: "pointer" }} />
                </div>,
            wrapText: true,
            autoHeight: true,
            maxWidth: 130,

        }
    ]

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form_head">
                            <h4> User List</h4>
                        </div>
                    </div>
                    {
                        loading ?
                            <div className='d-flex justify-content-center mt-5 pt-5'>
                                <Loader />
                            </div>
                            :
                            userListData && userListData.length ?
                                <div className='mt-3'>
                                    <DataTables
                                        rowData={userListData}
                                        columnDefs={columnDefs}
                                    />
                                </div> :
                                <div className='d-flex empty_data_section justify-content-center mt-3'>
                                    <h4>User List Not Found</h4>
                                </div>}
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default UserList;
