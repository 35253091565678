import React from 'react'
import UpdateVersionForm from '../../component/Form/UpdateVersion/UpdateVersion'

export default function UpdateVersion() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form_head">
                            <h4>Update Version</h4>
                            <UpdateVersionForm />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
