import React from 'react'
import CategoryForm from '../../../component/Form/MasterForm/CategoryForm/CategoryForm';

export default function UpdateCategory() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form_head">
                            <h4>Update Category</h4>
                        </div>
                       <CategoryForm/>
                    </div>
                </div>
            </div>
        </>
    )
}
