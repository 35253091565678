import React from 'react';

const FileInput = (props) => {
    return (
        <>
             <label className="form-label label_head">{props.label}</label>
            <input type="file"
                className='form-control input_filed '
                // id={props.mid ? props.mid : ''}
                name={props.name}
                onChange={props.onChange}
                onBlur={props.onBlur}
            />
        </>
    );
}

export default FileInput;
