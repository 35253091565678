import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var dashboardRes;
export const dashboardTodo = createAsyncThunk('dashboard', async (id) => {
    // console.log("ExerciseId", id)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'dashboard',
            headers: { "Content-Type": "application/json" }
        })
        return dashboardRes = res.data

    } catch (error) {
        return dashboardRes = error.response.data
    }
})

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(dashboardTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('equipmentList', state.data.response)
        });
        builder.addCase(dashboardTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default dashboardSlice.reducer; 