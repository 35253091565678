import React from 'react';

const RadioInput = (props) => {
    return (
        <>
               <label className='form-label label_head' > {props.label}</label>
            <div >
                <div className="form-check form-check-inline">
                    <input className="form-check-input form-input-check" type="radio"
                        name={props.name}
                        value='Male'
                        onChange={props.onChange}
                        defaultChecked={props.checkedValue == "Male" ? true : false}
                        onBlur={props.onBlur}
                        id={props.id}
                    />
                    <label className="form-check-label label_head">
                        Male
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input form-input-check" type="radio"
                        name={props.name}
                        value='Female'
                        onChange={props.onChange}
                        defaultChecked={props.checkedValue == "Female" ? true : false}
                        onBlur={props.onBlur}
                        id={props.id} />
                    <label className="form-check-label label_head">
                        Female
                    </label>
                </div>
            </div>

        </>
    );
}

export default RadioInput;
