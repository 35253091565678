import React from 'react';
import Button from 'react-bootstrap/Button';

const AddButton = (props) => {
    return (
        <>
               <Button variant="secondary" type='submit' className='create_btn'>{props.label}</Button>
        </>
    );
}

export default AddButton;
