import React, { useEffect, useState } from 'react';
import 'animate.css';
import WOW from 'wowjs';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../../index.css'
import { BiSolidUserDetail, BiPlanet, BiSelectMultiple, BiHeart, BiRupee, BiCalendarCheck, BiCheckShield, BiLogoPlayStore, BiLogoApple, BiPhone, BiEnvelope } from "react-icons/bi";
import { CiPercent } from "react-icons/ci";
import { Link } from 'react-router-dom'

const HomePage = () => {
    const [bool, setBool] = useState(false)


    // window.onscroll = function () { myFunction() };

    // function myFunction() {
    //     console.log("HI", document.body.scrollTop)
    //     if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
    //         document.getElementById("nv").className = "navbar-nav mx-auto py-0 sticky-top shadow-sm";
    //     } else {
    //         document.getElementById("nv").className = "navbar-nav mx-auto py-0";
    //     }
    // }

    const onScroll = () => {
        if (window.scrollY > 250) {
            document.getElementById("nv").className = "navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0 sticky-top shadow-sm";
        } else {
            document.getElementById("nv").className = "navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0";
        }
    }
    useEffect(() => {
        new WOW.WOW().init();
        window.addEventListener('scroll', onScroll, { passive: true });
    }, [])

    return (
        <>
            <div style={{backgroundColor:" #F0F6FF"}}>
                <div className="container-xxl bg-white p-0">
                    {/* <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
                    <div className="spinner-grow text-primary" style={{width: '3rem', height: '3rem'}} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div> */}

                    <div className="container-xxl position-relative p-0" id="home">
                        <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0" id="nv">
                            <a href="" className="navbar-brand p-0">
                                <img src="./logo2.png" alt="Logo" />
                            </a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                                <span className="fa fa-bars"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarCollapse">
                                <div className="navbar-nav mx-auto py-0">
                                    <a href="#home" className="nav-item nav-link active">Home</a>
                                    <a href="#about" className="nav-item nav-link">About</a>
                                    <a href="#benefits" className="nav-item nav-link">Benefits</a>
                                    <a href="#feature" className="nav-item nav-link">Feature</a>
                                    {/* <a href="#review" className="nav-item nav-link">Review</a> */}
                                    <a href="#contact" className="nav-item nav-link">Contact</a>
                                </div>
                                <a href="/login" target='#' className="btn btn-primary-gradient rounded-pill py-2 px-4 ms-3 d-none d-lg-block">Doctor Login</a>
                            </div>
                        </nav>

                        <div className="container-xxl bg-primary hero-header">
                            <div className="container px-lg-5">
                                <div className="row g-5">
                                    <div className="col-lg-8 text-center text-lg-start">
                                        <h1 className="text-white mb-4 animated slideInDown">The Revolutionary app that helps to find nearest affordable doctors </h1>
                                        <p className="text-white pb-3 animated slideInDown">Welcome to Doctors on Discount (DOD) your affordable healthcare companion, where  your healthcare journey  is not just about convenience, but about choice and savings too!</p>
                                        <a href="#download" class="btn btn-secondary-gradient py-sm-3 px-4 px-sm-5 rounded-pill animated slideInRight">Download App</a>
                                    </div>
                                    <div className="col-lg-4 d-flex justify-content-center justify-content-lg-end wow fadeInUp" data-wow-delay="0.3s">
                                        {/* <div className="owl-carousel screenshot-carousel"> */}
                                        <OwlCarousel className='owl-carousel screenshot-carousel' autoplay={true} dots={true} items={1} loop>
                                            <img className="img-fluid" src="img/Screenshot_20240501_170023.jpg" alt="" />
                                            <img className="img-fluid" src="img/Screenshot_20240501_170028.jpg" alt="" />
                                            <img className="img-fluid" src="img/Screenshot_20240501_170031.jpg" alt="" />
                                            <img className="img-fluid" src="img/Screenshot_20240501_170012.jpg" alt="" />
                                            {/* <img className="img-fluid" src="img/screenshot-5.png" alt="" /> */}
                                        </OwlCarousel>
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="container-xxl py-5" id="about">
                        <div className="container py-5 px-lg-5">
                            <div className="row g-5 align-items-center">
                                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                                    <h5 className="text-primary-gradient fw-medium">About App</h5>
                                    <h1 className="mb-4">#1 App For Healthcare</h1>
                                    <p className="mb-4"> At Doctor on Discount, we believe that quality healthcare should be accessible to everyone without breaking the bank. That's why we've developed a revolutionary mobile application available on both the Play Store and the App Store, bringing affordable medical consultations right to your fingertips.</p>
                                    <p><strong>Picture this:</strong> You're in need of medical advice, but the hassle of finding the right doctor at the right price seems daunting. Fear not, because DOD is here to revolutionize the way you approach healthcare. With our intuitive app, you're not just browsing through a list of doctors – you're handpicking the perfect match based on their specialty, qualifications, fees, and location. And here's the cherry on top: for a mere Rs.100/- registration fee for an entire year, you unlock a world of healthcare savings! That's right – with DOD, you're not just a patient, you're a savvy healthcare consumer. So why wait? Join us today and enjoy a whopping 50% discount on consultations, because your health and your wallet deserve the best!</p>
                                    {/* <div className="row g-4 mb-4">
                                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.5s">
                                        <div className="d-flex">
                                            <i className="fa fa-cogs fa-2x text-primary-gradient flex-shrink-0 mt-1"></i>
                                            <div className="ms-3">
                                                <h2 className="mb-0" data-toggle="counter-up">1234</h2>
                                                <p className="text-primary-gradient mb-0">Active Install</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.7s">
                                        <div className="d-flex">
                                            <i className="fa fa-comments fa-2x text-secondary-gradient flex-shrink-0 mt-1"></i>
                                            <div className="ms-3">
                                                <h2 className="mb-0" data-toggle="counter-up">1234</h2>
                                                <p className="text-secondary-gradient mb-0">Clients Reviews</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                </div>
                                <div className="col-lg-6">
                                    <img className="img-fluid wow fadeInUp" data-wow-delay="0.5s" src="img/about.png" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-xxl py-5" id="benefits">
                        <div className="container py-5 px-lg-5">
                            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                                <h5 className="text-primary-gradient fw-medium">Cut Costs, Not Quality: Discover the benefits of DOD!</h5>
                                <h1 className="mb-5">Awesome Benefits</h1>
                            </div>
                            <div className="row g-4">
                                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                    <div className="feature-item bg-light rounded p-4">
                                        <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{ width: '60px', height: '60px' }}>
                                            <BiSolidUserDetail className='iconv' />
                                        </div>
                                        <h5 className="mb-3">Expertly Vetted Doctors</h5>
                                        <p className="m-0">Our team hunts down the best doctors, so you don't have to. Say goodbye to uncertainty – with DOD, you're in safe hands.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                    <div className="feature-item bg-light rounded p-4">
                                        <div className="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4" style={{ width: '60px', height: '60px' }}>
                                            <BiPlanet className='iconv' />
                                        </div>
                                        <h5 className="mb-3">All-in-One Convenience</h5>
                                        <p className="m-0">From General Practitioners to Specialists, find every medical domain on a single platform. No more bouncing between websites – everything's here.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div className="feature-item bg-light rounded p-4">
                                        <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{ width: '60px', height: '60px' }}>
                                            <BiSelectMultiple className='iconv' />
                                        </div>
                                        <h5 className="mb-3">Fair and Square: </h5>
                                        <p className="m-0">We're all about transparency. DOD shows you options without bias, letting you choose based on what matters most to you.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                    <div className="feature-item bg-light rounded p-4">
                                        <div className="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4" style={{ width: '60px', height: '60px' }}>
                                            <BiHeart className='iconv' />
                                        </div>
                                        <h5 className="mb-3">Healthcare Anywhere</h5>
                                        <p className="m-0">Perfect for students, travellers, or anyone away from home. Wherever you are, quality healthcare is just a click away with DOD.</p>
                                    </div>
                                </div>
                                {/* <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="feature-item bg-light rounded p-4">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{ width: '60px', height: '60px' }}>
                                        <i className="fa fa-cloud text-white fs-4"></i>
                                    </div>
                                    <h5 className="mb-3">Cloud Storage</h5>
                                    <p className="m-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="feature-item bg-light rounded p-4">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4" style={{ width: '60px', height: '60px' }}>
                                        <i className="fa fa-mobile-alt text-white fs-4"></i>
                                    </div>
                                    <h5 className="mb-3">Fully Responsive</h5>
                                    <p className="m-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.</p>
                                </div>
                            </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="container-xxl py-5" id="howto">
                        <div className="container py-5 px-lg-5">
                            <div className="row g-5 align-items-center">
                                <div className="col-lg-8 wow fadeInUp" data-wow-delay="0.1s">
                                    <h5 className="text-primary-gradient fw-medium">Easy to use</h5>
                                    <h1 className="mb-4">User Friendly interface</h1>
                                    <p className="mb-4"><strong>Riya's Dental Dilemma: A Hostelier's Journey with Doctors on Discount</strong></p>
                                    <p>In the heart of Indore city, amidst the bustling streets and lively atmosphere, resided Riya, a diligent student living in a busy hostel. One morning, Riya woke up with a toothache, feeling worried about where to find a good dentist. Then, she remembered hearing about Doctors on Discount (DOD) from a friend.</p>
                                    <p>Riya quickly downloaded the app and found it super easy to use. She looked for dentists who could help with her tooth trouble and found Dr. Priyank Banthia. Dr. Banthia's fees were affordable, so Riya booked an appointment right away.</p>
                                    <p>The best part was that Riya only had to pay Rs.100/- to register for the whole year on the app! Plus, she got a big 50% discount on the consultation fee.</p>
                                    <p>When Riya went to see Dr. Banthia, she was relieved to find the dentist friendly and helpful. Dr. Banthia fixed Riya's toothache, and Riya left feeling much better.</p>
                                    <p>Riya was grateful she found DOD. Not only did she find a great dentist, but she also saved a lot of money on the appointment. Now, Riya knows she can trust DOD to help her with her healthcare needs whenever she needs it.</p>
                                </div>
                                <div className="col-lg-4 d-flex justify-content-center justify-content-lg-end wow fadeInUp" data-wow-delay="0.3s">
                                    {/* <div className="owl-carousel screenshot-carousel"> */}
                                    <OwlCarousel className='owl-carousel screenshot-carousel' autoplay={true} dots={true} items={1} loop>
                                        <img className="img-fluid" src="img/Screenshot_20240501_170012.jpg" alt="" />
                                        <img className="img-fluid" src="img/Screenshot_20240501_160108.jpg" alt="" />
                                        <img className="img-fluid" src="img/Screenshot_20240501_160124.jpg" alt="" />
                                        <img className="img-fluid" src="img/Screenshot_20240501_160132.jpg" alt="" />
                                        <img className="img-fluid" src="img/Screenshot_20240501_160011.jpg" alt="" />
                                        <img className="img-fluid" src="img/Screenshot_20240501_155950.jpg" alt="" />
                                    </OwlCarousel>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-xxl py-5" id="feature">
                        <div className="container py-5 px-lg-5">
                            <div className="text-center pb-4 wow fadeInUp" data-wow-delay="0.1s">
                                <h5 className="text-primary-gradient fw-medium">Easy Steps</h5>
                                <h1 className="mb-5">How It Works</h1>
                            </div>
                            <div className="row gy-5 gx-4 justify-content-center">
                                <div className="col-lg-6 col-sm-6 text-center pt-4 wow fadeInUp" data-wow-delay="0.1s">
                                    <div className="position-relative bg-light rounded pt-5 pb-4 px-4">
                                        <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle position-absolute top-0 start-50 translate-middle shadow" style={{ width: '100px', height: '100px' }}>
                                            <BiRupee className='text-white fs-1' />
                                        </div>
                                        <h5 className="mt-4 mb-3">Affordable Subscription</h5>
                                        <p className="mb-0">Sign up for our yearly subscription at an incredibly low price of Rs 100+GST. With this nominal fee, you gain access to exclusive discounts on medical consultations throughout the year.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 text-center pt-4 wow fadeInUp" data-wow-delay="0.3s">
                                    <div className="position-relative bg-light rounded pt-5 pb-4 px-4">
                                        <div className="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle position-absolute top-0 start-50 translate-middle shadow" style={{ width: '100px', height: '100px' }}>
                                            <CiPercent className='text-white fs-1' />
                                        </div>
                                        <h5 className="mt-4 mb-3">50% Discount on Consultations</h5>
                                        <p className="mb-0">Once you're registered, you can browse through our list of qualified doctors and specialists. Whether you prefer a virtual consultation or an in-person visit, you'll enjoy a generous 50% discount on all medical consultations.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6 text-center pt-4 wow fadeInUp" data-wow-delay="0.5s">
                                    <div className="position-relative bg-light rounded pt-5 pb-4 px-4">
                                        <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle position-absolute top-0 start-50 translate-middle shadow" style={{ width: '100px', height: '100px' }}>
                                            <BiCalendarCheck className='text-white fs-1' />
                                        </div>
                                        <h5 className="mt-4 mb-3">Easy Booking</h5>
                                        <p className="mb-0">Booking an appointment is quick and hassle-free. Simply select your preferred doctor, choose a convenient time slot, and confirm your appointment – all within the app.</p>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-sm-6 text-center pt-4 wow fadeInUp" data-wow-delay="0.5s">
                                    <div className="position-relative bg-light rounded pt-5 pb-4 px-4">
                                        <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle position-absolute top-0 start-50 translate-middle shadow" style={{ width: '100px', height: '100px' }}>
                                            <BiCheckShield className='text-white fs-1' />
                                        </div>
                                        <h5 className="mt-4 mb-3">Secure and Confidential</h5>
                                        <p className="mb-0">Your privacy is our top priority. Rest assured that all your personal and medical information is kept confidential and secure on our platform.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-xxl py-5" id='download'>
                        <div className="container py-5 px-lg-5">
                            <div className="row g-5 align-items-center">
                                <div className="col-lg-6">
                                    <img className="img-fluid wow fadeInUp" data-wow-delay="0.1s" src="img/about.png" />
                                </div>
                                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                                    <h5 className="text-primary-gradient fw-medium">Download</h5>
                                    <h1 className="mb-4">Download The Latest Version Of Our App</h1>
                                    <p className="mb-4">Download the Doctor on Discount app now and experience affordable healthcare like never before!</p>
                                    <div className="row g-4">
                                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.5s">
                                        <a href="https://apps.apple.com/in/app/doctor-on-discount/id6477575166" target='_blank' className="d-flex bg-primary-gradient rounded py-3 px-4">
                                            <BiLogoApple className='text-white fs-1' />
                                            <div className="ms-3">
                                                <p className="text-white mb-0">Available On</p>
                                                <h5 className="text-white mb-0">App Store</h5>
                                            </div>
                                        </a>
                                    </div>
                                        <div className="col-sm-6 wow fadeIn" data-wow-delay="0.7s">
                                            <a href="https://play.google.com/store/apps/details?id=com.tl.doctor_on_discount" target='_blank' className="d-flex bg-secondary-gradient rounded py-3 px-4">
                                                <BiLogoPlayStore className='text-white fs-1' />
                                                <div className="ms-3">
                                                    <p className="text-white mb-0">Available On</p>
                                                    <h5 className="text-white mb-0">Play Store</h5>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="bsb-cta-1 px-2 bsb-overlay bg-img-hero">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-10 offset-md-1 text-center">
                                    <h2 className="mb-3 text-uppercase h1 text-danger font-weight-light">Join the Community</h2>
                                    <p className="text-white lead">We also welcome doctors to join our platform at absolutely no cost. If you're a healthcare professional looking to expand your reach and help more patients, we invite you to register with us and become part of our growing network.</p>
                                    <p className="text-white lead">At Doctor on Discount, we're committed to revolutionizing healthcare accessibility by making quality medical care affordable for all. Join us today and take control of your health journey without breaking the bank.</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <div className="container-xxl py-5" id="pricing">
                        <div className="container py-5 px-lg-5">
                            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                                <h5 className="text-primary-gradient fw-medium">Pricing Plan</h5>
                                <h1 className="mb-5">Choose Your Plan</h1>
                            </div>
                            <div className="tab-class text-center pricing wow fadeInUp" data-wow-delay="0.1s">
                                <ul className="nav nav-pills d-inline-flex justify-content-center bg-primary-gradient rounded-pill mb-5">
                                    <li className="nav-item">
                                        <button className="nav-link active" data-bs-toggle="pill" href="#tab-1">Monthly</button>
                                    </li>
                                    <li className="nav-item">
                                        <button className="nav-link" data-bs-toggle="pill" href="#tab-2">Yearly</button>
                                    </li>
                                </ul>
                                <div className="tab-content text-start">
                                    <div id="tab-1" className="tab-pane fade show p-0 active">
                                        <div className="row g-4">
                                            <div className="col-lg-4">
                                                <div className="bg-light rounded">
                                                    <div className="border-bottom p-4 mb-4">
                                                        <h4 className="text-primary-gradient mb-1">Starter Plan</h4>
                                                        <span>Powerful & Awesome Features</span>
                                                    </div>
                                                    <div className="p-4 pt-0">
                                                        <h1 className="mb-3">
                                                            <small className="align-top" style={{ fontSize: '16px', lineHeight: '40px' }}>$</small>14.99<small
                                                                className="align-bottom" style={{ fontSize: '16px', lineHeight: '40px' }}>/ Month</small>
                                                        </h1>
                                                        <div className="d-flex justify-content-between mb-3"><span>HTML5 & CSS3</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                        <div className="d-flex justify-content-between mb-3"><span>Bootstrap v5</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                        <div className="d-flex justify-content-between mb-3"><span>Responsive Layout</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                        <div className="d-flex justify-content-between mb-2"><span>Cross-browser Support</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                        <a href="" className="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4">Get Started</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="bg-light rounded border">
                                                    <div className="border-bottom p-4 mb-4">
                                                        <h4 className="text-primary-gradient mb-1">Advance Plan</h4>
                                                        <span>Powerful & Awesome Features</span>
                                                    </div>
                                                    <div className="p-4 pt-0">
                                                        <h1 className="mb-3">
                                                            <small className="align-top" style={{ fontSize: '16px', lineHeight: '40px' }}>$</small>24.99<small
                                                                className="align-bottom" style={{ fontSize: '16px', lineHeight: '40px' }}>/ Month</small>
                                                        </h1>
                                                        <div className="d-flex justify-content-between mb-3"><span>HTML5 & CSS3</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                        <div className="d-flex justify-content-between mb-3"><span>Bootstrap v5</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                        <div className="d-flex justify-content-between mb-3"><span>Responsive Layout</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                        <div className="d-flex justify-content-between mb-2"><span>Cross-browser Support</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                        <a href="" className="btn btn-secondary-gradient rounded-pill py-2 px-4 mt-4">Get Started</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="bg-light rounded">
                                                    <div className="border-bottom p-4 mb-4">
                                                        <h4 className="text-primary-gradient mb-1">Premium Plan</h4>
                                                        <span>Powerful & Awesome Features</span>
                                                    </div>
                                                    <div className="p-4 pt-0">
                                                        <h1 className="mb-3">
                                                            <small className="align-top" style={{ fontSize: '16px', lineHeight: '40px' }}>$</small>34.99<small
                                                                className="align-bottom" style={{ fontSize: '16px', lineHeight: '40px' }}>/ Month</small>
                                                        </h1>
                                                        <div className="d-flex justify-content-between mb-3"><span>HTML5 & CSS3</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                        <div className="d-flex justify-content-between mb-3"><span>Bootstrap v5</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                        <div className="d-flex justify-content-between mb-3"><span>Responsive Layout</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                        <div className="d-flex justify-content-between mb-2"><span>Cross-browser Support</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                        <a href="" className="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4">Get Started</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="tab-2" className="tab-pane fade p-0">
                                        <div className="row g-4">
                                            <div className="col-lg-4">
                                                <div className="bg-light rounded">
                                                    <div className="border-bottom p-4 mb-4">
                                                        <h4 className="text-primary-gradient mb-1">Starter Plan</h4>
                                                        <span>Powerful & Awesome Features</span>
                                                    </div>
                                                    <div className="p-4 pt-0">
                                                        <h1 className="mb-3">
                                                            <small className="align-top" style={{ fontSize: '16px', lineHeight: '40px' }}>$</small>114.99<small
                                                                className="align-bottom" style={{ fontSize: '16px', lineHeight: '40px' }}>/ Yearly</small>
                                                        </h1>
                                                        <div className="d-flex justify-content-between mb-3"><span>HTML5 & CSS3</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                        <div className="d-flex justify-content-between mb-3"><span>Bootstrap v5</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                        <div className="d-flex justify-content-between mb-3"><span>Responsive Layout</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                        <div className="d-flex justify-content-between mb-2"><span>Cross-browser Support</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                        <a href="" className="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4">Get Started</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="bg-light rounded border">
                                                    <div className="border-bottom p-4 mb-4">
                                                        <h4 className="text-primary-gradient mb-1">Advance Plan</h4>
                                                        <span>Powerful & Awesome Features</span>
                                                    </div>
                                                    <div className="p-4 pt-0">
                                                        <h1 className="mb-3">
                                                            <small className="align-top" style={{ fontSize: '16px', lineHeight: '40px' }}>$</small>124.99<small
                                                                className="align-bottom" style={{ fontSize: '16px', lineHeight: '40px' }}>/ Yearly</small>
                                                        </h1>
                                                        <div className="d-flex justify-content-between mb-3"><span>HTML5 & CSS3</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                        <div className="d-flex justify-content-between mb-3"><span>Bootstrap v5</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                        <div className="d-flex justify-content-between mb-3"><span>Responsive Layout</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                        <div className="d-flex justify-content-between mb-2"><span>Cross-browser Support</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                        <a href="" className="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4">Get Started</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="bg-light rounded">
                                                    <div className="border-bottom p-4 mb-4">
                                                        <h4 className="text-primary-gradient mb-1">Premium Plan</h4>
                                                        <span>Powerful & Awesome Features</span>
                                                    </div>
                                                    <div className="p-4 pt-0">
                                                        <h1 className="mb-3">
                                                            <small className="align-top" style={{ fontSize: '16px', lineHeight: '40px' }}>$</small>134.99<small
                                                                className="align-bottom" style={{ fontSize: '16px', lineHeight: '40px' }}>/ Yearly</small>
                                                        </h1>
                                                        <div className="d-flex justify-content-between mb-3"><span>HTML5 & CSS3</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                        <div className="d-flex justify-content-between mb-3"><span>Bootstrap v5</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                        <div className="d-flex justify-content-between mb-3"><span>Responsive Layout</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                        <div className="d-flex justify-content-between mb-2"><span>Cross-browser Support</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                        <a href="" className="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4">Get Started</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="container-xxl py-5" id="review">
                        <div className="container py-5 px-lg-5">
                            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                                <h5 className="text-primary-gradient fw-medium">Testimonial</h5>
                                <h1 className="mb-5">What Say Our Clients!</h1>
                            </div>
                            <div className="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.1s">
                                <div className="testimonial-item rounded p-4">
                                    <div className="d-flex align-items-center mb-4">
                                        <img className="img-fluid bg-white rounded flex-shrink-0 p-1" src="img/testimonial-1.jpg" style={{ width: '85px', height: '85px' }} />
                                        <div className="ms-4">
                                            <h5 className="mb-1">Client Name</h5>
                                            <p className="mb-1">Profession</p>
                                            <div>
                                                <small className="fa fa-star text-warning"></small>
                                                <small className="fa fa-star text-warning"></small>
                                                <small className="fa fa-star text-warning"></small>
                                                <small className="fa fa-star text-warning"></small>
                                                <small className="fa fa-star text-warning"></small>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-0">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum et lorem et sit.</p>
                                </div>
                                <div className="testimonial-item rounded p-4">
                                    <div className="d-flex align-items-center mb-4">
                                        <img className="img-fluid bg-white rounded flex-shrink-0 p-1" src="img/testimonial-2.jpg" style={{ width: '85px', height: '85px' }} />
                                        <div className="ms-4">
                                            <h5 className="mb-1">Client Name</h5>
                                            <p className="mb-1">Profession</p>
                                            <div>
                                                <small className="fa fa-star text-warning"></small>
                                                <small className="fa fa-star text-warning"></small>
                                                <small className="fa fa-star text-warning"></small>
                                                <small className="fa fa-star text-warning"></small>
                                                <small className="fa fa-star text-warning"></small>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-0">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum et lorem et sit.</p>
                                </div>
                                <div className="testimonial-item rounded p-4">
                                    <div className="d-flex align-items-center mb-4">
                                        <img className="img-fluid bg-white rounded flex-shrink-0 p-1" src="img/testimonial-3.jpg" style={{ width: '85px', height: '85px' }} />
                                        <div className="ms-4">
                                            <h5 className="mb-1">Client Name</h5>
                                            <p className="mb-1">Profession</p>
                                            <div>
                                                <small className="fa fa-star text-warning"></small>
                                                <small className="fa fa-star text-warning"></small>
                                                <small className="fa fa-star text-warning"></small>
                                                <small className="fa fa-star text-warning"></small>
                                                <small className="fa fa-star text-warning"></small>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-0">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum et lorem et sit.</p>
                                </div>
                                <div className="testimonial-item rounded p-4">
                                    <div className="d-flex align-items-center mb-4">
                                        <img className="img-fluid bg-white rounded flex-shrink-0 p-1" src="img/testimonial-4.jpg" style={{ width: '85px', height: '85px' }} />
                                        <div className="ms-4">
                                            <h5 className="mb-1">Client Name</h5>
                                            <p className="mb-1">Profession</p>
                                            <div>
                                                <small className="fa fa-star text-warning"></small>
                                                <small className="fa fa-star text-warning"></small>
                                                <small className="fa fa-star text-warning"></small>
                                                <small className="fa fa-star text-warning"></small>
                                                <small className="fa fa-star text-warning"></small>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-0">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum et lorem et sit.</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="container-xxl py-5" id="contact">
                        <div className="container py-5 px-lg-5">
                            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                                <h5 className="text-primary-gradient fw-medium">Contact Us</h5>
                                <h1 className="mb-5">Get In Touch!</h1>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg-9 text-center">
                                    <div className="wow fadeInUp" data-wow-delay="0.3s">
                                        <p className="text-center mb-4">You can contact us for any questions, technical questions, suggestions, clarifications. Also, if you want to join on our portal you can share your contact details with us. </p>
                                        <p><BiPhone className='text-danger fs-5' /> +91-9893623033</p>
                                        <p><BiEnvelope className='text-danger fs-5' /> doctorondiscount@gmail.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid bg-primary text-light footer wow fadeIn" data-wow-delay="0.1s">
                        {/* <div className="container py-5 px-lg-5">
                        <div className="row g-5">
                            <div className="col-md-6 col-lg-3">
                                <h4 className="text-white mb-4">Address</h4>
                                <p><i className="fa fa-map-marker-alt me-3"></i>123 Street, New York, USA</p>
                                <p><i className="fa fa-phone-alt me-3"></i>+012 345 67890</p>
                                <p><i className="fa fa-envelope me-3"></i>info@example.com</p>
                                <div className="d-flex pt-2">
                                    <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-instagram"></i></a>
                                    <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <h4 className="text-white mb-4">Quick Link</h4>
                                <a className="btn btn-link" href="">About Us</a>
                                <a className="btn btn-link" href="">Contact Us</a>
                                <a className="btn btn-link" href="">Privacy Policy</a>
                                <a className="btn btn-link" href="">Terms & Condition</a>
                                <a className="btn btn-link" href="">Career</a>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <h4 className="text-white mb-4">Popular Link</h4>
                                <a className="btn btn-link" href="">About Us</a>
                                <a className="btn btn-link" href="">Contact Us</a>
                                <a className="btn btn-link" href="">Privacy Policy</a>
                                <a className="btn btn-link" href="">Terms & Condition</a>
                                <a className="btn btn-link" href="">Career</a>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <h4 className="text-white mb-4">Newsletter</h4>
                                <p>Lorem ipsum dolor sit amet elit. Phasellus nec pretium mi. Curabitur facilisis ornare velit non vulpu</p>
                                <div className="position-relative w-100 mt-3">
                                    <input className="form-control border-0 rounded-pill w-100 ps-4 pe-5" type="text" placeholder="Your Email" style={{ height: '48px' }} />
                                    <button type="button" className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"><i className="fa fa-paper-plane text-primary-gradient fs-4"></i></button>
                                </div>
                            </div>
                        </div>
                    </div> */}
                        <div className="container px-lg-5">
                            <div className="copyright">
                                <div className="row">
                                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                        &copy; <a className="border-bottom" href="#">Doctor on Discount</a>, All Right Reserved. Developed by <a href='https://www.techlene.com' target='_blank'>Techlene</a>
                                    </div>
                                    <div className="col-md-6 text-center text-md-end">
                                        <div className="footer-menu">
                                            <Link to='../shipping-and-delivery'> Shipping & delivery</Link>
                                            <Link to='../refund-and-return'>Refunds & Returns</Link>
                                            <Link to='../privacy-policy'>Privacy policy</Link>
                                            <Link to='../terms-and-conditions'>Terms & conditions</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="#" className="btn btn-lg btn-lg-square back-to-top pt-2"><i className="bi bi-arrow-up text-white"></i></a>
            </div>

        </>

    );
}

export default HomePage;
