import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addReasonRes;
export const addReasonTodo = createAsyncThunk(' addReason', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_reason',
            data: {

                "reason": data.reason,
                "status":data.status,
                "userRole": data.userRole,
                "added_by": localStorage.getItem('userId')
            },
            headers: { "Content-Type": "application/json" }
        })
        return addReasonRes = res.data

    } catch (error) {
        return addReasonRes = error.response.data
    }
})

export var updateReasonRes;
export const  updateReasonTodo = createAsyncThunk(' updateReason', async (data) => {
    try {
        const res = await axios({ 
            method: "post",
            url: process.env.REACT_APP_API_URL + `update_reason/${data.id}`,
            data: {

                "reason": data.values.reason,
                "userRole": data.values.userRole,
                "status":data.values.status,
                "modified_by": localStorage.getItem('userId')
            },
            headers: { "Content-Type": "application/json" }
        })
        return updateReasonRes = res.data

    } catch (error) {
        return updateReasonRes = error.response.data
    }
})