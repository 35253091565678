import React, { useState } from 'react';
import { BiUser } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import { RiLockPasswordLine } from "react-icons/ri";
import { useFormik } from 'formik';
import { logInSchema } from './LoginYup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logInRes, logInTodo } from '../../redux/slices/Login/login';
import PublicNavbar from '../../Pages/Policy&Term/PublicNavbar';


const AdminLogin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [spiner, setSpiner] = useState(false)

    const initiaValues = {
        number: '',
        password: ''
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initiaValues,
        validationSchema: logInSchema,

        onSubmit: (values, action) => {
            setSpiner(true)
            dispatch(logInTodo(values)).then(() => loginSuccess())
        }
    })

    const loginSuccess = () => {
        if (logInRes && logInRes.success) {
            localStorage.setItem('userId', logInRes.data[0].userId)
            localStorage.setItem('userName', logInRes.data[0].name)
            localStorage.setItem('number', logInRes.data[0].number)
            localStorage.setItem('userRole', logInRes.data[0].userRole)
            localStorage.setItem('doctorID', logInRes.data[0].doctorid ? logInRes.data[0].doctorid : '')
            localStorage.setItem('drProfile', logInRes.data[0].profilePicture ? logInRes.data[0].profilePicture : '')
            setSpiner(false);
            navigate('/dashboard')
        } else {
            setSpiner(false);
            toast.error(logInRes && logInRes.message, { position: "bottom-right" });
        }
    }


    return (
        <>
        <PublicNavbar/>
            <div className="container">
                <div className=" login-container row d-flexh-100 g-0 justify-content-between">
                    <div className="col-sm-6 align-self-md-center my-auto">
                        <div className='side-logo'>
                            <img src="../Group.png" alt="" className='img-fluid logo-img' />
                        </div>
                    </div>
                    <div className="col-sm-6 align-self-md-center my-auto">
                        <div className='login-details'>

                            <div>
                                <div className='finalLogo_section'>
                                    <img src="../logo2.png" alt="" className='img-fluid finalLogo' />
                                </div>
                                <h2>Please<span> Login !</span> </h2>
                                <form className='mt-4' onSubmit={handleSubmit}>
                                    <div className="input-group  input_section" style={{ position: 'relative' }}>
                                        <span className='input_icon'> <AiOutlineMail className='mb-1 me-2 ' style={{ color: "black" }} size={20} /></span>
                                        <input type="text" className="form-control form-login-input login_placeholder "
                                            name='number'
                                            placeholder="User Number"
                                            value={values.number}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                    {errors.number && touched.number ? (<span className='text-danger ms-4' >{errors.number}</span>) : null}


                                    <div className="input-group mt-2 input_section" style={{ position: 'relative' }}>
                                        <span className='input_icon'> <RiLockPasswordLine className='mb-1 me-2 ' style={{ color: "black" }} size={20} /></span>
                                        <input type="password" className="form-control form-login-input login_placeholder "
                                            name='password'
                                            placeholder="User password"
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                    {errors.password && touched.password ? (<span className='text-danger ms-4' >{errors.password}</span>) : null}
                                    
                                    <div className='forgot_psw_txt mt-2'>
                                        <Link to="../forgot-password">
                                            <p>Forgot Your Password ?</p>
                                        </Link>
                                    </div>
                                    <div className=' d-flex justify-content-center'>
                                        <button type='submit' className='btn Login-btn'>
                                            {spiner ? <p style={{ marginBottom: '0px' }}> Login... <span style={{ color: "white" }} className=" ms-2 spinner-border spinner-border-sm" aria-hidden="true"></span> </p> : "Login"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default AdminLogin;
