import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addDoctorRes;
export const  addDoctorTodo = createAsyncThunk(' addDoctor', async (data) => {
    // console.log("doctorData", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_doctor',
            data: data,
            headers: { "Content-Type": "multipart/form-data" }
        })
        return addDoctorRes = res.data

    } catch (error) {
        return addDoctorRes = error.response.data
    }
})

export var updateDoctorRes;
export const  updateDoctorTodo = createAsyncThunk(' updateDoctor', async (data) => {
    // console.log("update", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + `update_doctor/${data.id}`,
            data: data.bodyFormData,
            headers: { "Content-Type": "multipart/form-data" }
        })
        return updateDoctorRes = res.data

    } catch (error) {
        return updateDoctorRes = error.response.data
    }
})

export var updateProfileRes;
export const  updateProfileTodo = createAsyncThunk(' updateProfile', async (data) => {
    console.log("update", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + `update_doctor/${data.doctorId}`,
            data: data.bodyFormData,
            headers: { "Content-Type": "multipart/form-data" }
        })
        return updateProfileRes = res.data

    } catch (error) {
        return updateProfileRes = error.response.data
    }
})