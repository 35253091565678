import React, { useState } from 'react'
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import AddButton from '../../Button/AddButton';
import TextInput from '../../InputFiled/TextInput';
import { otpRes, otpTodo } from '../../../redux/slices/Login/changePassword';
import { useNavigate } from 'react-router-dom';
import { otpSchema } from '../ForgotPswPopup/otpYup';

export default function OTPResetPage() {
    const [spiner, setSpiner] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const initialValues = {
        otp: ''
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: otpSchema,
        onSubmit: (values, action) => {
            setSpiner(true)
            dispatch(otpTodo(values)).then(() => otpverify())
        },
    })

    const otpverify = () => {
        if (otpRes && otpRes.success) {
            toast.success(otpRes && otpRes.message, { position: 'bottom-right' })
            localStorage.setItem('userId', otpRes.data[0].userId)
            localStorage.setItem('userRole', otpRes.data[0].userRole)
            setSpiner(false);
            setTimeout(() => {
                navigate('/change-psw')
            }, 1300);
        } else {
            toast.error(otpRes && otpRes.message, { position: 'bottom-right' })
            setSpiner(false);
        }
    }

    return (
        <>
            <div className="container">
                <div className=" login-container row d-flexh-100 g-0 justify-content-between">
                    <div className="col-sm-6 align-self-md-center my-auto">
                        <div className='side-logo'>
                            <img src="../Group.png" alt="" className='img-fluid logo-img' />
                        </div>
                    </div>
                    <div className="col-sm-6 align-self-md-center my-auto">
                        <div className='login-details'>
                            <div>
                            <div className='finalLogo_section'>
                                    <img src="../logo2.png" alt="" className='img-fluid finalLogo' />
                                </div>
                                <h2>OTP<span> Verify !</span> </h2>
                                <div className='mt-3'>
                                    <form onSubmit={handleSubmit}>
                                        <TextInput
                                            name='otp'
                                            label={'Enter OTP'}
                                            value={values.otp}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.otp && touched.otp ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.otp}</span>) : null}

                                        <div className='mt-4 d-flex justify-content-center'>
                                            {/* <AddButton label={' Submit'} /> */}
                                            <button type='submit' className='btn Login-btn'>
                                            {spiner ? <p style={{ marginBottom: '0px' }}> Submit... <span style={{ color: "white" }} className=" ms-2 spinner-border spinner-border-sm" aria-hidden="true"></span> </p> : "SUBMIT"}
                                        </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
