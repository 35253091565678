import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addSubscriptionRes;
export const  addSubscriptionTodo = createAsyncThunk(' addSubscription', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_subscription',
            data: data,
            headers: { "Content-Type": "multipart/form-data" }
        })
        return addSubscriptionRes = res.data

    } catch (error) {
        return addSubscriptionRes = error.response.data
    }
})

export var updateSubscriptionRes;
export const  updateSubscriptionTodo = createAsyncThunk(' updateSubscription', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + `update_subscription/${data.planID}`,
            data: data.bodyFormData,
            headers: { "Content-Type": "multipart/form-data" }
        })
        return updateSubscriptionRes = res.data

    } catch (error) {
        return updateSubscriptionRes = error.response.data
    }
})