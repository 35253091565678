import { configureStore } from "@reduxjs/toolkit";
import categorylist from "./slices/Master/Categorgy/categorylist";
import specialitylist from "./slices/Master/Speciality/specialitylist";
import courselist from "./slices/Master/Course/courselist";
import statelist from "./slices/StateCity/statelist";
import citylist from "./slices/StateCity/citylist";
import docterList from "./slices/DoctorSlices/docterList";
import diseaseList from "./slices/Master/Disease/diseaseList";
import bannerList from "./slices/Master/HomeBanner/bannerList";
import BookingList from "./slices/BookingSlices/BookingList";
import PatientList from "./slices/PatientSlices/PatientList";
import userList from "./slices/UserSlices/userList";
import dashboard from "./slices/Dashboard/dashboard";
import doctorDashboard from "./slices/Dashboard/doctorDashboard";
import DoctorPatientList from "./slices/PatientSlices/DoctorPatientList";
import PaymentList from "./slices/PaymentSlices/PaymentList";
import PlanList from "./slices/Master/SubscriptionList/PlanList";
import ReasonList from "./slices/Master/Reason/ReasonList";
import bookingFilterList from "./slices/BookingSlices/bookingFilterList";
import doctorDetails from "./slices/DoctorSlices/doctorDetails";
import NotificationList from "./slices/Notification/NotificationList";
import VersionList from "./slices/VersionUpdate/VersionList";
import BookingDetails from "./slices/BookingSlices/BookingDetails";


const Store = configureStore({
    reducer: {
        dashboard: dashboard,
        doctordashboard: doctorDashboard,
        categoryList: categorylist,
        specialityList: specialitylist,
        courseList: courselist,
        diseaseList: diseaseList,
        bannerList: bannerList,
        reasonList: ReasonList,
        stateList: statelist,
        cityList: citylist,
        doctorList: docterList,
        docterDetails:doctorDetails,
        bookingList: BookingList,
        bookingFilterList : bookingFilterList,
        BookingDetails : BookingDetails,
        patientList: PatientList,
        userList: userList,
        doctorPatientList: DoctorPatientList,
        paymentList: PaymentList,
        planList: PlanList,
        notificationList:NotificationList,
        VersionList : VersionList
    }
})
export default Store;