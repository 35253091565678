import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { date } from "yup";

export var adminPswUpdateRes;
export const adminPswUpdateTodo = createAsyncThunk('adminPswUpdate', async (data) => {

    const userID = localStorage.getItem('userId');
    console.log(userID)
    const res = await axios({
        method: "post",
        // mode: 'cors',
        url: process.env.REACT_APP_API_URL + 'password_updated',
        data: {
            "id": userID  ,
            "userRole": data.userRole,
            "Password": data.new_psw,
            "confirmPassword": data.confirm_psw
        },
        crossDomain: true,
        headers: { "Content-Type": "application/json" }
    })
    return adminPswUpdateRes = res.data
})

export var forgotPswRes;
export const forgotPswTodo = createAsyncThunk('forgotPsw', async (data) => {

    // console.log("forgotPsw", data)

    const res = await axios({
        method: "post",
        url: process.env.REACT_APP_API_URL + 'number_verify',
        data: {
            // "type": data.type,
            "mobile": data.number
        },
        crossDomain: true,
        headers: { "Content-Type": "application/json" }
    })
    return forgotPswRes = res.data
})

export var otpRes;
export const otpTodo = createAsyncThunk('otp', async (data) => {

    console.log("otp", data)

    const res = await axios({
        method: "post",
        url: process.env.REACT_APP_API_URL + 'otp_verify',
        data: {
            "mobile": localStorage.getItem('contactNumber'),
            "otp": data.otp
        },
        crossDomain: true,
        headers: { "Content-Type": "application/json" }
    })
    return otpRes = res.data
})