import React, { useState } from 'react'
import TextInput from '../../../InputFiled/TextInput'
import StatusSelect from '../../../InputFiled/StatusSelect'
import AddButton from '../../../Button/AddButton'
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { addSpecialityTodo, addSpecialityRes, updateSpecialityTodo, updateSpecialityRes } from '../../../../redux/slices/Master/Speciality/addSpeciality'
import { AddSpecialitySchema } from './validation';
import { useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import Loader from '../../../Loader/Loader';


export default function SpecialityForm(props) {
    const { bool, setBool } = props;
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams();
    const { state } = useLocation();
    var specialityData = state;

    const initialValues = {
        speciality: id ? specialityData && specialityData.speciality : "",
        status: id ? specialityData && specialityData.status : ""
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: AddSpecialitySchema,

        onSubmit: (values, action) => {
            setLoading(true)
            {
                id ? dispatch(updateSpecialityTodo({ values, id })).then(() => updateSpecialityFun()) :
                    dispatch(addSpecialityTodo(values)).then(() => addSpcialityFun())
            }

            id ? <></> : action.resetForm()
        },
    })

    const addSpcialityFun = () => {
        if (!addSpecialityRes && addSpecialityRes.success) {
          
            setLoading(false)
            toast.error(addSpecialityRes && addSpecialityRes.message, { position: "bottom-right" })
            setBool(!bool)

        } else {
            setLoading(false)
            setBool(!bool)
            toast.success(addSpecialityRes && addSpecialityRes.message, { position: "bottom-right" })
        }
    }

    const updateSpecialityFun = () => {
        if (updateSpecialityRes && updateSpecialityRes.success) {
            setLoading(false)
            toast.success(updateSpecialityRes && updateSpecialityRes.message, { position: "bottom-right" })
            setTimeout(() => {
                navigate('../speciality')
            }, 1500);
        } else {
            setLoading(false)
            toast.error(updateSpecialityRes && updateSpecialityRes.message, { position: "bottom-right" })
        }
    }

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='master_form_section'>
                            {loading ?
                                <div className='d-flex justify-content-center mt-5'>
                                    <Loader />
                                </div> :
                                <form onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className='col-md-4 mb-3'>
                                            <TextInput
                                                name='speciality'
                                                label={'Speciality Name'}
                                                value={values.speciality}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.speciality && touched.speciality ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.speciality}</span>) : null}

                                        </div>
                                        <div className='col-md-4 mb-3'>
                                            <StatusSelect
                                                name='status'
                                                label={'Status'}
                                                value={values.status}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.status && touched.status ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.status}</span>) : null}

                                        </div>
                                        <div className='col-md-12 '>

                                            <AddButton
                                                label={'Save'} />
                                        </div>
                                    </div>
                                </form>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
