import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var bookingFilterListRes;
export const bookingFilterListTodo = createAsyncThunk('bookingFilterList', async (data) => {
    const docterId = localStorage.getItem('doctorID');
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'booking_filter_list',
            data: {
                "fk_doctor_id": docterId ? docterId : data ? data.values.doctor_id && data.values.doctor_id ? data.values.doctor_id : '' : '',
                "startDate": data ? data.startDate && data.startDate ? data.startDate :'' :'' ,
                "fk_user_id": '',
                "endDate": data ? data.endDate && data.endDate ? data.endDate :'' :'' ,
                "booking_stime": "",
                "booking_status":data ? data.values.booking_status && data.values.booking_status ? data.values.booking_status : '' : '',
            },
            headers: { "Content-Type": "application/json" }
        })
        return bookingFilterListRes = res.data

    } catch (error) {
        return bookingFilterListRes = error.response.data
    }
})

const bookingFilterListSlice = createSlice({
    name: 'bookingFilterList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(bookingFilterListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('equipmentList', state.data.response)
        });
        builder.addCase(bookingFilterListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default bookingFilterListSlice.reducer; 