import * as Yup from "yup";

export const AddUserSchema = Yup.object({
    user_name: Yup.string().required("Please Enter Name").matches(/^[a-zA-Z]/, "Empty Space Not Allow"),
    mobile_nu: Yup.string().min(10, 'Must be exactly 10 digits').required("Enter Mobile Number").matches(/^(((0|((\+)?91(\-)?))|((\((\+)?91\)(\-)?)))?[6-9]\d{9})?$/, "Invalid Number"),
    addhar_no: Yup.string().matches(/^[2-9][0-9]{3}\s[0-9]{4}\s[0-9]{4}$/ , "Invalid Number"),
    age: Yup.number().required(" Enter Age").min(1, "Age Can't be less then 1"),
    gender: Yup.string().required(" Select gender"),
    email: Yup.string().email(),
    state:Yup.string().required(" Select State") ,
    city: Yup.string().required(" Select city") ,
    plan: Yup.string().required(" Select plan") ,
    date: Yup.string().required(" Select date") ,
})

export const updateUserSchema = Yup.object({
    user_name: Yup.string().required("Please Enter Name").matches(/^[a-zA-Z]/, "Empty Space Not Allow"),
    // mobile_nu: Yup.string().min(10, 'Must be exactly 10 digits').required("Enter Mobile Number").matches(/^(((0|((\+)?91(\-)?))|((\((\+)?91\)(\-)?)))?[6-9]\d{9})?$/, "Invalid Number"),
    // addhar_no: Yup.string().matches(/^[2-9][0-9]{3}\s[0-9]{4}\s[0-9]{4}$/ , "Invalid Number"),
    age: Yup.number().required(" Enter Age").min(1, "Age Can't be less then 1"),
    gender: Yup.string().required(" Select gender"),
    email: Yup.string().email(),
    state:Yup.string().required(" Select State") ,
    city: Yup.string().required(" Select city") ,
    // plan: Yup.string().required(" Select plan") ,
    // date: Yup.string().required(" Select date") ,
    status: Yup.string().required(" Select Status"),

})