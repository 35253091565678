import React from 'react';

const StatusSelect = (props) => {
    return (
        <>
            <label className="form-label label_head"> {props.label}</label>
            <select className="form-select input_filed"
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}>
                <option value=''>Select</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
            </select>
        </>
    );
}

export default StatusSelect;
