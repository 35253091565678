import React from 'react'
import AddUpdateBooking from '../../component/Form/BookingForm/addUpdateBooking'

export default function AddBooking() {
  return (
    <>
       <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form_head">
                            <h4>Add Booking</h4>
                        </div>
                        <AddUpdateBooking/>
                    </div>
                </div>
            </div>
    </>
  )
}
