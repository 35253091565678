import React from 'react';
import Select from 'react-select'

const ReactSelectInput = (props) => {
    return (
        <>
            <label className="form-label label_head " > {props.label}</label>
            <Select
                closeMenuOnSelect={true}
                options={props.options}
                defaultValue={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
                isMulti={false}
                className="react_select "
                classNamePrefix="select"
            />
        </>
    );
}

export default ReactSelectInput;
