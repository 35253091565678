import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import moment from 'moment';

export var addBookingRes;
export const addBookingTodo = createAsyncThunk(' addBooking', async (data) => {
    var bookingStart = moment(data.booking_stime.split(' to ', 2)[0], 'hh:mm A').format("HH:mm:ss")
    var bookingEnd = moment(data.booking_stime.split(' to ', 2)[1], 'hh:mm A').format("HH:mm:ss")
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_booking',
            data: {
                "slot": data.slot,
                "fk_doctor_id": data.doctor_id,
                "fk_clinic_id": data.clinic_id,
                "fk_user_id": data.user_id,
                "patient_id": data.patient_id,
                "booking_for": "",
                "booking_stime": bookingStart,
                "booking_etime": bookingEnd,
                "booking_date": data.booking_date,
                "disease_id": '11',
                "disease_detail": data.disease_details,

            },
            headers: { "Content-Type": "application/json" }
        })
        return addBookingRes = res.data

    } catch (error) {
        return addBookingRes = error.response.data
    }
})


