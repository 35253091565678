import React from 'react'
import { Link } from 'react-router-dom'
import PublicNavbar from './PublicNavbar'

export default function ContactUs() {
    return (
        <>
            < div>
              
                <PublicNavbar />
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className='shipping_delivery text-start'>
                                <h3> Contact Us</h3>
                                <h5>You can contact us for any questions, technical questions, suggestions, clarifications. Also, if you want to join on our portal you can share your contact details with us.</h5>

                                <h5 className='mt-3'>Email: doctorondiscount@gmail.com</h5>
                                <h5 className='mt-1'>Contact No : 9893623033</h5>


                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
