import React, { useState, useEffect } from 'react';
import Loader from '../../component/Loader/Loader';
import DataTables from '../../component/DataTable/DataTable';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { patientListRes, patientListTodo } from '../../redux/slices/PatientSlices/PatientList';

const UserPatientList = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const patientListData = useSelector(state => state && state.patientList && state.patientList.data && state.patientList.data.data)
    const { id } = useParams();

    useEffect(() => {
        setLoading(true)
        dispatch(patientListTodo(id)).then(() => patientListFun())
    }, [])

    const patientListFun = () => {
        if (patientListRes && patientListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const columnDefs = [
        {
            headerName: 'S.NO.',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 100,
        },
        {
            headerName: 'PATIENT NAME',
            field: 'name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        }, {
            headerName: 'MOBILE NO.',
            field: 'phone',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },
      
        {
            headerName: 'AGE',
            field: 'age',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: 'REM. BOOKING',
            field: 'booking_count',
            cellRenderer: params => params.data.booking_count === null ? '-' : params.data.booking_count,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: 'GENDER',
            field: 'gender',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        }
    ]

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    {
                        loading ?
                            <div className='d-flex justify-content-center mt-5 pt-5'>
                                <Loader />
                            </div>
                            :
                            patientListData && patientListData.length ?
                                <div className='mt-3'>
                                    <DataTables
                                        rowData={patientListData}
                                        columnDefs={columnDefs}
                                    />
                                </div> :
                                <div className='d-flex empty_data_section justify-content-center mt-3'>
                                    <h4>Patient List Not Found</h4>
                                </div>}
                </div>
            </div>
        </>
    );
}

export default UserPatientList;
