import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import SaveButton from '../../component/Button/SaveButton';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UserPatientList from './UserPatientList';
import UserBookingList from './UserBookingList';

const UserDetails = () => {

    const { id } = useParams();
    const { state } = useLocation();
    var userData = state
    console.log("state", userData)

    return (
        <>
            <div className="container-fluid">
                <div className="row">

                    <div className="col-md-3">
                        <div className="form_head d-flex justify-content-between align-items-center">
                            <h4> User Details</h4>
                            {/* <Link to='../addpatient' state={userData.id}  >  <SaveButton label={'Add Patient'} /></Link> */}
                        </div>
                        <div className='profile_sidebar ps-3 pe-3 mt-2'>
                            <div className="user_img_section mt-3">
                                {userData.profilePicture === '' ? <img src='../profile.png' alt="userImg" className='img-fluid user_img' /> : <img src={process.env.REACT_APP_IMAGE_URL + userData.profilePicture} alt="userImg" className='img-fluid user_img' />}
                            </div>
                            <div className='text-center userInfo'>
                                <h4>{userData.username}</h4>
                                <p>{userData.email}</p>
                                <span> ( {userData.mobile} )</span> <br />
                                <span>  {userData.membership_id} </span>


                            </div>
                            <hr />
                            <div className='d-flex justify-content-between'>
                                <h6>Registration Date :</h6>
                                {userData.Registration_date === null || userData.Registration_date === '' ? '-' : <h6> {new Date(userData.Registration_date).toLocaleDateString('en-GB')}</h6>}
                            </div>
                            <div className='d-flex justify-content-between'>
                                <h6>Rem. Booking:</h6>
                                <h6> {userData.Patient.booking_count === null ? '-' : userData.Patient.booking_count}</h6>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <h6>Aadhaar No. :</h6>
                                <h6> {userData.adhaar_no}</h6>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <h6>Gender :</h6>
                                <h6> {userData.gender}</h6>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <h6>City :</h6>
                                <h6> {userData && userData.city_master && userData.city_master.city}</h6>
                            </div>

                            <div className='d-flex justify-content-between'>
                                <h6>End Date :</h6>
                                {userData.expired_plan_date === null || userData.expired_plan_date === '' ? '-' : <h6> {new Date(userData.expired_plan_date).toLocaleDateString('en-GB')}</h6>}
                            </div>
                            <div className='d-flex justify-content-between'>
                                <h6>Status :</h6>
                                <h6 style={{textTransform:"capitalize"}}> {userData.status}</h6>
                            </div>
                            <div className=''>
                                <h6>Address :</h6>
                                <h6> {userData && userData.address}</h6>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-9 ">
                        <Tabs
                            defaultActiveKey="patient"
                            id="fill-tab-example"
                            className="mb-3 piils_btn"
                            fill
                        >
                            <Tab eventKey="patient" title={<span>Patient List </span>}>
                                <UserPatientList />
                            </Tab>
                            <Tab eventKey="booking" title={<span>Booking List </span>}>
                                <UserBookingList />
                            </Tab>
                        </Tabs>

                    </div>
                </div>
            </div>
        </>
    );
}

export default UserDetails;
