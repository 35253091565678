import React, { useEffect, useState } from 'react'
import TextInput from '../../../InputFiled/TextInput'
import AddButton from '../../../Button/AddButton'
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { updateSubscriptionRes, updateSubscriptionTodo } from '../../../../redux/slices/Master/SubscriptionList/AddupdatePlan';
import { planListRes, planListTodo } from '../../../../redux/slices/Master/SubscriptionList/PlanList';
import { UpdatePlanSchema } from './validation';
import Loader from '../../../Loader/Loader'

export default function SubscriptionForm(props) {

    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()
    const planDetails = useSelector(state => state && state.planList && state.planList.data && state.planList.data.data && state.planList.data.data[0])
    var planID = planDetails && planDetails.id

    let initialValues = {
        subscription_name: planDetails && planDetails.plan_name,
        amount: planDetails && planDetails.amount,
        gst_percentage: planDetails && planDetails.gst_percentage,
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: UpdatePlanSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(updateSubscriptionTodo({ bodyFormData, planID })).then(() => updateSubscription())
        },
    })

    const totalGSTAmt = (parseInt(values.amount) * parseInt(values.gst_percentage) / 100)
    let bodyFormData = new FormData();
    bodyFormData.append("plan_name", values.subscription_name)
    bodyFormData.append("amount", values.amount)
    bodyFormData.append("gst_amt", totalGSTAmt)
    bodyFormData.append("gst_percentage", values.gst_percentage)
    bodyFormData.append("user_id", localStorage.getItem('userId'))

    const updateSubscription = () => {
        if (updateSubscriptionRes && updateSubscriptionRes.success) {
            toast.success(updateSubscriptionRes && updateSubscriptionRes.message, { position: "bottom-right" })
            setLoading(false)
        } else {
            setLoading(false)
            toast.error(updateSubscriptionRes && updateSubscriptionRes.message, { position: "bottom-right" })
        }
    }

    const planListFun = () => {
        if (planListRes && planListRes.success) {
            setLoading(false)
            initialValues.subscription_name = planListRes.data[0].plan_name
            initialValues.amount = planListRes.data[0].amount
            initialValues.gst_percentage = planListRes.data[0].gst_percentage

        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        setLoading(true)
        dispatch(planListTodo()).then(() => planListFun())
    }, [])


    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='master_form_section'>
                            {loading ?
                                <div className='d-flex justify-content-center mt-5'>
                                    <Loader />
                                </div> :
                                <form onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className='col-md-3 mb-3'>
                                            <TextInput
                                                name='subscription_name'
                                                label={'Plan Name'}
                                                value={values.subscription_name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.subscription_name && touched.subscription_name ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.subscription_name}</span>) : null}
                                        </div>

                                        <div className='col-md-3 mb-3'>
                                            <TextInput
                                                name='amount'
                                                label={'Amount'}
                                                value={values.amount}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.amount && touched.amount ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.amount}</span>) : null}
                                        </div>
                                        <div className='col-md-3 mb-3'>
                                            <TextInput
                                                name='gst_percentage'
                                                label={'GST (%) '}
                                                value={values.gst_percentage}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.gst_percentage && touched.gst_percentage ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.gst_percentage}</span>) : null}
                                        </div>
                                        <div className='col-md-3 mb-3'>
                                            <TextInput
                                                name='gst_amt'
                                                readOnly={'readOnly'}
                                                label={'GST Amount '}
                                                value={totalGSTAmt}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.gst_amt && touched.gst_amt ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.gst_amt}</span>) : null}
                                        </div>

                                        <div className='col-md-12 '>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <AddButton
                                                    label={"Update"} />
                                                <div className='total_amt'>
                                                    <p>Total Amount : <span>{parseFloat(values.amount) + parseFloat(totalGSTAmt)}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
