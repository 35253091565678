import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import TextInput from '../../InputFiled/TextInput';
import AddButton from '../../Button/AddButton';
import Loader from '../../Loader/Loader';
import { versionListRes, versionListTodo } from '../../../redux/slices/VersionUpdate/VersionList';
import { updateVersionRes, updateVersionTodo } from '../../../redux/slices/VersionUpdate/UpdateVersion';
import { UpdateVersionSchema } from './Validation';


export default function UpdateVersionForm() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const version = useSelector(state => state && state.VersionList && state.VersionList.data && state.VersionList.data.data)

    let initialValues = {
        type: "",
        andriod_version: version && version[0] && version[0].version,
        ios_version: version && version[1] && version[1].version
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema : UpdateVersionSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(updateVersionTodo(values)).then((res) => updateVersion_res(res))
        },
    })
    const updateVersion_res = (res) => {
        
        if (res && res.payload && res.payload && res.payload.data && res.payload.data.success) {
            toast.success(res && res.payload && res.payload && res.payload.data && res.payload.data.message, { position: 'bottom-right' })
            setLoading(false)

        } else {
            toast.error(res && res.payload && res.payload && res.payload.data && res.payload.data.message, { position: 'bottom-right' })
            setLoading(false)

        }
    }
    useEffect(() => {
        setLoading(true)
        dispatch(versionListTodo()).then(() => versionList_Res())
    }, [])

    const versionList_Res = () => {
        if (versionListRes && versionListRes.success) {
            setLoading(false)
            initialValues.andriod_version = versionListRes && versionListRes.data && versionListRes.data[0] && versionListRes.data[0].version
            initialValues.ios_version = versionListRes && versionListRes.data && versionListRes.data[1] && versionListRes.data[1].version
        } else {
            setLoading(false)
        }
    }

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='master_form_section'>
                            {loading ?
                                <div className='d-flex justify-content-center mt-5'>
                                    <Loader />
                                </div> :
                                <form onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className='col-md-4 mb-3'>
                                            <TextInput
                                                name='andriod_version'
                                                label={version && version[0] && version[0].type}
                                                value={values.andriod_version}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.andriod_version && touched.andriod_version ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.andriod_version}</span>) : null}

                                        </div>
                                        <div className='col-md-4 mb-3'>
                                            <TextInput
                                                name='ios_version'
                                                label={version && version[1] && version[1].type}
                                                value={values.ios_version}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.ios_version && touched.ios_version ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.ios_version}</span>) : null}

                                        </div>

                                        <div className='col-md-12 '>

                                            <AddButton
                                                label={'Update'} />
                                        </div>
                                    </div>
                                </form>}
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
