import * as Yup from "yup";

export const AddCategorySchema = Yup.object({
    category: Yup.string().required(" Enter  Category Name").matches(/^[a-zA-Z]/, "Empty Space Not Allow"),
    image:  Yup.mixed().required('Image is required'),
    status: Yup.string().required(" Select Status"),


})

export const UpdateCategorySchema = Yup.object({
    category: Yup.string().required(" Enter  Category Name").matches(/^[a-zA-Z]/, "Empty Space Not Allow"),
    status: Yup.string().required(" Select Status"),


})

