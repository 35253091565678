import React, { useState, useEffect } from 'react'
import DataTables from '../../component/DataTable/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../component/Loader/Loader';
import { RiComputerLine } from "react-icons/ri";
import { notificationListRes, notificationListTodo } from '../../redux/slices/Notification/NotificationList';
import { useNavigate } from 'react-router-dom';

export default function NotificationList(props) {
  const { bool, setBool } = props;
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const notificationList = useSelector(state => state && state.notificationList && state.notificationList.data && state.notificationList.data.data)

  const notificationFun = () => {
    if (notificationListRes && notificationListRes.success) {
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const notification_details = (params) => {
    navigate(`../notification-details/${params.data.id}`, { state: params && params.data })
  }

  useEffect(() => {
    setLoading(true)
    dispatch(notificationListTodo()).then(() => notificationFun())
  }, [bool])

  const columnDefs = [
    {
      headerName: 'S.NO.',
      valueGetter: 'node.rowIndex + 1',
      filter: 'false',
      suppressMenu: true,
      sortable: true,
      floatingFilter: false,
      wrapText: true,
      autoHeight: true,
      maxWidth: 100,
    },
    {
      headerName: 'IMAGE',
      field: 'categoryImage',
      cellRenderer: params => <div className={ params.data.notification_image === '' ? "": 'table_img_sec'}>
      { params.data.notification_image === '' ? "NA" :  <img className=" img-fluid table_data_img" src={process.env.REACT_APP_IMAGE_URL_SEC + params.data.notification_image} alt="image" />}
      </div>,
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      filter: true,
      sortable: true,
      floatingFilter: false,
      wrapText: true,
      autoHeight: true,
      maxWidth: 100,
    },
    {
      headerName: 'TITLE ',
      field: 'title',
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      filter: true,
      sortable: true,
      floatingFilter: false,
      wrapText: true,
      autoHeight: true,
      maxWidth: 300,
    },
    {
      headerName: 'DESCRIPTION ',
      field: 'description',
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      filter: true,
      sortable: true,
      floatingFilter: false,
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: 'DATE',
      field: 'status',
      cellRenderer: params => new Date(params && params.data && params.data.notification_date).toLocaleDateString('en-GB'),
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      filter: true,
      sortable: true,
      floatingFilter: false,
      // wrapText: true,
      // autoHeight: true
      maxWidth: 100,
    },
    {
      headerName: 'STATUS',
      field: 'status',
      cellRenderer: params => params.value == 'Completed' ? <span className='badge text-bg-success'>Completed</span> : <span className='badge text-bg-danger'>Pending</span>,
      filter: 'agTextColumnFilter',
      suppressMenu: true,
      filter: true,
      sortable: true,
      floatingFilter: false,
      // wrapText: true,
      // autoHeight: true
      maxWidth: 100,
    },
    {
      field: 'ACTIONS',
      cellRenderer: params =>
        <div> <RiComputerLine size={20} className='details ms-3' onClick={() => notification_details(params)} /> </div>,
      wrapText: true,
      autoHeight: true,
      maxWidth: 120,

    }
  ]
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="form_head">
              <h4> Notification List</h4>
            </div>
          </div>
          {
            loading ?
              <div className='d-flex justify-content-center mt-5 pt-5'>
                <Loader />
              </div>
              :
              notificationList && notificationList.length ?
                <div className='mt-3'>
                  <DataTables
                    rowData={notificationList}
                    columnDefs={columnDefs}
                  />
                </div> :
                <div className='d-flex empty_data_section justify-content-center mt-3'>
                  <h4>Notification List Not Found</h4>
                </div>}
        </div>
      </div>
    </>
  )
}
