import React from 'react'
import AddUpdateDoctor from '../../component/Form/DoctorForm/AddUpdateDoctor'

export default function UpdateDoctor() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form_head">
                            <h4>Update Doctor</h4>
                        </div>
                        <AddUpdateDoctor />
                    </div>
                </div>
            </div>
        </>
    )
}
