import React, { useState, useEffect } from 'react'
import Loader from '../../component/Loader/Loader'
import DataTables from '../../component/DataTable/DataTable'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { doctorPatientListRes, doctorPatientListTodo } from '../../redux/slices/PatientSlices/DoctorPatientList';

export default function DoctorPatientList() {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const patientListData = useSelector(state => state && state.doctorPatientList && state.doctorPatientList.data && state.doctorPatientList.data.data)
    const state = useLocation()
    const { id } = useParams();

    useEffect(() => {
        setLoading(true)
        dispatch(doctorPatientListTodo({ "doctorID": id })).then(() => doctorPatientListFun())
    }, [])

    const doctorPatientListFun = () => {
        if (doctorPatientListRes && doctorPatientListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }


    const columnDefs = [
        {
            headerName: 'S.NO.',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 100,
        },
        {
            headerName: 'PATIENT NAME',
            field: 'patient_detail.name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        }, {
            headerName: 'MOBILE NO.',
            field: 'patient_detail.phone',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },
 
        {
            headerName: 'AGE',
            field: 'patient_detail.age',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: 'GENDER',
            field: 'patient_detail.gender',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: 'REM. BOOKING',
            field: 'patient_detail.booking_count',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        }
    ]

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    {
                        loading ?
                            <div className='d-flex justify-content-center mt-5 pt-5'>
                                <Loader />
                            </div>
                            :
                            patientListData && patientListData.length ?
                                <div className='mt-3'>
                                    <DataTables
                                        rowData={patientListData}
                                        columnDefs={columnDefs}
                                    />
                                </div> :
                                <div className='d-flex empty_data_section justify-content-center mt-3'>
                                    <h4>Patient List Not Found</h4>
                                </div>}
                </div>
            </div>
        </>
    )
}
