import React from 'react'
import { Link } from 'react-router-dom'
import PublicNavbar from './PublicNavbar'

export default function RefunAndReturn() {
    return (
        <>
            < div>
       
                <PublicNavbar />
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-5">
                            <div className='shipping_delivery'>
                                <h3> Refund & Return</h3>
                                <h5 className='mt-3'>No cancellations & Refunds are entertained</h5>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
