import React, { useState, useEffect } from 'react'
import Loader from '../../../component/Loader/Loader'
import DataTables from '../../../component/DataTable/DataTable'
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux';
import { reasonListRes, reasonListTodo } from '../../../redux/slices/Master/Reason/ReasonList';
import { deleteReasonRes, deleteReasonTodo } from '../../../redux/slices/Master/Reason/deleteReason';

export default function ReasonList(props) {
    const { bool, setBool } = props;
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const reasonListData = useSelector(state => state && state.reasonList && state.reasonList.data && state.reasonList.data.data)

    useEffect(() => {
        setLoading(true)
        dispatch(reasonListTodo()).then(() => reasonListFun())
    }, [bool])

    const reasonListFun = () => {
        if (reasonListRes && reasonListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const edit_reason = (params) => {
        navigate(`../update_reason/${params.data.id}`, { state: params && params.data })
    }

    const del_reason = (id) => {
        Swal.fire({
            title: "Reason Delete",
            text: "Are You Sure Want To Delete This Reason ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteReasonTodo(id)).then(() => deleteReasonFun())
            }
        })
    }

    const deleteReasonFun = () => {
        if (deleteReasonRes && deleteReasonRes.success) {
            setBool(!bool)
            Swal.fire(
                "Reason",
                'Reason Deleted Successfully',
                'success'
            )
        } else {
            toast.error(deleteReasonRes && deleteReasonRes.message, { position: "bottom-right" })
        }
    }

    const columnDefs = [
        {
            headerName: 'S.NO.',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 100,
        },
        {
            headerName: 'REASON ',
            field: 'reason',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: 'ROLE ',
            field: 'userRole',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },

        {
            headerName: 'STATUS',
            field: 'status',
            cellRenderer: params => params.value == 'Active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,

        },
        {
            field: 'ACTIONS',
            cellRenderer: params =>
                <div>
                    <FiEdit size={20} className='edit' style={{ color: '#47BB4C', cursor: "pointer" }} onClick={() => edit_reason(params)} />
                    <RiDeleteBin6Line size={20} className='delete ms-3' style={{ color: '#EF3D50', cursor: "pointer" }} onClick={() => del_reason(params && params.data && params.data.id)} />
                </div>,
            wrapText: true,
            autoHeight: true,
        }
    ]

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">

                        <div className="form_head">
                            <h4> Reason List</h4>
                        </div>
                    </div>
                    {
                        loading ?
                            <div className='d-flex justify-content-center mt-5 pt-5'>
                                <Loader />
                            </div>
                            :
                            reasonListData && reasonListData.length ?
                                <div className='mt-3'>
                                    <DataTables
                                        rowData={reasonListData}
                                        columnDefs={columnDefs} />
                                </div>
                                :
                                <div className='d-flex empty_data_section justify-content-center mt-3'>
                                    <h4>Reason List Not Found</h4>
                                </div>}
                </div>
            </div>
        </>
    )
}
