import React, { useState, useEffect } from 'react';
import RadioInput from '../../InputFiled/RadioInput';
import TextInput from '../../InputFiled/TextInput';
import FileInput from '../../InputFiled/FileInput';
import NumberInput from '../../InputFiled/NumberInput';
import ReactSelectInput from '../../InputFiled/ReactSelectInput';
import DateTimeInput from '../../InputFiled/DateTimeInput';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import EmailInput from '../../InputFiled/EmailInput';
import AddButton from '../../Button/AddButton';
import { cityListRes, cityListTodo } from '../../../redux/slices/StateCity/citylist';
import { AddUserSchema, updateUserSchema } from './validation';
import { addUserRes, addUserTodo, updateUserRes, updateUserTodo } from '../../../redux/slices/UserSlices/addUser';
import { planListRes, planListTodo } from '../../../redux/slices/Master/SubscriptionList/PlanList';
import { stateListRes, stateListTodo } from '../../../redux/slices/StateCity/statelist';
import Loader from '../../Loader/Loader';
import { addSubscriptionTodo, addSubscriptionRes } from '../../../redux/slices/UserSlices/addSubscription'
import { addPaymentRes, addPaymentTodo } from '../../../redux/slices/UserSlices/addPayment'
import StatusSelect from '../../InputFiled/StatusSelect';


const AddupdateUser = () => {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const { id } = useParams();
    const navigate = useNavigate()
    const { state } = useLocation();
    var userData = state;
    const cityList = useSelector(state => state && state.cityList && state.cityList.data && state.cityList.data.data)
    const planList = useSelector(state => state && state.planList && state.planList.data && state.planList.data.data)
    const stateList = useSelector(state => state && state.stateList && state.stateList.data && state.stateList.data.data)
    var city = []
    var planOption = []
    var stateOption = []


    const cityRender = cityList && cityList.map((value) => city.push({
        "value": value.id,
        "label": value.city
    }))

    const planRender = planList && planList.map((value) => planOption.push({
        "value": value.id,
        "label": value.plan_name + " " + "( " + 'Amt :' + value.total_amount + ' , ' + 'Validity : ' + value.validity + 'mnt' + " )"
    }))

    const stateRender = stateList && stateList.map((value) => stateOption.push({
        "value": value.id,
        "label": value.state
    }))



    useEffect(() => {
        setLoading(true)
        dispatch(planListTodo()).then((res1) => {
            if (res1?.payload?.success) {
                dispatch(stateListTodo()).then((res2) => {
                    if (res2?.payload?.success) {
                        dispatch(cityListTodo('21')).then((res3) => {
                            if (res3?.payload?.success) {
                                setLoading(false)
                            } else {
                                setLoading(false)
                            }
                        })
                    } else {
                        setLoading(false)
                    }
                })
            } else {
                setLoading(false)
            }
        })
    }, [])


    const initialValues = {
        user_name: id ? userData.username : '',
        email: id ? userData.email : '',
        mobile_nu: id ? userData.mobile : '',
        age: id ? userData.age : '',
        image: id ? userData.profilePicture : '',
        addhar_no: '',
        state:  '21',
        city: id ? userData.city : '',
        gender: id ? userData.gender : '',
        payment_mode: '',
        date: '',
        plan: '',
        transection_id: '',
        status: id ? userData.status : ''

    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: id ? updateUserSchema : AddUserSchema,

        onSubmit: (values, action) => {
            {
                id ? dispatch(updateUserTodo(bodyFormData)).then(() => updateUserFun()) :
                    dispatch(addUserTodo(bodyFormData)).then(() => addUserFun())
            }
            setLoading(true)
            id ? <></> : action.resetForm()
        },
    })

    const addUserFun = () => {
        if (addUserRes && addUserRes.success) {

            dispatch(addSubscriptionTodo(
                {
                    "planID": values.plan,
                    "user_id": addUserRes && addUserRes.data[0] && addUserRes.data[0].userid,
                    "patient_id": addUserRes && addUserRes.data[0] && addUserRes.data[0].newPatient && addUserRes.data[0].newPatient.id
                }
            )).then((subs_res) => {
                if (addSubscriptionRes && addSubscriptionRes.success) {
                    dispatch(addPaymentTodo(
                        {
                            "order_id": subs_res && subs_res.payload && subs_res.payload.data && subs_res.payload.data.orderId,
                            "order_date": values.date,
                            "user_id": addUserRes && addUserRes.data[0] && addUserRes.data[0].userid,
                            "patient_id": addUserRes && addUserRes.data[0] && addUserRes.data[0].newPatient && addUserRes.data[0].newPatient.id,
                            "transection_id": values.transection_id,
                            "payment_mode": values.payment_mode,
                        }
                    )).then((paymentRes) => {

                        if (addPaymentRes && addPaymentRes.success) {
                            setLoading(false)
                            toast.success("User Add Successfully!!..", { position: "bottom-right" })
                            setTimeout(() => {
                                navigate('../userlist')
                            }, 1500);
                        } else {
                            toast.error("SOME THING WENT WRONG....!", { position: "bottom-right" })
                            setLoading(false)
                        }
                    })

                } else {
                    setLoading(false)
                    toast.error("SOMETHING WENT WRONG....!", { position: "bottom-right" })
                }
            })
        } else {
            setLoading(false)
            toast.error("SOMETHING WENT WRONG....!", { position: "bottom-right" })
        }
    }

    const updateUserFun = () => {
        if (updateUserRes && updateUserRes.success) {
            setLoading(false)
            toast.success(updateUserRes && updateUserRes.message, { position: "bottom-right" })
            setTimeout(() => {
                navigate('../userlist')
            }, 1500);
        } else {
            setLoading(false)
            toast.error(updateUserRes && updateUserRes.message, { position: "bottom-right" })

        }
    }

    let bodyFormData = new FormData();

    bodyFormData.append("name", values.user_name)
    { id ? <></> : bodyFormData.append("mobile", values.mobile_nu) }
    bodyFormData.append("gender", values.gender)
    bodyFormData.append("age", values.age)
    bodyFormData.append("state", values.state)
    bodyFormData.append("city", values.city)
    { id ? <></> : bodyFormData.append("adhaar_no", values.addhar_no) }
    bodyFormData.append("email", values.email)
    bodyFormData.append("path", "category")
    bodyFormData.append("profilePicture", values.image)
    { id ? bodyFormData.append("id", id) : <></> }
    { id ? bodyFormData.append("status", values.status) : <></> }


    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='master_form_section'>
                            {loading ? <div>
                                <Loader />
                            </div> :
                                <form onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className='col-md-4 mb-3'>
                                            <TextInput
                                                name='user_name'
                                                label={' Name'}
                                                value={values.user_name}
                                                onChange={handleChange}
                                                onBlur={handleBlur} />
                                            {errors.user_name && touched.user_name ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.user_name}</span>) : null}

                                        </div>
                                        <div className='col-md-4 mb-3'>
                                            <EmailInput
                                                name='email'
                                                label={'Email '}
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur} />

                                            {errors.email && touched.email ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.email}</span>) : null}
                                        </div>
                                        <div className='col-md-4 mb-3'>
                                            <label className="form-label label_head">Mobile No.</label>
                                            <input
                                                type="text"
                                                className="form-control input_filed"
                                                name='mobile_nu'
                                                label={'Mobile No.'}
                                                value={values.mobile_nu}
                                                readOnly={id ? true : false}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />

                                            {errors.mobile_nu && touched.mobile_nu ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.mobile_nu}</span>) : null}

                                        </div>
                                        <div className='col-md-4 mb-3'>
                                            <NumberInput
                                                name='age'
                                                label={'Age (In years)'}
                                                type={"date"}
                                                value={values.age}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />

                                            {errors.age && touched.age ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.age}</span>) : null}

                                        </div>

                                        <div className='col-md-4 mb-3'>
                                            <FileInput
                                                name='image'
                                                label={' Image'}
                                                onChange={(e) => setFieldValue("image", e.currentTarget.files[0])}
                                                onBlur={handleBlur}
                                            />
                                            {/* {errors.image && touched.image ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.image}</span>) : null} */}
                                        </div>
                                        {id ? <></> : <div className='col-md-4 mb-3'>
                                            <TextInput
                                                name='addhar_no'
                                                label={'Aadhaar Number.'}
                                                value={values.addhar_no}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.addhar_no && touched.addhar_no ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.addhar_no}</span>) : null}

                                        </div>}
                                        <div className='col-md-4 mb-3'>
                                            <ReactSelectInput
                                                name="state"
                                                options={stateOption}
                                                label={'State'}
                                                value={stateOption && stateOption.find((option) => option.value == values.state)}
                                                onChange={(e) => {
                                                    setFieldValue('state', e.value)

                                                }}
                                                onBlur={handleBlur}
                                            />
                                            {errors.state && touched.state ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.state}</span>) : null}

                                        </div>
                                        <div className='col-md-4 mb-3'>
                                            <ReactSelectInput
                                                name="city"
                                                options={city}
                                                label={'City'}
                                                value={id ? city.find((option) => option.value === userData.city) : values.city}
                                                onChange={(e) => {
                                                    setFieldValue('city', e.value)

                                                }}
                                                onBlur={handleBlur}
                                            />
                                            {errors.city && touched.city ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.city}</span>) : null}

                                        </div>

                                        {
                                            id ? <div className='col-md-4 mb-3'>
                                                {/* <StatusSelect
                                                    name='status'
                                                    label={'Status'}
                                                    value={values.status}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                /> */}
                                                <label className="form-label label_head">Select Status</label>
                                                <select className="form-select input_filed"
                                                    name='status'
                                                    value={values.status}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}>
                                                    <option value=''>Select</option>
                                                    <option value="active">Active</option>
                                                    <option value="inactive">Inactive</option>
                                                </select>
                                                {errors.status && touched.status ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.status}</span>) : null}

                                            </div> : ''
                                        }
                                        <div className='col-md-4 mb-3'>
                                            <RadioInput
                                                name="gender"
                                                label={'Gender'}
                                                value={values.gender}
                                                checkedValue={userData && userData.gender}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.gender && touched.gender ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.gender}</span>) : null}

                                        </div>

                                        {id ? <></> :
                                            <div>
                                                <hr />
                                                <h4 className='text-center' style={{ color: 'grey' }}>PAYMENT FORM</h4>
                                                <div className='row'>
                                                    <div className='col-md-4 mb-3'>
                                                        <label className="form-label label_head"> Payment Mode</label>
                                                        <select className="form-select input_filed"
                                                            name="payment_mode"
                                                            value={values.payment_mode}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}>
                                                            <option value=''>Select</option>
                                                            <option value="online">Online</option>
                                                            <option value="cash">Cash</option>
                                                        </select>
                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <DateTimeInput
                                                            name='date'
                                                            label={'Payment Date '}
                                                            value={values.date}
                                                            type={"date"}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.date && touched.date ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.date}</span>) : null}
                                                    </div>


                                                    <div className='col-md-4 mb-3'>
                                                        <ReactSelectInput
                                                            name="plan"
                                                            options={planOption}
                                                            label={' Select Plan'}
                                                            onChange={(e) => {
                                                                setFieldValue('plan', e.value)
                                                            }}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.plan && touched.plan ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.plan}</span>) : null}

                                                    </div>
                                                    <div className='col-md-4 mb-3'>
                                                        <TextInput
                                                            name='transection_id'
                                                            label={'Transaction Id'}
                                                            value={values.transection_id}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </div>
                                                </div>
                                            </div>}

                                        <div className='col-md-12'>
                                            <AddButton
                                                label={id ? 'Update' : 'Save'} />
                                        </div>
                                    </div>
                                </form>}
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default AddupdateUser;
