import React from 'react';

const DateTimeInput = (props) => {
    return (
        <>
            <label className="form-label label_head">{props.label}</label>
            <input
                type={props.type}
                className='form-control input_filed '
                name={props.name}
                value={props.value}
                min={props.min}
                onChange={props.onChange}
                onBlur={props.onBlur}
            />
        </>
    );
}

export default DateTimeInput;
