import React, { useState } from 'react'
import TextInput from '../../../InputFiled/TextInput'
import StatusSelect from '../../../InputFiled/StatusSelect'
import AddButton from '../../../Button/AddButton'
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { addCourseTodo, addCourseRes, updateCourseTodo, updateCourseRes } from '../../../../redux/slices/Master/Course/addupdateCourse';
import { AddCourseSchema } from './validation';
import { useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import Loader from '../../../Loader/Loader';

export default function CourseForm(props) {

    const { bool, setBool } = props;
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams();
    const { state } = useLocation();
    var courseData = state;

    const initialValues = {
        course: id ? courseData.course : "",
        status: id ? courseData.status : "",
        order: id ? courseData.ordered : '',
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: AddCourseSchema,

        onSubmit: (values, action) => {
            {
                id ? dispatch(updateCourseTodo({ values, id })).then(() => updateCourseFun()) :
                    dispatch(addCourseTodo(values)).then(() => courseAddFun())
            }
            setLoading(true)
            id ? <></> : action.resetForm()
        },
    })

    const courseAddFun = () => {
        if (addCourseRes && addCourseRes.success) {
            setLoading(false)
            toast.success(addCourseRes && addCourseRes.message, { position: "bottom-right" });
            setBool(!bool)
        } else {
            setLoading(false)
            toast.error(addCourseRes && addCourseRes.message, { position: "bottom-right" });
        }
    }
    const updateCourseFun = () => {
        if (updateCourseRes && updateCourseRes.success) {
            setLoading(false)
            toast.success(updateCourseRes && updateCourseRes.message, { position: "bottom-right" })
            setTimeout(() => {
                navigate('/qualification')
            }, 1500);
        } else {
            setLoading(false)
            toast.error(updateCourseRes && updateCourseRes.message, { position: "bottom-right" })

        }
    }


    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='master_form_section'>
                            {loading ?
                                <div className='d-flex justify-content-center mt-5'>
                                    <Loader />
                                </div> :
                                <form onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className='col-md-4 mb-3'>
                                            <TextInput
                                                name='course'
                                                label={'Course Name'}
                                                value={values.course}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.course && touched.course ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.course}</span>) : null}

                                        </div>
                                        <div className='col-md-4 mb-3'>
                                            <TextInput
                                                name='order'
                                                label={'Order No.'}
                                                value={values.order}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.order && touched.order ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.order}</span>) : null}

                                        </div>
                                        <div className='col-md-4 mb-3'>
                                            <StatusSelect
                                                name='status'
                                                label={'Status'}
                                                value={values.status}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            {errors.status && touched.status ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.status}</span>) : null}
                                        </div>
                                        <div className='col-md-12 '>

                                            <AddButton
                                                label={id ? 'Update' : 'Save'} />
                                        </div>
                                    </div>
                                </form>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
