import React, { useState } from 'react'
import DiseaseForm from '../../../component/Form/MasterForm/DiseaseForm/DiseaseForm'
import DiseaseList from './DiseaseList'

export default function AddDisease() {

    const [bool, setBool] = useState(false)

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form_head">
                            <h4>Add Disease</h4>
                            <DiseaseForm bool={bool} setBool={setBool} />
                        </div>
                        <div className='mt-3'>
                            <DiseaseList bool={bool} setBool={setBool} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
