import React from 'react';

const   TextInput = (props) => {
    return (
        <>
            <label className="form-label label_head">{props.label}</label>
            <input
                type="text"
                className="form-control input_filed"
                name={props.name}
                readOnly={props.readOnly}
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
            />
        </>
    );
}

export default TextInput;
