import React from 'react'
import Select from 'react-select'
import moment from 'moment';


export default function TimeInput(props) {


    const generateTimeOptions = () => {
        const timeOptions = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 5) {
                const formattedHour = hour % 12 || 12; // Convert 0 to 12
                const formattedHourWithZero = formattedHour.toString().padStart(2, '0'); // Add leading zero
                const formattedMinute = minute.toString().padStart(2, '0');

                const period = hour < 12 ? 'AM' : 'PM';
                // timeOptions.push(`${formattedHour}:${formattedMinute} ${period}`);
                timeOptions.push(`${formattedHourWithZero}:${formattedMinute} ${period}`);
            }
        }
        return timeOptions;

    };

    const timegenerator = generateTimeOptions();
    // console.log("timegenerator", timegenerator)

    // const currentTime = new Date().toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    // console.log(currentTime)

    var timeOptions = []
    const timeOptionsRender = timegenerator && timegenerator.map((value) => timeOptions.push({

        "value": moment(value, 'hh:mm A').format("HH:mm:ss"),
        "label": value
    }))
    // console.log("timeOptions",timeOptions)

    return (
        <>
            <label className="form-label label_head">{props.label}</label>
            {/* <select
                className="form-control input_filed"
                aria-describedby="addon-wrapping"
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
            >
                {timeOptions.map((time, index) => (
                    <option key={index} value={time}>
                        {time}
                    </option>
                ))}
            </select> */}
            <Select
                ref={props.reference}
                closeMenuOnSelect={true}
                options={timeOptions}
                defaultValue={timeOptions.find((option) => option.value === props.defVal)}
                onChange={props.onChange}
                onBlur={props.onBlur}
                isMulti={false}
                className="react_select "
                classNamePrefix="select"
            />
        </>
    )
}
