import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addCategoryRes;
export const  addCategoryTodo = createAsyncThunk(' addCategory', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_category',
            data: data,
            headers: { "Content-Type": "multipart/form-data" }
        })
        return addCategoryRes = res.data

    } catch (error) {
        return addCategoryRes = error.response.data
    }
})

export var updateCategoryRes;
export const  updateCategoryTodo = createAsyncThunk(' updateCategory', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + `update_category/${data.id}`,
            data: data.bodyFormData,
            headers: { "Content-Type": "multipart/form-data" }
        })
        return updateCategoryRes = res.data

    } catch (error) {
        return updateCategoryRes = error.response.data
    }
})