import React, { useCallback, useMemo, useRef, } from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'

export default function DataTables(props) {

    const gridRef = useRef()

    const defaultColDef = useMemo(() => ({
        flex: 1,
        resizable: true,
    }), [])

    const paginationNumberFormatter = useCallback((params) => {
        return (
            params.value.toLocaleString()
        )
    }, []);

        const onFirstDataRendered = useCallback((params) => {
            gridRef.current.api.sizeColumnsToFit();

        }, []);

        const onGridSizeChanged = useCallback((params) => {
            gridRef.current.api.sizeColumnsToFit();
        }, []);

    const onPageSizeChanged = useCallback(() => {
        var value = document.getElementById('page-size').value;
        gridRef.current.api.paginationSetPageSize(Number(value));
    }, []);

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current.api.setQuickFilter(
            document.getElementById('filter-text-box').value
        );
    }, []);

    const onBtnExport = useCallback(() => {
        // var params = {
        //     columnKeys: ['vdepartment_name', 'vdept_manager_name', 'icount_employee', 'cstatus']
        // };
        gridRef.current.api.exportDataAsCsv();
    }, []);

    const onSelectionChanged = useCallback((params) => {

        const selectedData = gridRef.current.api.getSelectedRows();
        // console.log('Selection updated', selectedData);
        // console.log(props.field)
        // props.value(props.field, props.filterData ? selectedData[0].id : selectedData)

        // if (props && props.addCourseModal) {
        //     // console.log("1", selectedData)
        //     props.value(`content[${document.getElementById('upinput').value}].file`, selectedData && selectedData[0] && selectedData[0].file_path)
        // } else if (props && props.addGroupCheck) {
        //     // console.log("2", selectedData)
        //     props.value(props.field, selectedData)
        // } else if (props && props.addQuizCheck) {
        //     // console.log("3", selectedData)
        //     props.value(props.field, selectedData)
        // } else if (props && props.addLearningTrack) {
        //     //  console.log("4", selectedData)
        //     props.value(props.field, selectedData)
        // } else if (props && props.addTrainingCheck) {
        //     //  console.log("5", selectedData)
        //     props.value(props.field, selectedData)
        // } else if (props && props.editTrainingCheck) {
        //     //  console.log("6", selectedData)
        //     props.value(props.field, selectedData)
        // }

    }, []);

    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <div className='data-table-extensions-filter'>
                    {/* <label htmlFor="filterDataTable" className="icon"></label> */}
                    <input
                        type="text"
                        id="filter-text-box"
                        placeholder="Search"
                        onInput={onFilterTextBoxChanged}
                        className="form-control input_filed"
                    />
                </div>
                <div className="d-flex align-items-center">
                    <label htmlFor="" className="form-label form_label" style={{ marginBottom: 0 }}>Page Size</label>
                    <select className="form-select input_filed ms-2" onChange={onPageSizeChanged} id="page-size" style={{ width: '12vh' }}>
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                    </select>
                    {/* <FaDownload size={25} onClick={onBtnExport} className='ms-4 me-2' style={{ cursor: 'pointer', color: '#56ab2f' }} /> */}
                </div>

            </div>
            <div className="ag-theme-alpine mt-3" style={{ height: props && props.height ? props.height : '70vh', width: '100%', boxShadow: "0px 0px 8px 1px #d0d0d0", borderRadius: '8px' }}>
                <PerfectScrollbar>
                    <AgGridReact
                        ref={gridRef}
                        rowData={props.rowData ? props.rowData : ''}
                        columnDefs={props.columnDefs ? props.columnDefs : ''}
                        defaultColDef={defaultColDef}
                        pagination={true}
                        paginationPageSize={10}
                        paginationNumberFormatter={paginationNumberFormatter}
                        onFirstDataRendered={onFirstDataRendered}
                        onGridSizeChanged={onGridSizeChanged}
                        rowHeight={props.rowHeight ? props.rowHeight : ''}
                        rowSelection={props.check ? props.check : ''}
                        suppressRowClickSelection={props.selection ? true : ''}
                        onSelectionChanged={props.selection ? onSelectionChanged : ''}
                    />
                </PerfectScrollbar>
            </div >
        </>
    )
}
