import React, { useState } from 'react'
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import AddButton from '../../Button/AddButton';
import TextInput from '../../InputFiled/TextInput';
import { forgotPswRes, forgotPswTodo } from '../../../redux/slices/Login/changePassword';
import { useNavigate } from 'react-router-dom';
import { adminContactSchema } from './Validation';

export default function ForgotPsw() {
    const [spiner, setSpiner] = useState(false)
    const role = localStorage.getItem('userRole');
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const initialValues = {
        number: ''
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: adminContactSchema,
        onSubmit: (values, action) => {
            setSpiner(true)
            dispatch(forgotPswTodo(values)).then(() => forgotPswFun())
        },
    })

    const forgotPswFun = () => {
        if (forgotPswRes && forgotPswRes.success) {
            toast.success(forgotPswRes && forgotPswRes.message, { position: 'bottom-right' })
            localStorage.setItem('contactNumber', forgotPswRes.data[0].mobile_number)
            setTimeout(() => {
                navigate('/otp')
            }, 1200);
            setSpiner(false);
        } else {
            toast.error(forgotPswRes && forgotPswRes.message, { position: 'bottom-right' })
            setSpiner(false);
        }
    }
    return (
        <>
            <div className="container">
                <div className=" login-container row d-flexh-100 g-0 justify-content-between">
                    <div className="col-sm-6 align-self-md-center my-auto">
                        <div className='side-logo'>
                            <img src="../Group.png" alt="" className='img-fluid logo-img' />
                        </div>
                    </div>
                    <div className="col-sm-6 align-self-md-center my-auto">
                        <div className='login-details'>
                            <div>
                            <div className='finalLogo_section'>
                                    <img src="../logo2.png" alt="" className='img-fluid finalLogo' />
                                </div>
                                <h2>Forgot<span> Password !</span> </h2>
                                <div className='mt-3'>
                                    <form onSubmit={handleSubmit}>
                                        <TextInput
                                            name='number'
                                            label={'Contact Number'}
                                            value={values.number}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.number && touched.number ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.number}</span>) : null}

                                        <div className='mt-4 d-flex justify-content-center'>
                                            {/* <AddButton label={' Send OTP'} /> */}
                                            <button type='submit' className='btn Login-btn'>
                                            {spiner ? <p style={{ marginBottom: '0px' }}> Sending... <span style={{ color: "white" }} className=" ms-2 spinner-border spinner-border-sm" aria-hidden="true"></span> </p> : "SEND OTP"}
                                        </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
