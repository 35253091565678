import React, { useEffect, useState } from 'react'
import ReactSelectInput from '../../InputFiled/ReactSelectInput'
import DateTimeInput from '../../InputFiled/DateTimeInput'
import TextAreaInput from '../../InputFiled/TextAreaInput'
import AddButton from '../../Button/AddButton'
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import { docterListRes, docterListTodo } from '../../../redux/slices/DoctorSlices/docterList';
import { patientListRes, patientListTodo } from '../../../redux/slices/PatientSlices/PatientList'
import { diseaseListRes, diseaseListTodo } from '../../../redux/slices/Master/Disease/diseaseList'
import { AddBookingSchema } from './validation'
import { addBookingRes, addBookingTodo } from '../../../redux/slices/BookingSlices/addUpdateBooking'
import Loader from '../../Loader/Loader'
import moment from 'moment';
import { docterDetailsTodo } from '../../../redux/slices/DoctorSlices/doctorDetails'

export default function AddUpdateBooking() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { id } = useParams();
  const { state } = useLocation();
  var bookingData = state;

  const doctorData = useSelector(state => state && state.doctorList && state.doctorList.data && state.doctorList.data.data.filter(status => status.status !== "Inactive"))
  const diseaseData = useSelector(state => state && state.diseaseList && state.diseaseList.data && state.diseaseList.data.data.filter(status => status.status !== "Inactive"))
  const patientList = useSelector(state => state && state.patientList && state.patientList.data && state.patientList.data.data)
  const docterDetails = useSelector(state => state && state.docterDetails && state.docterDetails.data && state.docterDetails.data.data[0])

  var doctorOption = []
  var userOption = []
  var diseaseOption = []

  var bookingStartTm1 = moment(docterDetails && docterDetails.ClinicMaster && docterDetails.ClinicMaster.open_time1, "HH:mm:ss").format('hh:mm A')
  var bookingStartTm2 = moment(docterDetails && docterDetails.ClinicMaster && docterDetails.ClinicMaster.open_time2, "HH:mm:ss").format('hh:mm A')
  var bookingEndTm1 = moment(docterDetails && docterDetails.ClinicMaster && docterDetails.ClinicMaster.close_time1, "HH:mm:ss").format('hh:mm A')
  var bookingEndTm2 = moment(docterDetails && docterDetails.ClinicMaster && docterDetails.ClinicMaster.close_time2, "HH:mm:ss").format('hh:mm A')


  const bookingSlot = [
    {
      value: 1,
      label: (docterDetails && docterDetails.ClinicMaster && docterDetails.ClinicMaster.open_time1.length) > 0 ||
        (docterDetails && docterDetails.ClinicMaster && docterDetails.ClinicMaster.close_time1.length) > 0
        ? `${bookingStartTm1} to ${bookingEndTm1}`
        : 'Slot Not Available'
    }
  ];

  if (docterDetails && docterDetails.ClinicMaster && docterDetails.ClinicMaster.open_time2 !== '00:00:00') {
    bookingSlot.push({
      value: 2,
      label: (docterDetails && docterDetails.ClinicMaster && docterDetails.ClinicMaster.open_time2.length) > 0 ||
        (docterDetails && docterDetails.ClinicMaster && docterDetails.ClinicMaster.close_time2.length) > 0
        ? `${bookingStartTm2} to ${bookingEndTm2}`
        : 'Slot Not Available'
    });
  }



  const doctorRender = doctorData && doctorData.map((value) => doctorOption.push({
    "value": value.id,
    "label": value.name,
    'value2': value.ClinicMaster.id
  }))



if (patientList) {
  patientList.forEach(value => {
    userOption.push({
      "value": value.id,
      "label": `${value.name} (${value.User && value.User.username ? value.User.username + ' : ' + value.User.mobile : ''})`,
      'value2': value.fk_user_id
    });
  });
}

  const diseaseRender = diseaseData && diseaseData.map((value) => diseaseOption.push({
    "value": value.id,
    "label": value.name
  }))

  const doctorListFun = () => {
    if (docterListRes && docterListRes.success) {
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const diseaseListFun = () => {
    if (diseaseListRes && diseaseListRes.success) {
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const patientListFun = () => {
    if (patientListRes && patientListRes.success) {
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const clinicListFun = (id) => {
    dispatch(docterDetailsTodo(id))
  }


  useEffect(() => {
    setLoading(true)
    dispatch(docterListTodo()).then(() => doctorListFun())
    dispatch(diseaseListTodo()).then(() => diseaseListFun())
    dispatch(patientListTodo()).then(() => patientListFun())
  }, [])

  const initialValues = {
    doctor_id: "",
    clinic_id: '',
    user_id: "",
    patient_id: '',
    booking_date: "",
    booking_stime: "",
    booking_etime: "",
    slot: '',
    disease_id: "",
    disease_details: "",
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: initialValues,
    validationSchema: AddBookingSchema,

    onSubmit: (values, action) => {
      setLoading(true)
      dispatch(addBookingTodo(values)).then(() => addBookingFun())
      action.resetForm()
    },
  })

  const addBookingFun = () => {
    if (addBookingRes && addBookingRes.success) {
      setLoading(false)
      toast.success(addBookingRes && addBookingRes.message, { position: "bottom-right" })
      setTimeout(() => {
        navigate('../bookinglist')
      }, 1500);
    } else {
      setLoading(false)
      toast.error(addBookingRes && addBookingRes.message, { position: "bottom-right" })

    }
  }

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='master_form_section'>
              {loading ?
                <div>
                  <Loader />
                </div> :
                <form onSubmit={handleSubmit}>
                  <div className='row'>

                    <div className='col-md-4 mb-3'>
                      <ReactSelectInput
                        name="doctor_id"
                        options={doctorOption}
                        label={'Doctor'}
                        value={id ? doctorOption.find((option) => option.value === bookingData.specilization) : values.doctor_id}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue('doctor_id', e.value);
                          setFieldValue('clinic_id', e.value2);
                          clinicListFun(e.value)
                        }}
                      />
                      {errors.doctor_id && touched.doctor_id ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.doctor_id}</span>) : null}
                    </div>

                    <div className='col-md-4 mb-3'>
                      <ReactSelectInput
                        name="user_id"
                        options={userOption}
                        label={'User'}
                        value={id ? userOption.find((option) => option.value === bookingData.specilization) : values.user_id}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue('patient_id', e.value)
                          setFieldValue('user_id', e.value2)
                        }
                        }
                      />
                      {errors.user_id && touched.user_id ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.user_id}</span>) : null}
                    </div>

                    <div className='col-md-4 mb-3'>
                      <DateTimeInput
                        name='booking_date'
                        label={'Booking Date'}
                        type={"date"}
                        min={new Date().toISOString().split('T')[0]}
                        value={values.booking_date}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.booking_date && touched.booking_date ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.booking_date}</span>) : null}
                    </div>

                    <div className='col-md-4 mb-3'>

                      <ReactSelectInput
                        name="slot"
                        options={bookingSlot && bookingSlot}
                        label={'Booking Time'}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue('slot', e.value)
                          setFieldValue('booking_stime', e.label)
                          setFieldValue('booking_etime', e.label)
                        }
                        }
                      />
                      {errors.slot && touched.slot ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.slot}</span>) : null}
                    </div>

                    {/* <div className='col-md-4 mb-3'>
                      <ReactSelectInput
                        name="disease_id"
                        options={diseaseOption}
                        label={'Disease'}
                        value={id ? diseaseOption.find((option) => option.value === bookingData.specilization) : values.disease_id}
                        onBlur={handleBlur}
                        onChange={(e) =>
                          setFieldValue('disease_id', e.value)
                        }
                      />
                      {errors.disease_id && touched.disease_id ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.disease_id}</span>) : null}
                    </div> */}

                    <div className='col-md-4 mb-3'>
                      <TextAreaInput
                        name='disease_details'
                        label={'Disease Details '}
                        value={values.disease_details}
                        onChange={handleChange}
                        onBlur={handleBlur} />
                    </div>

                    <div className='col-md-12 '>
                      <AddButton
                        label={'Save'} />
                    </div>
                  </div>
                </form>}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}
