import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var deleteCourseRes;
export const  deleteCourseTodo = createAsyncThunk(' deleteCourse', async (id) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + "course_delete",
            data: {
                "course_id": id.toString()
            },
            headers: { "Content-Type": "application/json" }
        })
        return deleteCourseRes = res.data

    } catch (error) {
        return deleteCourseRes = error.response.data
    }
})