import React from 'react'
import CourseForm from '../../../component/Form/MasterForm/CourseForm/CourseForm'

export default function UpdateCourse() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form_head">
                            <h4>Update Qualification</h4>
                        </div>
                        <CourseForm />
                    </div>
                </div>
            </div>
        </>
    )
}
