import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import DoctorBookingList from './DoctorBookingList';
import DoctorPatientList from './DoctorPatientList';
import moment from 'moment';


const DocterDetails = () => {

    const { id } = useParams();
    const { state } = useLocation();
    var drData = state
    console.log(drData)

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3">
                        <div className="form_head d-flex justify-content-between align-items-center">
                            <h4> Doctor Details</h4>
                        </div>
                        <div className='profile_sidebar mt-2'>
                            <PerfectScrollbar>
                                <div className='ps-2 pe-2'>
                                    <div className="user_img_section mt-3">
                                        <img src={process.env.REACT_APP_IMAGE_URL + drData.doctorImage} alt="userImg" className='img-fluid user_img' />
                                    </div>
                                    <div className='text-center userInfo'>
                                        <h4>{drData.name}</h4>
                                        <span> ( {drData.mobile} )</span>
                                        <p className='dr_psw'>Default Password : 123456</p>
                                    </div>
                                    <hr />
                                    <div>

                                        <div className='d-flex justify-content-between'>
                                            <h6 className='labelh6'>Gender :</h6>
                                            <h6> {drData.gender}</h6>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <h6 className='labelh6'>Experience :</h6>
                                            <h6> {drData.update_experience} Year</h6>
                                        </div>
                                        <div className=''>
                                            <h6 className='labelh6'>Category :</h6>
                                            <div className='d-flex'>
                                            {drData && drData.degree && drData.categorylist.map((value, index) => <h6 key={index} >  {index == 0 ? '' : <span className='me-1'>,</span>}{value.name}</h6>)}
                                        </div>
                                        </div>
                                        <div className=''>
                                            <h6 className='labelh6'>Speciality :</h6>
                                            <h6> {drData.Speciality.speciality}</h6>
                                        </div>
                                        <div className=''>
                                            <h6 className='labelh6'>Qualification </h6>
                                            <div className='d-flex'>
                                                {drData && drData.degree && drData.degree.map((value, index) => <h6 key={index} >  {index == 0 ? '' : <span className='me-1'>,</span>}{value.course}</h6>)}
                                            </div>

                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <h6 className='labelh6'>Added Date :</h6>
                                            <h6> {new Date(drData.added_date).toLocaleDateString('en-GB')} </h6>
                                        </div>
                                        
                                        <hr />
                                        <h5 className='text-center'>Clinic Details</h5>
                                        <div className=''>
                                            <h6 className='labelh6'>Clinic :</h6>
                                            <h6> {drData && drData.ClinicMaster && drData.ClinicMaster.clinicname}</h6>
                                        </div>
                                        <div className=''>
                                            <h6 className='labelh6'>Contact Person :</h6>
                                            <h6> {drData && drData.ClinicMaster && drData.ClinicMaster.contact_person}</h6>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <h6 className='labelh6'>Contact No :</h6>
                                            <h6> {drData && drData.ClinicMaster && drData.ClinicMaster.phone_no}</h6>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <h6 className='labelh6'> Slot 1 Start Time  :</h6>
                                            <h6>  {moment(drData && drData.ClinicMaster && drData.ClinicMaster.open_time1, "HH:mm:ss").format('hh:mm A')}</h6>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <h6 className='labelh6'> Slot 1 End Time  :</h6>
                                            <h6> {moment(drData && drData.ClinicMaster && drData.ClinicMaster.close_time1, "HH:mm:ss").format('hh:mm A')} </h6>
                                        </div>
                                        {drData && drData.ClinicMaster && drData.ClinicMaster.open_time2 === "00:00:00" ? "" : <div className='d-flex justify-content-between'>
                                            <h6 className='labelh6'> Slot 2 Start Time  :</h6>
                                            <h6>  {moment(drData && drData.ClinicMaster && drData.ClinicMaster.open_time2, "HH:mm:ss").format('hh:mm A')}</h6>
                                        </div>}
                                        {drData && drData.ClinicMaster && drData.ClinicMaster.close_time2 === "00:00:00" ? "" :    <div className='d-flex justify-content-between'>
                                            <h6 className='labelh6'> Slot 2 End Time  :</h6>
                                            <h6>  {moment(drData && drData.ClinicMaster && drData.ClinicMaster.close_time2, "HH:mm:ss").format('hh:mm A')}</h6>
                                        </div>}
                                        <div className='d-flex justify-content-between'>
                                            <h6 className='labelh6'>City :</h6>
                                            <h6> {drData && drData.ClinicMaster && drData.ClinicMaster.city_master && drData.ClinicMaster.city_master.city}</h6>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <h6 className='labelh6'>State :</h6>
                                            <h6> {drData && drData.ClinicMaster && drData.ClinicMaster.state_master.state}</h6>
                                        </div>
                                        <div className=''>
                                            <h6 className='labelh6'>Address :</h6>
                                            <h6> {drData && drData.ClinicMaster && drData.ClinicMaster.address}</h6>
                                        </div>
                                        <div className=''>
                                            <h6 className='labelh6'>Details :</h6>
                                            <h6> {drData && drData.ClinicMaster && drData.ClinicMaster.clinic_detail}</h6>
                                        </div>

                                    </div>
                                </div>
                            </PerfectScrollbar>

                        </div>
                    </div>
                    <div className="col-md-9">
                        <Tabs
                            defaultActiveKey="booking"
                            id="fill-tab-example"
                            className="mb-3 piils_btn"
                            fill
                        >
                            <Tab eventKey="booking" title={<span>Booking List </span>}>
                                <DoctorBookingList />
                            </Tab>
                            <Tab eventKey="patient" title={<span>Patient List </span>}>
                                <DoctorPatientList />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DocterDetails;
