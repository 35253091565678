import React, { useState } from 'react';
import CategoryForm from '../../../component/Form/MasterForm/CategoryForm/CategoryForm';
import CategoryList from './CategoryList';

const AddCategory = () => {
    const [bool, setBool] = useState(false)

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form_head">
                            <h4>Add Category</h4>
                        </div>
                        <CategoryForm bool={bool} setBool={setBool} />
                        <div className='mt-3'>
                            <CategoryList bool={bool} setBool={setBool} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddCategory;
