import React, { useState } from 'react'
import { BiHide, BiShow } from "react-icons/bi";
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { toast,ToastContainer } from 'react-toastify';
import AddButton from '../../Button/AddButton';
import { adminPswUpdateRes, adminPswUpdateTodo } from '../../../redux/slices/Login/changePassword';
import { adminPasswordSchema } from '../ForgotPswPopup/PswYup';
import { useNavigate } from 'react-router-dom';

export default function ChangePswPage() {
    const [spiner, setSpiner] = useState(false)
    const [newPswShow, setNewPswShow] = useState(false);
    const [confirmPswShow, setconfirmPswShow] = useState(false);
    const navigate = useNavigate()
    const role = localStorage.getItem('userRole');
    const doctorID = localStorage.getItem('doctorID');
    const userID = localStorage.getItem('userId');

    const dispatch = useDispatch()

    const togglePassword = () => {
        setNewPswShow(!newPswShow);
    }
    const togglePassword1 = () => {
        setconfirmPswShow(!confirmPswShow);
    }

    const initialValues = {
        // id: ,
        userRole: role && role,
        new_psw: "",
        confirm_psw: ""
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: adminPasswordSchema,
        onSubmit: (values, action) => {
            setSpiner(true)
            dispatch(adminPswUpdateTodo(values)).then(() => pswChange())
        },
    })

    const pswChange = () => {
        if (adminPswUpdateRes && adminPswUpdateRes.success) {
            toast.success(adminPswUpdateRes && adminPswUpdateRes.message, { position: 'bottom-right' })
            setSpiner(false);
            setTimeout(() => {
                navigate('/login')
            }, 1300);
        } else {
            setSpiner(false);
            toast.error(adminPswUpdateRes && adminPswUpdateRes.message, { position: 'bottom-right' })
        }
    }

    return (
        <>
            <div className="container">
                <div className=" login-container row d-flexh-100 g-0 justify-content-between">
                    <div className="col-sm-6 align-self-md-center my-auto">
                        <div className='side-logo'>
                            <img src="../Group.png" alt="" className='img-fluid logo-img' />
                        </div>
                    </div>
                    <div className="col-sm-6 align-self-md-center my-auto">
                        <div className='login-details'>
                            <div>
                            <div className='finalLogo_section'>
                                    <img src="../logo2.png" alt="" className='img-fluid finalLogo' />
                                </div>
                                <h2>Change<span> Password !</span> </h2>
                                <div className='mt-3'>
                                    <form onSubmit={handleSubmit}>
                                        
                                        <div className='mt-2 ps-3 pe-3' style={{ position: 'relative' }}>
                                            <label htmlFor="user_name" className="form-label gh_label">New Password</label>
                                            {newPswShow ? <span className="psw_icon" onClick={togglePassword} ><BiShow size={20} style={{color:"#9d9d9d"}}/></span> : <span className="psw_icon" onClick={togglePassword} ><BiHide size={20} style={{color:"#9d9d9d"}}/></span>}
                                            <input type={newPswShow ? "text" : "password"}
                                                name='new_psw'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.new_psw}
                                                className='form-control input_filed' />
                                            {errors.new_psw && touched.new_psw ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.new_psw}</span>) : null}
                                        </div>

                                        <div className='mt-2 mb-4 ps-3 pe-3' style={{ position: 'relative' }}>
                                            <label htmlFor="user_name" className="form-label gh_label">Conform Password</label>
                                            {confirmPswShow ? <span className="psw_icon" onClick={togglePassword1} ><BiShow size={20} style={{color:"#9d9d9d"}}/></span> : <span className="psw_icon" onClick={togglePassword1} ><BiHide size={20} style={{color:"#9d9d9d"}}/></span>}
                                            <input type={confirmPswShow ? "text" : "password"} onChange={handleChange} onBlur={handleBlur}
                                                value={values.confirm_psw} name='confirm_psw' className='form-control input_filed' />

                                            {errors.confirm_psw && touched.confirm_psw ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.confirm_psw}</span>) : null}
                                        </div>

                                        <div className='mt-3 d-flex justify-content-center'>
                                            {/* <AddButton label={'Update'} /> */}
                                            <button type='submit' className='btn Login-btn'>
                                            {spiner ? <p style={{ marginBottom: '0px' }}> Update... <span style={{ color: "white" }} className=" ms-2 spinner-border spinner-border-sm" aria-hidden="true"></span> </p> : "UPDATE"}
                                        </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
