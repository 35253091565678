import React from 'react';
import AddUpdateDoctor from '../../component/Form/DoctorForm/AddUpdateDoctor';

const UpdateProfile = () => {
    return (
        <>
             <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form_head">
                            <h4>Update Profile</h4>
                        </div>
                        <AddUpdateDoctor />
                    </div>
                </div>
            </div>
        </>
    );
}

export default UpdateProfile;
