import React, { useState, useRef, useEffect } from 'react'
import Loader from '../../../src/component/Loader/Loader'
import DataTables from '../../component/DataTable/DataTable'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import Select from 'react-select'
import { IoRefreshOutline } from "react-icons/io5";
import { RiComputerLine } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BookingCancle from './BookingCancle';
import AddButton from '../../component/Button/AddButton';
import { docterListTodo, docterListRes } from '../../redux/slices/DoctorSlices/docterList';
import { bookingFilterListRes, bookingFilterListTodo } from '../../redux/slices/BookingSlices/bookingFilterList';
import moment from 'moment';

export default function BookingList() {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [bool, setBool] = useState(false)
    const bookingListData = useSelector(state => state && state.bookingFilterList && state.bookingFilterList.data && state.bookingFilterList.data.data)
    const doctorData = useSelector(state => state && state.doctorList && state.doctorList.data && state.doctorList.data.data)
    const docterId = localStorage.getItem('doctorID');
    const role = localStorage.getItem('userRole');
    const [bookingCancle, setBookingCancle] = useState(false)
    const [bookingData, setBookingData] = useState('')
    const [dateRange, setDateRange] = useState(['', '']);
    const [startDate, endDate] = dateRange;

    useEffect(() => {
        setLoading(true)
        dispatch(bookingFilterListTodo()).then(() => bookingListFun())
        dispatch(docterListTodo()).then(() => doctorListFun())
    }, [bool])

    const doctorListFun = () => {
        if (docterListRes && docterListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const handlebookingCancle = (data) => {
        setBookingCancle(true)
        setBookingData(data)
    }

    const bookingListFun = () => {
        if (bookingFilterListRes && bookingFilterListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const booking_details = (params) => {
        navigate(`../booking_details/${params.data.id}`, { state: params && params.data })
    }

    var dateFormat = new Date();
    var options = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
    var time = dateFormat.toLocaleTimeString(undefined, options);
    var date = new Date().toLocaleDateString("en-CA")

    // -----------------BOOKING FILTER CODE-------------------


    var doctorOption = []


    const doctorRender = doctorData && doctorData.map((value) => doctorOption.push({
        "value": value.id,
        "label": value.name
    }))

    const initialValues = {
        doctor_id: "",
        booking_status: ''
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,

        onSubmit: (values, action) => {
            setLoading(true)
            dispatch(bookingFilterListTodo({ values, startDate, endDate })).then(() => bookingListFun())
        },
    })

    const selectRef = useRef();
    const handleReset = () => {
        selectRef && selectRef.current && selectRef.current.clearValue();
        values.booking_status = ""
        setDateRange(['', ''])
        setBool(!bool)
    };

    const columnDefs = [
        {
            headerName: 'S.NO.',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 95,
        },
        {
            headerName: 'DOCTOR ',
            field: 'doctor_detail.name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: 'PATIENT',
            field: 'patient_detail.name',
            filter: 'agTextColumnFilter',
            // cellRenderer: params => params.data.booking_for === 'other' ? <span>{params.data && params.data.patient_detail && params.data.patient_detail.name}</span> : params.data.booking_for === 'self' ? <span>{params.data && params.data.user_detail && params.data.user_detail.username}</span> : <></>,
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 150 ,
        },

        {
            headerName: 'BOOKING ID',
            field: 'booking_id',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: ' DATE',
            field: 'booking_date',
            cellRenderer: params => new Date(params.data.booking_date).toLocaleDateString('en-GB'),
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 135 ,

        },
        {
            headerName: ' TIME',
            field: 'booking_stime',
            filter: 'agTextColumnFilter',
            cellRenderer: params => moment(params.data.booking_stime, "HH:mm:ss").format('hh:mm A'),
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 120 ,

        },
        {
            headerName: ' DISEASE',
            field: 'disease_details.name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 120 ,

        },

        {
            headerName: ' STATUS',
            field: 'booking_status',
            cellRenderer: params => params.value == 'Completed' ? <span style={{ color: "white" }} className='badge text-bg-success'>Completed</span> : params.value == 'Pending' ? <span className='badge text-bg-warning'>Booked</span> : params.value == 'Cancelled' ? <span className='badge text-bg-danger'>Cancelled</span> : '',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true
            maxWidth: 120 ,
        },
        {
            field: 'ACTIONS',
            maxWidth: 120 ,
            pinned: 'right',
            cellRenderer: params => {

                if (role === 'doctor' && params.data.booking_status === 'Pending') {
                    if (new Date(params.data.booking_date).toLocaleDateString('en-CA') > date) {
                        return (
                            <>
                                <span type='button' onClick={() => handlebookingCancle(params.data)} className='badge text-bg-danger'> Cancel</span>
                                <RiComputerLine size={20} className='details ms-3'
                                    onClick={() => booking_details(params)}
                                />
                            </>
                        )
                    } else {
                        if (new Date(params.data.booking_date).toLocaleDateString('en-CA') == date && params.data.booking_stime > time) {
                            return (
                                <>
                                    <span type='button' onClick={() => handlebookingCancle(params.data)} className='badge text-bg-danger'> Cancel</span>
                                    <RiComputerLine size={20} className='details ms-3'
                                        onClick={() => booking_details(params)}
                                    />
                                </>
                            )
                        } else {
                            return <RiComputerLine size={20} className='details ms-3'
                                onClick={() => booking_details(params)}
                            />
                        }
                    }
                } else {
                    return <RiComputerLine size={20} className='details ms-3'
                        onClick={() => booking_details(params)}
                    />
                }
            },
            wrapText: true,
            autoHeight: true,
        }
    ]

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className=" row align-items-center">
                            <div className="col-md-2">
                                <div className="form_head">
                                    <h4> Booking List</h4>
                                </div>
                            </div>

                            <div className=" col-md-10 ">
                                <form onSubmit={handleSubmit}>
                                    <div className=" d-flex justify-content-end align-items-center">
                                        {role === 'doctor' ? "" : <div>
                                            <Select
                                                ref={selectRef}
                                                closeMenuOnSelect={true}
                                                options={doctorOption}
                                                name='doctor_id'
                                                onBlur={handleBlur}
                                                style={{width:"200px"}}
                                                // defaultValue={values.doctor_id}
                                                onChange={(option) =>
                                                    setFieldValue('doctor_id', option && option.value)
                                                }
                                                placeholder='Select Doctor'
                                                isMulti={false}
                                                className="react_select "
                                                classNamePrefix="select"
                                            />
                                        </div>}
                                        <div className="ms-2">
                                            <DatePicker
                                                selectsRange={true}
                                                startDate={startDate}
                                                endDate={endDate}
                                                style={{width:"200px"}}
                                                className="form-control input_filed "
                                                placeholderText='Select Date'
                                                onChange={(update) => {
                                                    setDateRange(update);
                                                }}
                                                isClearable={false}
                                            />

                                        </div>
                                        
                                        <div className="ms-2">
                                            <select className="form-select input_filed"
                                                name='booking_status'
                                                id="status"
                                                style={{width:"200px"}}
                                                value={values.booking_status}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                <option value=''>Select Status</option>
                                                <option value="Pending">Pending</option>
                                                <option value="Cancelled">Cancelled</option>
                                                <option value="Completed">Completed</option>
                                            </select>
                                        </div>
                                      
                                        <div className=" ms-2">
                                            <div className='d-flex align-items-center'>
                                                <AddButton label={'Filter'} />
                                                <span className='ms-2 refresh_btn' onClick={handleReset}><IoRefreshOutline style={{ color: 'white' }} size={25} /></span>
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {
                        loading ?
                            <div className='d-flex justify-content-center mt-5 pt-5'>
                                <Loader />
                            </div>
                            :
                            bookingListData && bookingListData.length ?
                                <div className='mt-3'>
                                    <DataTables
                                        rowData={bookingListData}
                                        columnDefs={columnDefs}
                                    />
                                </div>
                                :
                                <div className='d-flex empty_data_section justify-content-center mt-3'>
                                    <h4>Booking Not Found</h4>
                                </div>}
                </div>
            </div>
            <ToastContainer />
            <BookingCancle bookingCancle={bookingCancle} bool={bool} setBool={setBool} setBookingCancle={setBookingCancle} bookingData={bookingData} />
        </>
    )
}
