import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addSubscriptionRes;
export const addSubscriptionTodo = createAsyncThunk(' addSubscription', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'user_add_subscription',
            data: {

                "plan_id": data && data.planID,
                "user_id": data && data.user_id,
                "patient_id": data && data.patient_id,
            },
            headers: { "Content-Type": "application/json" }
        })
        return addSubscriptionRes = res.data

    } catch (error) {
        return addSubscriptionRes = error.response.data
    }
})