import React, { useState } from 'react'
import SpecialityForm from '../../../component/Form/MasterForm/SpecialityForm/SpecialityForm'
import SpecialityList from '../../../Pages/Masters/Speciality/SpecialityList'

export default function AddSpeciality() {
    const [bool, setBool] = useState(false)
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form_head">
                            <h4>Add Speciality</h4>
                            <SpecialityForm bool={bool} setBool={setBool} />
                        </div>
                        <div className='mt-3'>
                            <SpecialityList bool={bool} setBool={setBool} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
