import React, { useState, useEffect } from 'react'
import DataTables from '../../component/DataTable/DataTable'
import { useDispatch, useSelector } from 'react-redux'
import { docterListRes, docterListTodo } from '../../redux/slices/DoctorSlices/docterList'
import { FiEdit } from "react-icons/fi";
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2'
import { RiDeleteBin6Line, RiComputerLine } from "react-icons/ri";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import Loader from '../../component/Loader/Loader';
import { deleteDoctorTodo, deleteDoctorRes, ActiveDoctorTodo, InactiveDoctorTodo, ActiveDoctorRes, InactiveDoctorRes } from '../../redux/slices/DoctorSlices/deleteDoctor';


export default function DoctorList() {

    const dispatch = useDispatch()
    const [bool, setBool] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const doctorList = useSelector(state => state && state.doctorList && state.doctorList.data && state.doctorList.data.data)

    useEffect(() => {
        setLoading(true)
        dispatch(docterListTodo()).then(() => docterListFun())
    }, [bool])

    const docterListFun = () => {
        if (docterListRes && docterListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }
    const del_doctor = (id) => {

        Swal.fire({
            title: "Doctor Delete",
            text: "Are You Sure Want To Delete This Doctor ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteDoctorTodo(id)).then(() => deleteDoctorFun())
            }
        })
    }

    const deleteDoctorFun = () => {
        if (deleteDoctorRes && deleteDoctorRes.success) {
            setBool(!bool)
            Swal.fire(
                "Doctor",
                'Doctor Deleted Successfully',
                'success'
            )
        } else {
            toast.error(deleteDoctorRes && deleteDoctorRes.message, { position: "bottom-right" })
        }
    }



    const edit_doctor = (params) => {
      
        navigate(`../update_doctor/${params.data.id}`, { state: params && params.data })
    }
    const doctor_details = (params) => {
        navigate(`../doctor_details/${params.data.id}`, { state: params && params.data })
    }

    const docterActive = (id) => {
        Swal.fire({
            title: "Doctor Active",
            text: "Are You Sure Want To Active This Doctor ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Active'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(ActiveDoctorTodo(id)).then(() => ActiveDoctorFun())
            }
        })
    }
    const ActiveDoctorFun = () => {
        if (ActiveDoctorRes && ActiveDoctorRes.success) {
            setBool(!bool)
            Swal.fire(
                "Doctor",
                'Doctor Active Successfully',
                'success'
            )
        } else {
            toast.error(ActiveDoctorRes && ActiveDoctorRes.message, { position: "bottom-right" })
        }
    }

    const docterInactive = (id) => {
        Swal.fire({
            title: "Doctor Inactive",
            text: "Are You Sure Want To Inactive This Doctor ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Inactive'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(InactiveDoctorTodo(id)).then(() => InactiveDoctorFun())
            }
        })
    }
    const InactiveDoctorFun = () => {
        if (InactiveDoctorRes && InactiveDoctorRes.success) {
            setBool(!bool)
            Swal.fire(
                "Doctor",
                'Doctor Inactive Successfully',
                'success'
            )
        } else {
            toast.error(InactiveDoctorRes && InactiveDoctorRes.message, { position: "bottom-right" })
        }
    }

    const columnDefs = [
        {
            headerName: 'S.NO.',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 95,
        },
        {
            headerName: 'DOCTOR NAME',
            field: 'name',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: 'MOBILE NO.',
            field: 'mobile',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 160,

        },
        {
            headerName: 'IMAGE',
            field: 'doctorImage',
            cellRenderer: params => <div className='table_img_sec'>
                <img className=" img-fluid table_data_img" src={process.env.REACT_APP_IMAGE_URL + params.data.doctorImage} alt="image" />
            </div>,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 120,

        },
        {
            headerName: 'FEES',
            field: 'price',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 100,
        },
        {
            headerName: 'QUALIFICATION',
            field: 'degree',
            valueFormatter: function (params) {
                return params.data && params.data.degree && params.data.degree.map(val => `${val.course}`).join(',')
            },
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: 'SPECIALITY',
            field: 'Speciality.speciality',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: 'STATUS',
            field: 'status',
            cellRenderer: params => params.value == 'Active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            maxWidth: 130,
        },
        {
            field: 'ACTIONS',
            cellRenderer: params =>
                <div>

                    {params.data.status === 'Active' ? <AiOutlineEye onClick={() => docterInactive(params.data.id)} size={18} style={{ color: '#404040', cursor: "pointer" }} /> :
                        < AiOutlineEyeInvisible onClick={() => docterActive(params.data.id)} size={20} style={{ color: '#404040', cursor: "pointer" }} />}
                    <FiEdit size={20} className='edit ms-2'
                        style={{ color: '#47BB4C', cursor: "pointer" }}
                        onClick={() => edit_doctor(params)}
             
                    />
                    <RiDeleteBin6Line size={20} className='delete ms-2'
                        style={{ color: '#EF3D50', cursor: "pointer" }}
                        onClick={() => del_doctor(params && params.data && params.data.id)}
                  
                    />
                    <RiComputerLine size={20} className='details ms-2'
                        onClick={() => doctor_details(params)}

                    />

                </div>,
            wrapText: true,
            autoHeight: true,
             pinned: 'right',

        }
    ]


    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form_head">
                            <h4> Doctor List</h4>
                        </div>
                        {
                            loading ?
                                <div className="d-flex justify-content-center">
                                    <Loader />
                                </div> :
                                doctorList && doctorList.length ?
                                    <div className='mt-3'>
                                        <DataTables
                                            rowData={doctorList}
                                            columnDefs={columnDefs}
                                        />
                                    </div> :
                                    <div className='d-flex empty_data_section justify-content-center mt-3'>
                                        <h4>Doctor List Not Found</h4>
                                    </div>}

                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
