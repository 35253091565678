import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var notificationListRes;
export const notificationListTodo = createAsyncThunk('notificationList', async (id) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'custom_notification',
        
            headers: { "Content-Type": "application/json" }
        })
        return notificationListRes = res.data

    } catch (error) {
        return notificationListRes = error.response.data
    }
})

const notificationListSlice = createSlice({
    name: 'notificationList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(notificationListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('equipmentList', state.data.response)
        });
        builder.addCase(notificationListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default notificationListSlice.reducer; 