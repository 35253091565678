import * as Yup from "yup";

export const AddBookingSchema = Yup.object({
    doctor_id: Yup.string().required(" Select Doctor") ,
    user_id: Yup.string().required(" Select User") ,
    // patient_id: Yup.string().required(" Select Patient") ,
    // disease_id: Yup.string().required(" Select Disease") ,
    // booking_for: Yup.string().required(" Select Booking  For"),
    booking_date: Yup.string().required(" Select Booking Date "),
    slot: Yup.string().required(" Select  Booking  Time "),
    // disease_details: Yup.string().required(" Enter Disease Details ").matches(/^[a-zA-Z]/, "Empty Space Not Allow"),

})