import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addDiseaseRes;
export const addDiseaseTodo = createAsyncThunk(' addDisease', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_disease',
            data: {

                "name": data.disease,
                "status": data.status,
                "added_by": localStorage.getItem('userId')
            },
            headers: { "Content-Type": "application/json" }
        })
        return addDiseaseRes = res.data

    } catch (error) {
        return addDiseaseRes = error.response.data
    }
})

export var updateDiseaseRes;
export const  updateDiseaseTodo = createAsyncThunk(' updateDisease', async (data) => {
    console.log("data", data)
    try {
        const res = await axios({ 
            method: "post",
            url: process.env.REACT_APP_API_URL + `update_disease/${data.id}`,
            data: {

                "name": data && data.values.disease,
                "status": data && data.values.status,
                "modified_by": localStorage.getItem('userId')
            },
            headers: { "Content-Type": "application/json" }
        })
        return updateDiseaseRes = res.data

    } catch (error) {
        return updateDiseaseRes = error.response.data
    }
})