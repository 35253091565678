import React from 'react';

const TextAreaInput = (props) => {
    return (
        <>
              <label className="form-label label_head "> {props.label}</label>
            <textarea className="form-control input_filed" placeholder="" rows={1}
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}></textarea>
        </>
    );
}

export default TextAreaInput;
