import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addNotificationRes;
export const  addNotificationTodo = createAsyncThunk(' addNotification', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'multiple_send_notification',
            data: data,
            headers: { "Content-Type": "multipart/form-data" }
        })
        return addNotificationRes = res.data

    } catch (error) {
        return addNotificationRes = error.response.data
    }
})