import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Navbar from './component/Navbar&Sidebar/Navbar';
const useAuth = () => {
    let user = localStorage.getItem('userId');
    if (user) {
        try {
            if (JSON.parse(user)) {
                return user;
            }
        } catch (error) {
            console.log("log1", error)
            return false
        }

    } else {
        console.log("log2", "test")
        return false
    }
}

const ProtectedRoutes = ({ element, allowedRoles, ...rest }) => {
    const auth = useAuth()
    const userRole = localStorage.getItem('userRole');
    if (!auth || !userRole) {
        return <Navigate to="/login" />;
    }
    if (allowedRoles && allowedRoles.includes(userRole)) {
        // Render the protected element with additional props
        return (
            <div>
                <Navbar />
                <div id="content">
                    {/* {React.cloneElement(element, rest)} */}
                    <Outlet />
                </div>
            </div>
        );
    } else {
        // Handle unauthorized access

        return <Navigate to="/dashboard" />;
    }

}




export default ProtectedRoutes;
