import React, { useState } from 'react'
import TextAreaInput from '../../../InputFiled/TextAreaInput';
import { AddBannerSchema, UpdateBannerSchema } from './validation';
import FileInput from '../../../InputFiled/FileInput';
import { useDispatch, useSelector } from 'react-redux';
import AddButton from '../../../Button/AddButton';
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { addBannerRes, addBannerTodo, updateBannerTodo, updateBannerRes } from '../../../../redux/slices/Master/HomeBanner/addupdateBanner';
import Loader from '../../../Loader/Loader';
import TextInput from '../../../InputFiled/TextInput';
import StatusSelect from '../../../InputFiled/StatusSelect';

export default function HomeBannerForm(props) {

    const [loading, setLoading] = useState(false)
    const { bool, setBool } = props;
    const dispatch = useDispatch()
    const { id } = useParams();
    const navigate = useNavigate()
    const { state } = useLocation();
    var bannerData = state;

    const initialValues = {
        bannerDetails: id ? bannerData && bannerData.detail : '',
        image: id ? bannerData && bannerData.bannerImage : '',
        banner_link:id ? bannerData && bannerData.link : '',
        status:id ?  bannerData && bannerData.status : ''
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: id ? UpdateBannerSchema : AddBannerSchema,

        onSubmit: (values, action) => {
            setLoading(true)
            {
                id ? dispatch(updateBannerTodo({bodyFormData,id})).then(()=> updateBannerFun()) :
                dispatch(addBannerTodo(bodyFormData)).then(()=> addBannerFun())
            }
            id ? <></> : action.resetForm()
        },
    })

    let bodyFormData = new FormData();

    bodyFormData.append("detail", values.bannerDetails)
    bodyFormData.append("path", "category") 
    bodyFormData.append("bannerImage", values.image)
    bodyFormData.append("link", values.banner_link)
    bodyFormData.append("status", values.status)
    bodyFormData.append("added_by", localStorage.getItem('userId'))

    const addBannerFun = () => {
        if (addBannerRes && addBannerRes.success) {
            setLoading(false)
            toast.success(addBannerRes && addBannerRes.message, { position: "bottom-right" })
            setBool(!bool)
           
        } else {
            setLoading(false)
            toast.error(addBannerRes && addBannerRes.message, { position: "bottom-right" })

        }
    }

    const updateBannerFun = () => {
        if (updateBannerRes && updateBannerRes.success) {
            setLoading(false)
            toast.success(updateBannerRes && updateBannerRes.message, { position: "bottom-right" })
            setTimeout(() => {
                navigate('../homebanner')
            }, 1500);
        } else {
            setLoading(false)
            toast.error(updateBannerRes && updateBannerRes.message, { position: "bottom-right" })
            
        }
    }

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='master_form_section'>
                        {loading ?
                                <div className='d-flex justify-content-center mt-5'>
                                    <Loader />
                                </div> :
                            <form onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-md-4 mb-3'>
                                        <TextAreaInput
                                            name='bannerDetails'
                                            label={'Banner Details '}
                                            value={values.bannerDetails}
                                            onChange={handleChange}
                                            onBlur={handleBlur} />
                                        {errors.bannerDetails && touched.bannerDetails ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.bannerDetails}</span>) : null}

                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <FileInput
                                            name='image'
                                            label={'Banner Image'}
                                            onChange={(e) => setFieldValue("image", e.currentTarget.files[0])}
                                            onBlur={handleBlur}
                                        />
                                        {errors.image && touched.image ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.image}</span>) : null}
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                            <TextInput
                                                name='banner_link'
                                                label={'Link '}
                                                value={values.banner_link}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />

                                        </div>
                                        <div className='col-md-4 mb-3'>
                                        <StatusSelect
                                            name='status'
                                            label={'Status'}
                                            value={values.status}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        {errors.status && touched.status ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.status}</span>) : null}
                                    </div>

                                    <div className='col-md-12 '>
                                        <AddButton
                                            label={id ? 'Update' : 'Save'} />
                                    </div>
                                </div>
                            </form>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
