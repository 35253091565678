import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var bookingListRes;
export const bookingListTodo = createAsyncThunk('bookingList', async (data) => {
    // console.log("ExerciseId", data)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'booking_filter_list',
           data :{
                "fk_doctor_id":  data.doctorId,
                "startDate":"",
                "fk_user_id":data.userId,
                "endDate": "",
                "booking_stime":""
           },
            headers: { "Content-Type": "application/json" }
        })
        return bookingListRes = res.data

    } catch (error) {
        return bookingListRes = error.response.data
    }
})

const bookingListSlice = createSlice({
    name: 'bookingList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(bookingListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('equipmentList', state.data.response)
        });
        builder.addCase(bookingListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default bookingListSlice.reducer; 