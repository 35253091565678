import * as Yup from "yup";

export const AddDoctorSchema = Yup.object({
    drname: Yup.string().required(" Enter Doctor  Name").matches(/^[a-zA-Z]/, "Empty Space Not Allow"),
    mobile_nu: Yup.string().min(10, 'Must be exactly 10 digits').required("Enter Mobile Number").matches(/^(((0|((\+)?91(\-)?))|((\((\+)?91\)(\-)?)))?[6-9]\d{9})?$/, "Invalid Number"),
    registration_number: Yup.number().positive("Registration number can't start with a minus").integer("Number Should be Integer").required('Registration number is required'),
    experience: Yup.number().min(0, "Experience Can't be less then 0").required('Experience is required'),
    brief_biopic: Yup.string().required(" Enter Bio ").matches(/^[a-zA-Z]/, "Empty Space Not Allow"),
    dr_image: Yup.mixed().required('Image is required'),
    gender: Yup.string().required(" Select gender"),
    degree: Yup.array().required(" Select at least One Degree").min(1, " Select at least One Degree"),
    specialization: Yup.string().required(" Select Specialization"),
    category: Yup.array().required(" Select at least One Category").min(1, " Select at least One Category"),
    clinicname: Yup.string().required(" Enter Clinic Name").matches(/^[a-zA-Z]/, "Empty Space Not Allow"),
    contact_person: Yup.string().required(" Enter Contact Person Name").matches(/^[a-zA-Z]/, "Empty Space Not Allow"),
    appoinment_contact: Yup.string().min(10, 'Must be exactly 10 digits').required(" Enter  Contact Number").matches(/^(((0|((\+)?91(\-)?))|((\((\+)?91\)(\-)?)))?[6-9]\d{9})?$/, "Invalid Number"),
    consultation_fees: Yup.number().min(1, "Fess Can not be start with minus").integer("Number Should be Integer").required('Consultation Fess is required'),
    // startTimeFirst: Yup.string().required(" Enter  start Time "),
    startTimeFirst: Yup.string() .required('Start Time is required'),
    EndTimeFirst: Yup.string().required('End Time is required').test('endTime', 'End Time should be Greater then Start Time', function (value) {
        const { startTimeFirst } = this.parent; // Access the value of startTime
        const currentDate = new Date();
        if (!startTimeFirst) {
            // Handle the case where startTime is not set
            return false;
        }

        // Create Date objects for startTime and endTime
        const startTimeObj = new Date(`${currentDate.toISOString().split('T')[0]} ${startTimeFirst}`);
        const endTimeObj = new Date(`${currentDate.toISOString().split('T')[0]} ${value}`);

        // console.log("yup",startTimeObj)
        // console.log("yup",endTimeObj)

        return endTimeObj > startTimeObj;
    }),
    // startTimesecond: Yup.string(),
    // EndTimesecond: Yup.string().test('endTime', 'End Time should be Greater then Start Time', function (value) {
    //     const { startTimesecond } = this.parent; // Access the value of startTime
    //     const currentDate = new Date();
    //     if (!startTimesecond) {
    //         // Handle the case where startTime is not set
    //         return false;
    //     }

    //     // Create Date objects for startTime and endTime
    //     const startTimeObj = new Date(`${currentDate.toISOString().split('T')[0]} ${startTimesecond}`);
    //     const endTimeObj = new Date(`${currentDate.toISOString().split('T')[0]} ${value}`);
    //     return endTimeObj > startTimeObj;
    // }),
 
    state: Yup.string().required(" Select State"),
    city: Yup.string().required(" Select City"),
    address: Yup.string().required(" Enter Clinic Address ").matches(/^[a-zA-Z0-9!@#$%&()-`.+,/\"]/, "Empty Space Not Allow"),
    // clinic_image: Yup.mixed().required('Image is required'),
    otherinformation: Yup.string().required(" Enter Information ").matches(/^[a-zA-Z]/, "Empty Space Not Allow"),
    latitude: Yup.string().required(" Enter Latitude"),
    longitude: Yup.string().required(" Enter Longitude"),
});

export const UpdateDoctorSchema = Yup.object({
    drname: Yup.string().required(" Enter Doctor  Name").matches(/^[a-zA-Z]/, "Empty Space Not Allow"),
    mobile_nu: Yup.string().min(10, 'Must be exactly 10 digits').required("Enter Mobile Number").matches(/^(((0|((\+)?91(\-)?))|((\((\+)?91\)(\-)?)))?[6-9]\d{9})?$/, "Invalid Number"),
    registration_number: Yup.number().positive("Registration number can't start with a minus").integer("Number Should be Integer").required('Registration number is required'),
    experience: Yup.number().min(0, "Experience Can't be less then 0").required('Experience is required'),
    brief_biopic: Yup.string().required(" Enter Bio ").matches(/^[a-zA-Z]/, "Empty Space Not Allow"),
    // dr_image:  Yup.mixed().required('Image is required'),
    gender: Yup.string().required(" Select gender"),
    degree: Yup.array().required(" Select at least One Degree").min(1, " Select at least One Degree"),
    specialization: Yup.string().required(" Select Specialization"),
    category: Yup.array().required(" Select at least One Category").min(1, " Select at least One Category"),
    clinicname: Yup.string().required(" Enter Clinic Name").matches(/^[a-zA-Z]/, "Empty Space Not Allow"),
    contact_person: Yup.string().required(" Enter Doctor Name Name").matches(/^[a-zA-Z]/, "Empty Space Not Allow"),
    appoinment_contact: Yup.string().min(10, 'Must be exactly 10 digits').required(" Enter  Contact Number").matches(/^(((0|((\+)?91(\-)?))|((\((\+)?91\)(\-)?)))?[6-9]\d{9})?$/, "Invalid Number"),
    consultation_fees: Yup.number().min(1, "Fess Can not be start with minus").integer("Number Should be Integer").required('Consultation Fess is required'),
    startTimeFirst: Yup.string().required('Start Time is required'),
    EndTimeFirst: Yup.string().required('End Time is required').test('endTime', 'End Time should be Greater then Start Time', function (value) {
        const { startTimeFirst } = this.parent; // Access the value of startTime
        const currentDate = new Date();
        if (!startTimeFirst) {
            // Handle the case where startTime is not set
            return false;
        }

        // Create Date objects for startTime and endTime
        const startTimeObj = new Date(`${currentDate.toISOString().split('T')[0]} ${startTimeFirst}`);
        const endTimeObj = new Date(`${currentDate.toISOString().split('T')[0]} ${value}`);

        // console.log("yup",startTimeObj)
        // console.log("yup",endTimeObj)

        return endTimeObj > startTimeObj;
    }),
    // startTimesecond: Yup.string().required(" Enter  start Time "),
    // EndTimesecond: Yup.string().required(" Enter End Time "),
    state: Yup.string().required(" Select State"),
    city: Yup.string().required(" Select City"),
    address: Yup.string().required(" Enter Clinic Address ").matches(/^[a-zA-Z0-9!@#$%&()-`.+,/\"]/, "Empty Space Not Allow"),
    // clinic_image:  Yup.mixed().required('Image is required'),
    otherinformation: Yup.string().required(" Enter Information ").matches(/^[a-zA-Z]/, "Empty Space Not Allow"),
    latitude: Yup.string().required(" Enter Latitude"),
    longitude: Yup.string().required(" Enter Longitude"),
});

