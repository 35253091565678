import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var specialityListRes;
export const specialityListTodo = createAsyncThunk('specialityList', async (id) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'specialization_list',
            headers: { "Content-Type": "application/json" },
            data:{
                'status':'all'
            }
        })
        return specialityListRes = res.data

    } catch (error) {
        return specialityListRes = error.response.data
    }
})

const specialityListSlice = createSlice({
    name: 'specialityList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(specialityListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('equipmentList', state.data.response)
        });
        builder.addCase(specialityListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default specialityListSlice.reducer; 