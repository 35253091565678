import React from 'react';
import { MdDashboard, MdLeaderboard, MdOutlineFlipCameraAndroid , MdNotifications } from "react-icons/md";
import { BiChevronRight, BiClinic } from "react-icons/bi";
import { HiUsers } from "react-icons/hi";
import { FaUserMd, FaClinicMedical, FaUser } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { RiHealthBookFill, RiFileList3Fill } from "react-icons/ri";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { CgProfile } from "react-icons/cg";

const Sidebar = () => {

    const adminAccess = localStorage.getItem('userRole');
    // console.log("adminAccess", adminAccess)

    return (
        <>

            <div id="dashboard-sidebar">
                <div className=" profile-header ">
                    <div className='text-center sidebar-logo-section '>
                        {adminAccess === "admin" ? <img src="../profile.png" alt="img" className='img-fluid user-img' /> : <img src={process.env.REACT_APP_IMAGE_URL + localStorage.getItem('drProfile')} alt="img" className='img-fluid user-img' />}
                        <h5>{localStorage.getItem('userName')}</h5>
                    </div>
                </div>

                <div className="offcanvas-body profile-sidebar-body ">
                    <PerfectScrollbar>
                        <div className="accordion accordion-flush" id="accordionFlushExample">
                            <div className="accordion-item new-accordion">
                                <Link to="../dashboard"> <button className=" collapsed acc-btn " type="button" >
                                    <MdDashboard className='mb-1 me-2' size={20} /> Dashboard
                                </button></Link>
                            </div>

                            {adminAccess === "admin" ? <div className="accordion-item new-accordion">
                                <button className=" collapsed acc-btn" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo" >
                                    <MdLeaderboard className='mb-1 me-2' size={20} /> Master
                                </button>
                                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <ul className='acc-list' >
                                            <Link to="../category">   <li ><BiChevronRight className='mb-1 me-0' size={20} />  Category</li> </Link>
                                            {/* <Link to="../department">  <li ><BiChevronRight className='mb-1 me-0' size={20} />  Department </li></Link> */}
                                            {/* <Link to='../Education'>   <li ><BiChevronRight className='mb-1 me-0' size={20} />  Education </li></Link> */}
                                            <Link to='../speciality'> <li ><BiChevronRight className='mb-1 me-0' size={20} />  Speciality </li></Link>
                                            <Link to='/qualification'> <li ><BiChevronRight className='mb-1 me-0' size={20} />  Qualification </li></Link>
                                            {/* <Link to='../language'> <li ><BiChevronRight className='mb-1 me-0' size={20} />  Language </li></Link> */}
                                            <Link to='../reason'> <li ><BiChevronRight className='mb-1 me-0' size={20} />  Reason </li></Link>
                                            <Link to='../disease'> <li ><BiChevronRight className='mb-1 me-0' size={20} />  Disease </li></Link>
                                            <Link to='../homebanner'> <li ><BiChevronRight className='mb-1 me-0' size={20} />  Home Banner </li></Link>
                                            <Link to='../update_subscription'> <li ><BiChevronRight className='mb-1 me-0' size={20} /> Update Plan </li></Link>
                                            {/* <Link to='../Notification'> <li ><BiChevronRight className='mb-1 me-0' size={20} />  Notification </li></Link> */}

                                        </ul>
                                    </div>
                                </div>
                            </div> : <></>}
                            <div className="accordion-item new-accordion">
                                <button className=" collapsed acc-btn" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree" >
                                    <RiHealthBookFill className='mb-1 me-2' size={20} /> Booking
                                </button>
                                <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <ul className='acc-list' >
                                            {adminAccess === "admin" ?
                                                <Link to='../addbooking'>
                                                    <li ><BiChevronRight className='mb-1 me-0' size={20} /> Add Booking</li>
                                                </Link> : <></>}
                                            <Link to='../bookinglist'>  <li ><BiChevronRight className='mb-1 me-0' size={20} />  Booking List </li></Link>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {adminAccess === "admin" ? <div className="accordion-item new-accordion">
                                <button className=" collapsed acc-btn" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour" >
                                    <FaUserMd className='mb-1 me-2' size={20} /> Doctor
                                </button>
                                <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <ul className='acc-list' >
                                            <Link to="/add_doctor"><li ><BiChevronRight className='mb-1 me-0' size={20} />  Add Doctor</li></Link>
                                            <Link to='/doctorList'>  <li ><BiChevronRight className='mb-1 me-0' size={20} />  Doctor List </li></Link>

                                        </ul>
                                    </div>
                                </div>
                            </div> : <></>}
                            <div className="accordion-item new-accordion">
                                <button className=" collapsed acc-btn" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix" >
                                    <HiUsers className='mb-1 me-2' size={20} /> Patient
                                </button>
                                <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <ul className='acc-list' >
                                            <Link to='/patientlist'>
                                                <li ><BiChevronRight className='mb-1 me-0' size={20} />  Patient List </li>
                                            </Link>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {adminAccess === "admin" ? <></> : <div className="accordion-item new-accordion">
                                <Link to="../update_profile"> <button className=" collapsed acc-btn " type="button" >
                                    <CgProfile className='mb-1 me-2' size={20} /> Update Profile
                                </button></Link>
                            </div>}
                         
                            {adminAccess === "admin" ? <div className="accordion-item new-accordion">
                                <button className=" collapsed acc-btn" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight" >
                                    <FaUser className='mb-1 me-2' size={18} /> User
                                </button>
                                <div id="flush-collapseEight" className="accordion-collapse collapse" aria-labelledby="flush-collapseEight" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        <ul className='acc-list' >
                                            {/* <Link to="/"><li ><BiChevronRight className='mb-1 me-0' size={20} />  Add payment</li></Link> */}
                                            <Link to='/adduser'>
                                                <li ><BiChevronRight className='mb-1 me-0' size={20} />  Add User </li>
                                            </Link>
                                            <Link to='/userlist'>
                                                <li ><BiChevronRight className='mb-1 me-0' size={20} />   User List </li>
                                            </Link>

                                        </ul>
                                    </div>
                                </div>
                            </div> : <></>}
                            <div className="accordion-item new-accordion">
                                <Link to='/notification'> <button className=" collapsed acc-btn" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine" >
                                    <MdNotifications className='mb-1 me-2' size={20} /> Notification
                                </button></Link>
                            
                            </div>
                            <div className="accordion-item new-accordion">
                                <Link to='/update-version'> <button className=" collapsed acc-btn" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine" >
                                    <MdOutlineFlipCameraAndroid  className='mb-1 me-2' size={20} /> Update Version
                                </button></Link>
                            
                            </div>
                        </div>
                    </PerfectScrollbar>
                </div>


            </div>
        </>
    );
}

export default Sidebar;
