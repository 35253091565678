import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addSpecialityRes;
export const addSpecialityTodo = createAsyncThunk(' addSpeciality', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_speciality',
            data: {

                "speciality": data.speciality,
                "status": data.status,
                "user_id": localStorage.getItem('userId')
            },
            headers: { "Content-Type": "application/json" }
        })
        return addSpecialityRes = res.data

    } catch (error) {
        return addSpecialityRes = error.response.data
    }
})

export var updateSpecialityRes;
export const  updateSpecialityTodo = createAsyncThunk(' updateSpeciality', async (data) => {
    try {
        const res = await axios({ 
            method: "post",
            url: process.env.REACT_APP_API_URL + `speciality_update/${data.id}`,
            data: {

                "speciality": data && data.values.speciality,
                "status": data && data.values.status,
                "user_id": localStorage.getItem('userId')
            },
            headers: { "Content-Type": "application/json" }
        })
        return updateSpecialityRes = res.data

    } catch (error) {
        return updateSpecialityRes = error.response.data
    }
})