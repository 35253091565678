import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addCourseRes;
export const addCourseTodo = createAsyncThunk(' addCourse', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_course',
            data: {

                "course": data.course,
                "status": data.status,
                "ordered":data.order,
                "user_id": localStorage.getItem('userId')
            },
            headers: { "Content-Type": "application/json" }
        })
        return addCourseRes = res.data

    } catch (error) {
        return addCourseRes = error.response.data
    }
})

export var updateCourseRes;
export const  updateCourseTodo = createAsyncThunk(' updateCourse', async (data) => {
    try {
        const res = await axios({ 
            method: "post",
            url: process.env.REACT_APP_API_URL + `course_update/${data.id}`,
            data: {

                "course": data && data.values.course,
                "status": data && data.values.status,
                "ordered":data && data.values.order,
                "user_id": localStorage.getItem('userId')
            },
            headers: { "Content-Type": "application/json" }
        })
        return updateCourseRes = res.data

    } catch (error) {
        return updateCourseRes = error.response.data
    }
})