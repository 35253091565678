import React from 'react'
import { ThreeDots } from 'react-loader-spinner'

export default function Loader() {
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='d-flex justify-content-center'>
                            <ThreeDots
                                height="90"
                                width="90"
                                radius="9"
                                color="rgb(34, 34, 34)"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
