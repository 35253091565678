import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var deleteDoctorRes;
export const  deleteDoctorTodo = createAsyncThunk(' deleteDoctor', async (id) => {
    // console.log("doctorData", id)
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + `doctor_delete/${id}`,
            headers: { "Content-Type": "application/json" }
        })
        return deleteDoctorRes = res.data

    } catch (error) {
        return deleteDoctorRes = error.response.data
    }
})

export var InactiveDoctorRes;
export const  InactiveDoctorTodo = createAsyncThunk(' InactiveDoctor', async (id) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'doctor_status',
            headers: { "Content-Type": "application/json" },
            data:{
                "id":id,
                "status":'Inactive'
            }
        })
        return InactiveDoctorRes = res.data

    } catch (error) {
        return InactiveDoctorRes = error.response.data
    }
})
export var ActiveDoctorRes;
export const  ActiveDoctorTodo = createAsyncThunk(' ActiveDoctor', async (id) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'doctor_status',
            headers: { "Content-Type": "application/json" },
            data:{
                "id":id,
                "status":'Active'
            }
        })
        return ActiveDoctorRes = res.data

    } catch (error) {
        return ActiveDoctorRes = error.response.data
    }
})