import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import DataTables from '../../component/DataTable/DataTable';
import { useNavigate } from 'react-router-dom';
import { RiComputerLine } from "react-icons/ri";


export default function NotificationView() {
    const state = useLocation();
    const navigate = useNavigate()
    const notification = state && state.state
    const userList = notification.CustomNotificationDetails

    const columnDefs = [
        {
            headerName: 'S.NO.',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 100,
        },
        {
            headerName: ' NAME',
            field: 'User.username',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: 'MOBILE NO.',
            field: 'User.mobile',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: 'DATE',
            field: 'status',
            cellRenderer: params => new Date(params && params.data && params.data.added_date).toLocaleDateString('en-GB'),
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true
            // maxWidth: 100,
          },
        {
            headerName: 'STATUS',
            field: 'status',
            cellRenderer: params => params.value == 'success' ? <span className='badge text-bg-success'>Success</span> : <span className='badge text-bg-danger'>Failed</span>,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            // wrapText: true,
            // autoHeight: true
        },
        
    ]

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="cold-md-12">
                        <div className="form_head ">
                            <h4> Notification Details</h4>
                        </div>
                    
                                <div>
                                    <div className=' mt-3 notification_view'>
                                        <p >{notification.title}</p>
                                        <span >{notification.description}</span>
                                    </div>
                                    <div className='mt-3'>
                                        <DataTables
                                            rowData={userList}
                                            columnDefs={columnDefs}
                                            height={'62vh'}
                                        />
                                    </div>
                                </div>
                    </div>
                </div>
            </div>
        </>
    )
}
