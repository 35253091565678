import React from 'react'
import HomeBannerForm from '../../../component/Form/MasterForm/HomeBanner/HomeBannerForm'

export default function UpdateBanner() {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="form_head">
              <h4>Update HomeBanner</h4>
            </div>
            <HomeBannerForm />
          </div>
        </div>
      </div>
    </>
  )
}
