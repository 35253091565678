import React, { useState } from 'react'
import HomeBannerForm from '../../../component/Form/MasterForm/HomeBanner/HomeBannerForm'
import BannerList from './BannerList'

export default function AddBanner() {
    const [bool, setBool] = useState(false)

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form_head">
                            <h4>Add HomeBanner</h4>
                        </div>
                        <HomeBannerForm bool={bool} setBool={setBool} />
                        <div className='mt-3'>
                            <BannerList bool={bool} setBool={setBool} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
