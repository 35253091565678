import React, { useState, useEffect } from 'react'
import DataTables from '../../../component/DataTable/DataTable'
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { specialityListTodo, specialityListRes } from '../../../redux/slices/Master/Speciality/specialitylist';
import Swal from 'sweetalert2'
import Loader from '../../../component/Loader/Loader';
import { deleteSpecialityRes, deleteSpecialityTodo } from '../../../redux/slices/Master/Speciality/deleteSpeciality';


export default function SpecialityList(props) {
    const { bool, setBool } = props;
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const specialityList = useSelector(state => state && state.specialityList && state.specialityList.data && state.specialityList.data.data)

    const edit_speciality = (params) => {
        navigate(`../update_speciality/${params.data.id}`, { state: params && params.data })
    }

    const del_speciality = (id) => {
        Swal.fire({
            title: "Speciality Delete",
            text: "Are You Sure Want To Delete This Speciality ?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#121136',
            cancelButtonColor: '#EF3D50',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteSpecialityTodo(id)).then(() => deleteSpecialityFun())
            }
        })
    }

    const deleteSpecialityFun = () => {
        if (deleteSpecialityRes && deleteSpecialityRes.success) {
            setBool(!bool)
            Swal.fire(
                "Speciality",
                deleteSpecialityRes && deleteSpecialityRes.message,
                'success'
            )
        } else {
            Swal.fire({
                title:  (deleteSpecialityRes && deleteSpecialityRes.data && deleteSpecialityRes.data.count) ? deleteSpecialityRes.data.count.toString() : "",
                text: (deleteSpecialityRes && deleteSpecialityRes.message) ? deleteSpecialityRes.message.toString() : "",
                icon: 'warning', // This should be one of 'success', 'error', 'warning', 'info', or 'question'
                customClass: {
                  icon: 'warning'
                }
              });
        }
    }

    useEffect(() => {
        setLoading(true)
        dispatch(specialityListTodo()).then(() => specialityListFun())
    }, [bool])

    const specialityListFun = () => {
        if (specialityListRes && specialityListRes.success) {
            setLoading(false)
        } else {
            setLoading(false)

        }
    }

    const columnDefs = [
        {
            headerName: 'S.NO.',
            valueGetter: 'node.rowIndex + 1',
            filter: 'false',
            suppressMenu: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
            maxWidth: 100,
        },
        {
            headerName: 'SPECIALITY NAME',
            field: 'speciality',
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,
            wrapText: true,
            autoHeight: true,
        },

        {
            headerName: 'STATUS',
            field: 'status',
            cellRenderer: params => params.value == 'Active' ? <span className='badge text-bg-success'>Active</span> : <span className='badge text-bg-danger'>Inactive</span>,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            filter: true,
            sortable: true,
            floatingFilter: false,

        },
        {
            field: 'ACTIONS',
            cellRenderer: params =>
                <div>
                    <FiEdit size={20} className='edit' style={{ color: '#47BB4C', cursor: "pointer" }} onClick={() => edit_speciality(params)} />
                    <RiDeleteBin6Line size={20} className='delete ms-3' style={{ color: '#EF3D50', cursor: "pointer" }} onClick={() => del_speciality(params && params.data && params.data.id)} />

                </div>,
            wrapText: true,
            autoHeight: true,
        }
    ]

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form_head">
                            <h4> Speciality List</h4>
                        </div>
                    </div>
                    {
                        loading ?
                            <div className='d-flex justify-content-center mt-5 pt-5'>
                                <Loader />
                            </div>
                            :
                            specialityList && specialityList.length ?
                                <div className='mt-3'>
                                    <DataTables
                                        rowData={specialityList}
                                        columnDefs={columnDefs} />
                                </div>
                                :
                                <div className='d-flex empty_data_section justify-content-center mt-3'>
                                    <h4>Speciality List Not Found</h4>
                                </div>}
                </div>
            </div>
        </>
    )
}
