import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var bannerListRes;
export const bannerListTodo = createAsyncThunk('bannerList', async (id) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'homebanner_list',
           
            headers: { "Content-Type": "application/json" }
        })
        return bannerListRes = res.data

    } catch (error) {
        return bannerListRes = error.response.data
    }
})

const bannerListSlice = createSlice({
    name: 'bannerList',
    initialState: {
        data: null,
    },
    extraReducers: (builder) => {
        builder.addCase(bannerListTodo.fulfilled, (state, action) => {
            state.data = action.payload;
            // console.log('equipmentList', state.data.response)
        });
        builder.addCase(bannerListTodo.rejected, (state, action) => {
            // console.log('token_error', action)
        })
    }
});

export default bannerListSlice.reducer; 