import React from 'react'
import DiseaseForm from '../../../component/Form/MasterForm/DiseaseForm/DiseaseForm'

export default function UpdateDisease() {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form_head">
                            <h4>Update Disease</h4>
                        </div>
                        <DiseaseForm />
                    </div>
                </div>
            </div>
        </>
    )
}
