import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var addBannerRes;
export const  addBannerTodo = createAsyncThunk(' addBanner', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + 'add_homebanner',
            data: data,
            headers: { "Content-Type": "multipart/form-data" }
        })
        return addBannerRes = res.data

    } catch (error) {
        return addBannerRes = error.response.data
    }
})

export var updateBannerRes;
export const  updateBannerTodo = createAsyncThunk(' updateBanner', async (data) => {
    try {
        const res = await axios({
            method: "post",
            url: process.env.REACT_APP_API_URL + `update_homebanner/${data.id}`,
            data: data.bodyFormData,
            headers: { "Content-Type": "multipart/form-data" }
        })
        return updateBannerRes = res.data

    } catch (error) {
        return updateBannerRes = error.response.data
    }
})