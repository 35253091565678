import React, { useEffect } from 'react'
import ReactSelectInput from '../../component/InputFiled/ReactSelectInput';
import AddButton from '../../component/Button/AddButton';
import { MdCancel } from "react-icons/md";
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { reasonListTodo } from '../../redux/slices/Master/Reason/ReasonList';
import { bookingCancleRes, bookingCancleTodo } from '../../redux/slices/BookingSlices/cancleBooking';
import { BookingCancleSchema } from './Validation';

export default function BookingCancle(props) {
    const { bookingCancle, setBookingCancle } = props;
    const { bool, setBool } = props;
    var bookingID = props.bookingData.booking_id
    const reasonListData = useSelector(state => state && state.reasonList && state.reasonList.data && state.reasonList.data.data)

    const role = localStorage.getItem('userRole');

    var reasonOption = []
    const reasonRender = reasonListData && reasonListData.map((value) => reasonOption.push({
        "value": value.id,
        "label": value.reason
    }))

    const dispatch = useDispatch()

    const initialValues = {
        reason_id: '',
    }

    useEffect(() => {
        dispatch(reasonListTodo(role))
    }, [])


    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: BookingCancleSchema,
        
        onSubmit: (values, action) => {
            dispatch(bookingCancleTodo({values,bookingID})).then(() => bookingCancleFun())
        }
    })

    const bookingCancleFun = () => {
        if (bookingCancleRes && bookingCancleRes.success) {
            setBool(!bool)
            setBookingCancle(false)
            toast.success(bookingCancleRes && bookingCancleRes.message, { position: "bottom-right" })
        } else {
            toast.error(bookingCancleRes && bookingCancleRes.message, { position: "bottom-right" })
        }
    }

    return (
        <>
            <div className={bookingCancle ? 'modal fade show d-block' : 'modal fade show d-none'} style={{ background: 'rgba(0,0,0,0.8)' }}>
                <div className="modal-dialog modal-dialog-centered modal-md  ">
                    <div className="modal-content pb-5" >
                        <div className="modal-header  "  >
                            <h5 className='text-center ' > Booking Cancel</h5>
                            <span onClick={() => { setBookingCancle(false); }}><MdCancel style={{ color: 'red' }} size={25} className="mb-2" /></span>
                        </div>
                        <div className='modal-body  p-4 '>
                            <form onSubmit={handleSubmit}>

                                <ReactSelectInput
                                    name="reason_id"
                                    options={reasonOption}
                                    label={'Reason'}
                                    value={values.reason_id}
                                    onChange={(e) =>
                                        setFieldValue('reason_id', e.value)
                                    }
                                    onBlur={handleBlur}
                                />
                                {errors.reason_id && touched.reason_id ? (<span className='text-danger ' style={{ fontSize: "15px" }} >{errors.reason_id}</span>) : null}
                               
                                <div className='text-end mt-2'>
                                    <AddButton
                                        label={'Save'} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}
