import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../component/Loader/Loader';
import { bookingDetailsRes, bookingDetailsTodo } from '../../redux/slices/BookingSlices/BookingDetails';

export default function BookingDetails() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const bookingData = useSelector(state => state && state.BookingDetails && state.BookingDetails.data && state.BookingDetails.data.data && state.BookingDetails.data.data[0])
    const { id } = useParams();
    const { state } = useLocation();
    console.log("bookingData", bookingData)
    var timeString = bookingData && bookingData.booking_stime
    var momentTime = moment(timeString, "HH:mm:ss");
    var formattedTime = momentTime.format('hh:mm A');


    useEffect(() => {
        setLoading(true)
        dispatch(bookingDetailsTodo(id)).then(() => bookingDetails_fun())
    }, [])

    const bookingDetails_fun = () => {
        if (bookingDetailsRes && bookingDetailsRes.success) {
            setLoading(false)
        } else {
            setLoading(false)
        }
    }


    return (
        <>
            <div className="container-fluid">
                <div className="form_head ">
                    <h4> Booking Details</h4>
                </div>
                {loading ?
                    <div className='d-flex justify-content-center mt-5 pt-5'>
                        <Loader />
                    </div>
                    :
                    <div className="row">
                        <div className="col-md-3">
                            <div className='profile_sidebar mt-2'>
                                <PerfectScrollbar>
                                    <div className='ps-3 pe-3'>
                                        <div className='booking_details'>
                                            <h5 className='text-center'>Docter Details</h5>
                                            <div className='d-flex justify-content-between'>
                                                <p>Doctor</p>
                                                <span>{bookingData && bookingData.doctor_detail && bookingData.doctor_detail.name}</span>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p>Contact No</p>
                                                <span>{bookingData && bookingData.doctor_detail && bookingData.doctor_detail.mobile}</span>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p>Experience</p>
                                                <span>{bookingData && bookingData.doctor_detail && bookingData.doctor_detail.experience} Year</span>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p>Specilization</p>
                                                <span>{bookingData && bookingData.doctor_detail && bookingData.doctor_detail.Speciality && bookingData.doctor_detail.Speciality.speciality} </span>
                                            </div>
                                            <div className=''>
                                                <p>Qualification</p>
                                                <div className='d-flex'>
                                                    {bookingData && bookingData.degree && bookingData.degree.map((value, index) => <span key={index} >  {index == 0 ? '' : <span className='me-1'>,</span>}{value.course}</span>)}
                                                </div>
                                            </div>
                                            <hr />
                                            <h5 className='text-center '>Clinic Details</h5>

                                            <div className=''>
                                                <p>Clinic</p>
                                                <span>{bookingData && bookingData.doctor_detail && bookingData.doctor_detail.ClinicMaster && bookingData.doctor_detail.ClinicMaster.clinicname}</span>
                                            </div>
                                            < div className=''>
                                                <p>Contact Person </p>
                                                <span> {bookingData && bookingData.doctor_detail && bookingData.doctor_detail.ClinicMaster && bookingData.doctor_detail.ClinicMaster.contact_person}</span>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p>Contact No </p>
                                                <span> {bookingData && bookingData.doctor_detail && bookingData.doctor_detail.ClinicMaster && bookingData.doctor_detail.ClinicMaster.phone_no}</span>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p>City </p>
                                                <span>{bookingData && bookingData.doctor_detail && bookingData.doctor_detail.ClinicMaster && bookingData.doctor_detail.ClinicMaster.city_master && bookingData.doctor_detail.ClinicMaster.city_master.city}</span>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p>State </p>
                                                <span> {bookingData && bookingData.doctor_detail && bookingData.doctor_detail.ClinicMaster && bookingData.doctor_detail.ClinicMaster.state_master && bookingData.doctor_detail.ClinicMaster.state_master.state}</span>
                                            </div>
                                            <div className=''>
                                                <p>Address </p>
                                                <span> {bookingData && bookingData.doctor_detail && bookingData.doctor_detail.ClinicMaster && bookingData.doctor_detail.ClinicMaster.address}</span>
                                            </div>
                                            <hr />
                                            <h5 className='text-center '>User Details</h5>

                                            <div className='d-flex justify-content-between'>
                                                <p>User</p>
                                                <span>{bookingData && bookingData.user_detail && bookingData.user_detail.username}</span>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p>Contact No</p>
                                                <span>{bookingData && bookingData.user_detail && bookingData.user_detail.mobile}</span>
                                            </div>
                                            {/* <div className='d-flex justify-content-between'>
                                                <p>DOB</p>
                                                <span>{new Date(bookingData && bookingData.user_detail && bookingData.user_detail.dob).toLocaleDateString('en-GB')}</span>
                                            </div> */}
                                            <div className='d-flex justify-content-between'>
                                                <p>Gender</p>
                                                <span>{bookingData && bookingData.user_detail && bookingData.user_detail.gender}</span>
                                            </div>
                                            <div className=''>
                                                <p>Email</p>
                                                <span>{bookingData && bookingData.user_detail && bookingData.user_detail.email}</span>
                                            </div>
                                            <hr />
                                            <h5 className='text-center '>Patient Details</h5>

                                            <div className='d-flex justify-content-between'>
                                                <p>Patient</p>
                                                <span>{bookingData && bookingData.patient_detail && bookingData.patient_detail.name}</span>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p>Contact No</p>
                                                <span>{bookingData && bookingData.patient_detail && bookingData.patient_detail.phone}</span>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p>DOB</p>
                                                <span>{new Date(bookingData && bookingData.patient_detail && bookingData.patient_detail.dob).toLocaleDateString('en-GB')}</span>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p>Gender</p>
                                                <span>{bookingData && bookingData.patient_detail && bookingData.patient_detail.gender}</span>
                                            </div>
                                            <div className=''>
                                                <p>Email</p>
                                                <span>{bookingData && bookingData.patient_detail && bookingData.patient_detail.email}</span>
                                            </div>
                                        </div>
                                    </div>
                                </PerfectScrollbar>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className='profile_sidebar mt-2'>
                                <PerfectScrollbar>
                                    <div className='ps-3 pe-3'>
                                        <div className="row row-flex me-1 ms-1">
                                            <div className="col-md-4 mt-2">
                                                <div className='booking_details_2 content text-center aling-items-center'>
                                                    <p>Booking ID</p>
                                                    <span>{bookingData && bookingData.booking_id && bookingData.booking_id}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mt-2">
                                                <div className='booking_details_2 content text-center aling-items-center'>
                                                    <p>Booking Date</p>
                                                    <span>{new Date(bookingData && bookingData.booking_id && bookingData.booking_date).toLocaleDateString('en-GB')}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mt-2">
                                                <div className='booking_details_2 content text-center aling-items-center'>
                                                    <p>Booking Time </p>
                                                    <span>{formattedTime}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mt-2">
                                                <div className='booking_details_2 content text-center aling-items-center'>
                                                    <p>Booking Status </p>
                                                    <span >{bookingData &&  bookingData.booking_status === 'Pending' ? "Booked" : bookingData &&  bookingData.booking_status}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mt-2">
                                                <div className='booking_details_2 content text-center aling-items-center'>
                                                    <p>Disease </p>
                                                    <span style={{ textTransform: 'capitalize' }}>{bookingData && bookingData.disease_details && bookingData.disease_details.name}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4 mt-2 ">
                                                <div className='booking_details_2 content text-center aling-items-center'>
                                                    <p>Disease Detail</p>
                                                    {bookingData && bookingData.disease_detail === "" || bookingData && bookingData.disease_detail === null ? '-' : <span style={{ textTransform: 'capitalize' }}>{bookingData && bookingData.disease_detail}</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </PerfectScrollbar>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}
