import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export var deleteReasonRes;
export const  deleteReasonTodo = createAsyncThunk(' deleteReason', async (id) => {
    try {
        const res = await axios({
            method: "delete",
            url: process.env.REACT_APP_API_URL + `reason_delete/${id}`,
            headers: { "Content-Type": "application/json" }
        })
        return deleteReasonRes = res.data

    } catch (error) {
        return deleteReasonRes = error.response.data
    }
})